'use client'

import React from 'react'
import {
  DateField as RACDateField,
  DateInput as RACDateInput,
  DateSegment as RACDateSegment,
  composeRenderProps,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type {
  DateValue,
  DateFieldProps as RACDateFieldProps,
  DateInputProps as RACDateInputProps,
  ValidationResult,
} from 'react-aria-components'

import { Description, FieldError, Label } from '../../forms/field/field'
import { fieldBorderStyles, fieldStyles, inputStyles } from '../../forms/field/field.styles'

const segmentStyles = tv({
  base: 'inline rounded py-0.5 caret-transparent outline outline-0',
  variants: {
    isPlaceholder: {
      true: 'text-muted-foreground [&+[data-type="literal"]]:text-muted-foreground',
    },
    isDisabled: {
      true: 'text-disabled-foreground',
    },
    isFocused: {
      true: 'bg-neutral-5',
    },
  },
})

interface DateInputProps extends Omit<RACDateInputProps, 'children'> {
  isDisabled?: boolean
  ref?: React.Ref<React.ComponentRef<typeof RACDateInput>>
}

export function DateInput({ className, isDisabled, ref, ...rest }: DateInputProps) {
  return (
    <RACDateInput
      {...rest}
      aria-disabled={isDisabled}
      className={composeRenderProps(className, (className, renderProps) =>
        inputStyles({ ...renderProps, className }),
      )}
    >
      {(segment) => <RACDateSegment ref={ref} segment={segment} className={segmentStyles} />}
    </RACDateInput>
  )
}

export interface DateFieldProps<T extends DateValue> extends RACDateFieldProps<T> {
  label?: string
  description?: string
  errorMessage?: string | ((validation: ValidationResult) => string)
}

export function DateField<T extends DateValue>({
  label,
  description,
  errorMessage,
  className,
  ...rest
}: DateFieldProps<T>) {
  return (
    <RACDateField
      {...rest}
      className={composeRenderProps(className, (className, renderProps) =>
        fieldStyles({ ...renderProps, className }),
      )}
    >
      {label ? <Label>{label}</Label> : null}

      <DateInput className={fieldBorderStyles} />

      {description ? <Description>{description}</Description> : null}
      <FieldError>{errorMessage}</FieldError>
    </RACDateField>
  )
}
