import React from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useTitle } from 'ahooks'
import { PlusIcon } from 'lucide-react'

import type { Key } from 'react-aria-components'

import { Button } from '@fysioscout/ui/buttons/button'
import { Tab, TabList, TabPanel, Tabs } from '@fysioscout/ui/navigation/tabs'

import { clinicPatientQueries } from '@/api/fysioscout/endpoints/clinic-patient/queries'
import { clinicTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-treatment/queries'
import { clinicVideoConsultationsQueries } from '@/api/fysioscout/endpoints/clinic-video-consultations/queries'
import { Route } from '@/app/routes/_auth/patients/$patientId/index'
import { PageHeading, PageLayout } from '@/components/layouts/page-layout'
import { PatientTopBar } from '@/features/patients/components/patient-top-bar'
import { DetachPatientProvider } from '@/features/patients/user-actions/detach-patient/components/detach-patient-provider'
import { TreatmentList } from '@/features/treatments/components/treatment/treatment-list'
import { useCreateTreatmentActions } from '@/features/treatments/user-actions/create-treatment/store'
import { EditTreatmentNameProvider } from '@/features/treatments/user-actions/edit-treatment-name/components/edit-treatment-name-provider'
import { FinishedConsultations } from '@/features/video-consultation/components/finished-consultations'
import { ScheduledConsultations } from '@/features/video-consultation/components/scheduled-consultations'
import { useIsVideoConsultationAvailable } from '@/features/video-consultation/hooks/use-is-video-consultation-available'
import { useCreateVideoConsultationActions } from '@/features/video-consultation/user-actions/create-video-consultation/store'

export function PatientView() {
  const navigate = Route.useNavigate()
  const params = Route.useParams()

  const { data: patient } = useSuspenseQuery(clinicPatientQueries.getById(params.patientId))

  useTitle(patient.patient_details.name)

  const handleDetachPatientSuccess = async () => {
    await navigate({ to: '/patients', search: { status: 'accepted' } })
  }

  return (
    <DetachPatientProvider onSuccess={handleDetachPatientSuccess}>
      <EditTreatmentNameProvider patientId={params.patientId}>
        <PageLayout>
          <div className={'flex flex-col gap-8'}>
            <PageHeading>{patient.patient_details.name}</PageHeading>
            <PatientTopBar patient={patient} />
            <PatientTabs />
          </div>
        </PageLayout>
      </EditTreatmentNameProvider>
    </DetachPatientProvider>
  )
}

function PatientTabs() {
  const navigate = Route.useNavigate()
  const search = Route.useSearch()

  const isVideoConsultationAvailable = useIsVideoConsultationAvailable()
  const [, startTransition] = React.useTransition()

  const handleSelectionChange = (key: Key) => {
    if (key === 'video-consultations' || key === 'treatments') {
      const tab = key === 'video-consultations' ? 'video-consultations' : 'treatments'

      startTransition(() => {
        void navigate({
          to: '.',
          search: (prev) => ({ ...prev, tab }),
        })
      })
    }
  }

  return (
    <Tabs defaultSelectedKey={search.tab} onSelectionChange={handleSelectionChange}>
      <TabList>
        <Tab id={'treatments'}>Behandlingsforløb</Tab>
        <Tab id={'video-consultations'} isDisabled={!isVideoConsultationAvailable}>
          Videokonsultationer
        </Tab>
      </TabList>

      <TabPanel id={'treatments'}>
        <TreatmentsTab />
      </TabPanel>

      <TabPanel id={'video-consultations'}>
        <VideoConsultationsTab />
      </TabPanel>
    </Tabs>
  )
}

function TreatmentsTab() {
  const createTreatmentActions = useCreateTreatmentActions()
  const params = Route.useParams()

  const { data: treatments } = useSuspenseQuery(
    clinicTreatmentQueries.treatments({ path: { patientId: params.patientId } }),
  )

  const { data: patient } = useSuspenseQuery(clinicPatientQueries.getById(params.patientId))

  return (
    <div className={'flex flex-col space-y-5'}>
      <div className={'stack items-end justify-between'}>
        <Button
          variant={'solid'}
          color={'accent'}
          iconEnd={<PlusIcon />}
          onPress={createTreatmentActions.open}
        >
          Opret behandlingsforløb
        </Button>
      </div>

      <TreatmentList treatments={treatments} patient={patient} />
    </div>
  )
}

function VideoConsultationsTab() {
  const params = Route.useParams()
  const createVideoConsultationActions = useCreateVideoConsultationActions()

  const { data: scheduledConsultations } = useSuspenseQuery(
    clinicVideoConsultationsQueries.byPatientId({
      path: { id: params.patientId },
      query: { status: 'scheduled' },
    }),
  )

  const { data: finishedConsultations } = useSuspenseQuery(
    clinicVideoConsultationsQueries.byPatientId({
      path: { id: params.patientId },
      query: { status: 'finished' },
    }),
  )

  const handlePress = () => {
    createVideoConsultationActions.setPatientId(params.patientId)
    createVideoConsultationActions.open()
  }

  return (
    <div className={'flex flex-col space-y-5'}>
      <div className={'stack items-end justify-between'}>
        <Button variant={'solid'} color={'accent'} iconEnd={<PlusIcon />} onPress={handlePress}>
          Opret videokonsultation
        </Button>
      </div>

      <ScheduledConsultations consultations={scheduledConsultations} />
      <FinishedConsultations consultations={finishedConsultations} />
    </div>
  )
}
