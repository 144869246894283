'use client'

import React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { AlertCircleIcon, InfoIcon } from 'lucide-react'

import type { ReactNode } from 'react'
import type { DialogProps as RACDialogProps } from 'react-aria-components'
import type { ModalProps } from '../modal/modal'

import { ButtonGroup } from '../../buttons/button-group/button-group'
import { Button } from '../../buttons/button/button'
import { Dialog, DialogTitle } from '../dialog/dialog'
import { Modal } from '../modal/modal'

export interface AlertDialogProps
  extends Omit<RACDialogProps, 'children'>,
    Pick<ModalProps, 'onOpenChange' | 'isOpen' | 'size'> {
  title: string
  children?: ReactNode
  actionLabel: string
  cancelLabel?: string
  destructive?: boolean
  isPending?: boolean
  onAction?: () => void
  onClose?: () => void
  onCloseComplete?: () => void

  /** Icon to display at the start of the button. */
  icon?: React.ReactNode
}

export function AlertDialog({
  title,
  children,
  actionLabel,
  cancelLabel,
  destructive,
  isOpen,
  isPending,
  onAction,
  onClose,
  onCloseComplete,
  onOpenChange,
  size,
  icon,
  ...rest
}: AlertDialogProps) {
  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      onClose?.()
    }

    onOpenChange?.(isOpen)
  }

  return (
    <Modal
      isOpen={isOpen ?? undefined}
      onOpenChange={handleOpenChange}
      onCloseComplete={onCloseComplete}
      size={size}
    >
      <Dialog role={'alertdialog'} {...rest}>
        {({ close }) => (
          <>
            <DialogTitle className={'pr-12'}>{title}</DialogTitle>

            <Slot className={'text-subtle-foreground absolute right-8 top-8 size-5'}>
              {destructive ? <AlertCircleIcon aria-hidden /> : <InfoIcon aria-hidden />}
            </Slot>

            {children ? (
              <div className={'text-subtle-foreground mt-2 pr-8 text-sm'}>{children}</div>
            ) : null}

            <ButtonGroup className={'mt-8 justify-end'}>
              <Button size={'sm'} variant={'soft'} color={'neutral'} onPress={close}>
                {cancelLabel ?? 'Annuller'}
              </Button>

              <Button
                size={'sm'}
                color={destructive ? 'destructive' : 'accent'}
                onPress={onAction}
                isPending={isPending}
                iconStart={icon}
              >
                {actionLabel}
              </Button>
            </ButtonGroup>
          </>
        )}
      </Dialog>
    </Modal>
  )
}
