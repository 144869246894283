import React from 'react'

import { CreateTreatmentContext } from '../context'
import { createTreatmentStore } from '../store'
import { CreateTreatmentDialog } from './create-treatment-dialog'

interface CreateTreatmentProviderProps {
  /** The unique identifier for the patient. */
  patientId: string

  /** The child components to render within the treatment provider. */
  children?: React.ReactNode
}

export function CreateTreatmentProvider({ patientId, children }: CreateTreatmentProviderProps) {
  const [store] = React.useState(() => createTreatmentStore())

  return (
    <CreateTreatmentContext value={{ store, patientId }}>
      <CreateTreatmentDialog />

      {children}
    </CreateTreatmentContext>
  )
}
