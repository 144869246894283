import React from 'react'
import { ExternalLinkIcon } from 'lucide-react'
import ms from 'ms'

import { LinkButton } from '@fysioscout/ui/buttons/link-button'
import { Cell } from '@fysioscout/ui/collections/table'
import { Tooltip, TooltipTrigger } from '@fysioscout/ui/overlays/tooltip'

import { useAuthContext } from '@/features/auth/context'
import { CONSULTATION_UNLOCK_MINUTES } from '@/features/video-consultation/config/constants'
import { useConsultationTableRowContext } from '@/features/video-consultation/contexts/consultation-table-row-context'
import { getTimeDifferenceMinutes } from '@/features/video-consultation/utils'
import { useUpdateTimer } from '@/hooks/use-update-timer'

export function StartConsultationCell() {
  const { consultation } = useConsultationTableRowContext()
  const { user } = useAuthContext()

  const timeDifferenceMin = getTimeDifferenceMinutes(consultation.scheduled_date)

  useUpdateTimer({ intervalMs: ms('10s'), enabled: timeDifferenceMin <= 60 })

  const isOutsideTimeframe = timeDifferenceMin > CONSULTATION_UNLOCK_MINUTES
  const isAssignedToLoggedInUser = consultation.employee.user_id === user.id
  const isDisabled = !isAssignedToLoggedInUser || isOutsideTimeframe

  return (
    <Cell>
      <TooltipTrigger delay={100} closeDelay={100} isDisabled={!isDisabled}>
        <LinkButton
          variant={'soft'}
          size={'sm'}
          color={'accent'}
          iconStart={<ExternalLinkIcon />}
          isVisuallyDisabled={isDisabled}
        >
          {isDisabled ? (
            <span>Start videokonsultation</span>
          ) : (
            <a target={'_blank'} href={consultation.host_room_url} rel={'noreferrer'}>
              Start videokonsultation
            </a>
          )}
        </LinkButton>

        <Tooltip>
          {isAssignedToLoggedInUser
            ? `Link til konsultationen åbner ${CONSULTATION_UNLOCK_MINUTES} min. før start.`
            : 'Du er ikke tildelt videokonsultationen. Skift behandleren og vælg dig selv for at få adgang til konsultationen.'}
        </Tooltip>
      </TooltipTrigger>
    </Cell>
  )
}
