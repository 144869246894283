import React from 'react'
import { UsersRoundIcon } from 'lucide-react'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import {
  CollapsiblePanel,
  CollapsiblePanelContent,
  CollapsiblePanelTrigger,
} from '@fysioscout/ui/layout/collapsible-panel'
import { Badge } from '@fysioscout/ui/status/badge'
import { Text } from '@fysioscout/ui/typography/text'

import { PatientTable } from '@/features/patients/components/patient-table'

import { PatientTableContext } from '../contexts/patient-table-context'

interface UnattachedPatientsProps {
  patients: Schemas['ClinicPatientDto'][]
  clinic: Schemas['ClinicDto2']
}

export function UnattachedPatients(props: UnattachedPatientsProps) {
  return (
    <CollapsiblePanel>
      <CollapsiblePanelTrigger>
        <div className={'hstack center gap-5'}>
          <UsersRoundIcon className={'size-5'} />
          <div className={'stack items-start'}>
            <Text size={'2'} slot={'description'} medium>
              {props.clinic.name}
            </Text>

            <Text size={'1'} subtle>
              Patienter uden tilknyttet behandler
            </Text>
          </div>

          <Badge variant={'yellow'}>
            {props.patients.length} {props.patients.length === 1 ? 'patient' : 'patienter'}
          </Badge>
        </div>
      </CollapsiblePanelTrigger>

      <CollapsiblePanelContent>
        <PatientTableContext value={{ enabledActions: 'all' }}>
          <PatientTable
            patients={props.patients}
            itemsPerPage={5}
            hideColumns={['practitioner', 'deletion']}
          />
        </PatientTableContext>
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  )
}
