'use client'

import { motion } from 'framer-motion'
import { Modal as RACModal } from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type { Variants } from 'framer-motion'
import type { ModalOverlayProps as RACModalOverlayProps } from 'react-aria-components'
import type { VariantProps } from 'tailwind-variants'

import { ModalOverlay } from '../modal-overlay/modal-overlay'

const drawerStyles = tv({
  base: 'bg-modal border-modal-border fixed inset-y-3 right-3 overflow-y-auto rounded-xl border',
  variants: {
    size: {
      '1': 'w-[clamp(450px,500px,90vw)]',
      '2': 'w-[clamp(450px,650px,90vw)]',
      '3': 'w-[clamp(450px,800px,90vw)]',
      '4': 'w-[clamp(450px,1140px,90vw)]',
    },
  },
  defaultVariants: {
    size: '2',
  },
})

type DrawerVariants = VariantProps<typeof drawerStyles>

const MotionModal = motion.create(RACModal)

const variants = {
  enter: { x: 0, transition: { type: 'spring', duration: 0.35, bounce: 0.15 } },
  exit: { x: '100%', transition: { type: 'tween', ease: 'easeIn', duration: 0.15 } },
} satisfies Variants

export interface DrawerProps extends Omit<RACModalOverlayProps, 'style'>, DrawerVariants {
  onClose?: () => void
  onCloseComplete?: () => void
}

export function Drawer({
  size,
  children,
  onClose,
  onOpenChange,
  onCloseComplete,
  ...rest
}: DrawerProps) {
  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      onClose?.()
    }

    onOpenChange?.(isOpen)
  }

  const handleAnimationComplete = (definition: string) => {
    if (definition === 'exit') {
      onCloseComplete?.()
    }
  }

  return (
    <ModalOverlay isDismissable onOpenChange={handleOpenChange} {...rest}>
      {(renderProps) => (
        <MotionModal
          key={'drawer'}
          className={drawerStyles({ size })}
          initial={{ x: '100%' }}
          variants={variants}
          animate={renderProps.state.isOpen ? 'enter' : 'exit'}
          onAnimationComplete={handleAnimationComplete}
        >
          {typeof children === 'function' ? children(renderProps) : children}
        </MotionModal>
      )}
    </ModalOverlay>
  )
}
