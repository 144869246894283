import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { pipe, sortBy, uniqueBy } from 'remeda'
import { useSpinDelay } from 'spin-delay'

import type { CreateVideoConsultationContextValue } from '../context'

import { clinicEmployeeQueries } from '@/api/fysioscout/endpoints/clinic-employee/queries'
import { clinicPatientQueries } from '@/api/fysioscout/endpoints/clinic-patient/queries'
import { useAuthContext } from '@/features/auth/context'
import { useConsultationCount } from '@/features/video-consultation/hooks/use-consultation-count'

import { CreateVideoConsultationContext } from '../context'
import { createVideoConsultationStore } from '../store'

export interface CreateVideoConsultationProviderProps
  extends Pick<CreateVideoConsultationContextValue, 'onCreate'> {
  children?: React.ReactNode
}

export function CreateVideoConsultationProvider({
  children,
  onCreate,
}: CreateVideoConsultationProviderProps) {
  const [store] = React.useState(() => createVideoConsultationStore())
  const { isLimitReached } = useConsultationCount()
  const { user } = useAuthContext()

  const employeesQuery = useQuery(clinicEmployeeQueries.list())
  const acceptedPatientsQuery = useQuery(clinicPatientQueries.accepted())
  const pendingPatientsQuery = useQuery(clinicPatientQueries.pending())

  const patients = React.useMemo(() => {
    const accepted =
      acceptedPatientsQuery.data?.clinic_employees.flatMap((employee) => employee.patients) ?? []
    const unattached = acceptedPatientsQuery.data?.unattached_patients ?? []
    const pending = pendingPatientsQuery.data?.patients ?? []

    const allPatients = [accepted, unattached, pending].flat()

    return pipe(
      allPatients,
      uniqueBy(({ patient_details }) => patient_details.id),
      sortBy((patient) => patient.patient_details.name),
    )
  }, [acceptedPatientsQuery.data, pendingPatientsQuery.data])

  const isLoading = useSpinDelay(
    employeesQuery.isLoading || acceptedPatientsQuery.isLoading || pendingPatientsQuery.isLoading,
    {
      minDuration: 400,
      delay: 0,
    },
  )

  const value = React.useMemo(() => {
    const employees = employeesQuery.data ?? []

    const defaultEmployeeId = employees.find((employee) => employee.user_id === user.id)?.id

    return {
      store,
      employees,
      patients,
      defaultEmployeeId,
      isLimitReached,
      isLoading,
      onCreate,
    } satisfies CreateVideoConsultationContextValue
  }, [employeesQuery.data, isLimitReached, isLoading, onCreate, patients, store, user.id])

  return <CreateVideoConsultationContext value={value}>{children}</CreateVideoConsultationContext>
}
