import React from 'react'

import type { EditTreatmentNameContextValue } from '../context'

import { EditTreatmentNameContext } from '../context'
import { editTreatmentNameStore } from '../store'
import { EditTreatmentNameDialog } from './edit-treatment-name-dialog'

interface EditTreatmentNameProviderProps extends Omit<EditTreatmentNameContextValue, 'store'> {
  children?: React.ReactNode
}

export function EditTreatmentNameProvider({
  patientId,
  treatment,
  children,
}: EditTreatmentNameProviderProps) {
  const [store] = React.useState(() => editTreatmentNameStore())

  return (
    <EditTreatmentNameContext value={{ store, patientId, treatment }}>
      <EditTreatmentNameDialog />

      {children}
    </EditTreatmentNameContext>
  )
}
