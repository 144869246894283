import React from 'react'

import { Button } from '@fysioscout/ui/buttons/button'
import { Form } from '@fysioscout/ui/forms/form'
import { Switch } from '@fysioscout/ui/forms/switch'
import { TextField } from '@fysioscout/ui/forms/text-field'
import { toast } from '@fysioscout/ui/status/toast'

import { useInviteClinicPatient } from '@/features/patients/api/invite-clinic-patient'

const FIELDS = {
  email: 'email',
  chat: 'chat',
}

interface AttachExistingPatientFormProps {
  /** Optional CSS class names to apply. */
  className?: string

  /** Success callback to invoke when the form is submitted successfully. */
  onSuccess?: (response: unknown) => void
}

export function AttachExistingPatientForm(props: AttachExistingPatientFormProps) {
  const mutation = useInviteClinicPatient()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const emailValue = formData.get(FIELDS.email) as string | null

    const email = emailValue?.trim() ?? ''
    const is_chat_enabled = formData.get(FIELDS.chat) === 'on'

    mutation.mutate(
      { email, is_chat_enabled },
      {
        onSuccess: (data) => {
          toast.success(`${email} er nu inviteret til klinikken.`, {
            description:
              'Patienten skal nu acceptere invitationen for at blive tilknyttet klinikken.',
          })

          props.onSuccess?.(data)
        },
      },
    )
  }

  return (
    <div data-testid={'attach-existing-patient-form'}>
      <Form onSubmit={handleSubmit}>
        <TextField autoFocus label={'Email'} name={FIELDS.email} type={'email'} isRequired />
        <Switch name={FIELDS.chat}>Aktivér chat til patienten</Switch>

        <Button variant={'solid'} color={'accent'} type={'submit'} isPending={mutation.isPending}>
          Tilknyt patient
        </Button>
      </Form>
    </div>
  )
}
