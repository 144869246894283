import React from 'react'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import {
  Classification,
  Consent,
  Panel,
  PanelHeader,
  PanelItem,
  PanelItemDescription,
  PanelItemDescriptionList,
  PanelItemLabel,
} from '../shared'

interface FirstTimeConsultationProps {
  transcription: Schemas['FirstTimeConsultationV2']
}

export function FirstTimeConsultationV2(props: FirstTimeConsultationProps) {
  return (
    <div data-testid={'first-time-consultation'} className={'stack gap-8'}>
      <Classification type={'first_time_v2'} classification={props.transcription.classification} />
      <Anamnesis anamnesis={props.transcription.anamnesis} />
      <ExaminationAndTreatment treatment={props.transcription.examination_and_treatment} />
      <Consent consent={props.transcription.consent} />
    </div>
  )
}

interface AnamnesisProps {
  anamnesis: Schemas['AnamnesisV2']
}

function Anamnesis(props: AnamnesisProps) {
  return (
    <Panel
      left={
        <PanelHeader
          heading={'Anamnese'}
          description={
            'Anamnese er en patients sygehistorie, indsamlet gennem samtale med sundhedspersonalet for at få et overblik over helbredsmæssige forhold. Den hjælper med at stille en diagnose og planlægge behandling.'
          }
        />
      }
      right={
        <>
          <PanelItem>
            <PanelItemLabel>Henvendelsesårsag</PanelItemLabel>

            <PanelItemDescriptionList>
              <PanelItemDescription>
                {props.anamnesis.reason_for_contact.primary_complaint}
              </PanelItemDescription>

              <PanelItemDescription>
                {props.anamnesis.reason_for_contact.context ?? '–'}
              </PanelItemDescription>

              <PanelItemDescription>
                {props.anamnesis.reason_for_contact.impact_on_daily_activities ?? '–'}
              </PanelItemDescription>

              <PanelItemDescription>
                {props.anamnesis.reason_for_contact.onset ?? '–'}
              </PanelItemDescription>
            </PanelItemDescriptionList>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Beskæftigelse</PanelItemLabel>

            <PanelItemDescriptionList>
              <PanelItemDescription>{props.anamnesis.occupation?.type ?? '–'}</PanelItemDescription>

              <PanelItemDescription>
                {props.anamnesis.occupation?.physical_demands ?? '–'}
              </PanelItemDescription>
            </PanelItemDescriptionList>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Hvor, hvornår og hvad skete der?</PanelItemLabel>
            <PanelItemDescription>{props.anamnesis.incident_details ?? '–'}</PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Smerte</PanelItemLabel>

            <PanelItemDescriptionList>
              <PanelItemDescription>
                Intensitet: {props.anamnesis.pain?.intensity ?? '–'}
              </PanelItemDescription>

              <PanelItemDescription>{props.anamnesis.pain?.location ?? '–'}</PanelItemDescription>
            </PanelItemDescriptionList>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Forværrende faktorer</PanelItemLabel>

            <PanelItemDescriptionList>
              {props.anamnesis.pain?.characteristics.aggravating_factors.map(
                (characteristic, i) => (
                  <PanelItemDescription key={i}>{characteristic}</PanelItemDescription>
                ),
              )}
            </PanelItemDescriptionList>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Lindrende faktorer</PanelItemLabel>

            <PanelItemDescriptionList>
              {props.anamnesis.pain?.characteristics.relieving_factors.map((characteristic, i) => (
                <PanelItemDescription key={i}>{characteristic}</PanelItemDescription>
              ))}
            </PanelItemDescriptionList>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Tidligere undersøgelse og behandling</PanelItemLabel>

            <PanelItemDescription>
              {props.anamnesis.previous_examinations_treatments ?? '–'}
            </PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Medicin</PanelItemLabel>

            <PanelItemDescription>{props.anamnesis.medications ?? '–'}</PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Andre skader</PanelItemLabel>

            <PanelItemDescription>{props.anamnesis.other_injuries ?? '–'}</PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Sygdomsrelaterede problematikker</PanelItemLabel>

            <PanelItemDescription>
              {props.anamnesis.disease_related_problems ?? '–'}
            </PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Kost og motion</PanelItemLabel>

            <PanelItemDescription>{props.anamnesis.diet_and_exercise ?? '–'}</PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Søvn (døgnrytme)</PanelItemLabel>
            <PanelItemDescription>{props.anamnesis.sleep ?? '–'}</PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Stress, depression og angst</PanelItemLabel>

            <PanelItemDescription>
              {props.anamnesis.psychological_factors ?? '–'}
            </PanelItemDescription>
          </PanelItem>
        </>
      }
    />
  )
}

interface ExaminationAndTreatmentProps {
  treatment: Schemas['FirstTimeExaminationAndTreatmentV2']
}

function ExaminationAndTreatment(props: ExaminationAndTreatmentProps) {
  return (
    <Panel
      left={
        <PanelHeader
          heading={'Examination og behandling'}
          description={
            'Examination og behandling beskriver patientens aktiviteter, udførelse af aktiviteter og behandling af patientens lidelser.'
          }
        />
      }
      right={
        <>
          <PanelItem>
            <PanelItemLabel>Observation</PanelItemLabel>

            <PanelItemDescription>
              {props.treatment.observation.visual_findings}
            </PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Provokation og sikkerhedstest</PanelItemLabel>

            <PanelItemDescription>
              {props.treatment.provocative_and_safety_tests}
            </PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Plan for dagens behandling</PanelItemLabel>

            <PanelItemDescription>
              {props.treatment.treatment_plan.current_session}
            </PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Kortsigtet mål</PanelItemLabel>

            <PanelItemDescription>
              {props.treatment.treatment_plan.short_term_goals}
            </PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Langsigtet mål</PanelItemLabel>

            <PanelItemDescription>
              {props.treatment.treatment_plan.long_term_goals}
            </PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Patientens mål</PanelItemLabel>

            <PanelItemDescription>
              {props.treatment.treatment_plan.patient_goals}
            </PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Information og vejledning</PanelItemLabel>

            <PanelItemDescriptionList>
              {props.treatment.information_and_guidance.provided_information.map(
                (information, i) => (
                  <PanelItemDescription key={i}>{information}</PanelItemDescription>
                ),
              )}
            </PanelItemDescriptionList>
          </PanelItem>
        </>
      }
    />
  )
}
