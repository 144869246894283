'use client'

import React from 'react'
import { Modal as RACModal } from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type { ModalOverlayProps as RACModalOverlayProps } from 'react-aria-components'
import type { VariantProps } from 'tailwind-variants'

import { ModalOverlay } from '../modal-overlay/modal-overlay'

const modalStyles = tv({
  base: 'border-modal-border bg-modal mb-44 max-h-full w-full rounded-xl border bg-clip-padding text-left align-middle shadow-2xl',
  variants: {
    isEntering: {
      true: 'animate-in slide-in-from-bottom-2 duration-200 ease-out',
    },
    isExiting: {
      true: 'animate-out slide-out-to-bottom-2 duration-200 ease-in',
    },
    size: {
      '1': 'max-w-[480px]',
      '2': 'max-w-[540px]',
      '3': 'max-w-[680px]',
    },
  },
  defaultVariants: {
    size: '1',
  },
})

type ModalVariants = VariantProps<typeof modalStyles>

export interface ModalProps extends RACModalOverlayProps, ModalVariants {
  onCloseComplete?: () => void
}

export function Modal({ size, onCloseComplete, ...rest }: ModalProps) {
  const isExitingRef = React.useRef(false)
  const modalRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const modal = modalRef.current

    if (!modal) return

    const handleAnimationEnd = (e: AnimationEvent) => {
      if (e.target !== modal) return

      if (isExitingRef.current) {
        onCloseComplete?.()
        isExitingRef.current = false
      }
    }

    modal.addEventListener('animationend', handleAnimationEnd)

    return () => modal.removeEventListener('animationend', handleAnimationEnd)
  }, [onCloseComplete])

  return (
    <ModalOverlay isDismissable {...rest}>
      <RACModal
        {...rest}
        ref={modalRef}
        className={(renderProps) => {
          isExitingRef.current = renderProps.isExiting

          return modalStyles({ size, ...renderProps })
        }}
      />
    </ModalOverlay>
  )
}
