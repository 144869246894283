import React from 'react'

import { useSession } from '@/features/auth/api/use-session'
import { AuthContext } from '@/features/auth/context'
import { redirectToAuth } from '@/features/auth/utils'

interface AuthProviderProps {
  children: React.ReactNode
}

/**
 * Manages the authentication context for the application.
 *
 * Forces the user to log in at the Auth site if they are not authenticated.
 *
 * @param props - The props object.
 * @param props.children - The child components to be rendered within the authentication context.
 * @returns If the user is authenticated, returns a JSX element containing the child components
 *   rendered within the authentication context.
 * @see redirectToAuth
 */
export function AuthProvider({ children }: AuthProviderProps) {
  const session = useSession()

  if (session.data) {
    return <AuthContext value={{ user: session.data.user }}>{children}</AuthContext>
  }

  if (session.isLoading) {
    return null
  }

  redirectToAuth()

  return null
}
