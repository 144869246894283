import React from 'react'
import { flushSync } from 'react-dom'
import invariant from 'tiny-invariant'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { Button } from '@fysioscout/ui/buttons/button'
import { Form } from '@fysioscout/ui/forms/form'
import { RadioGroupList, RadioListRadio } from '@fysioscout/ui/forms/radio-group-list'
import { Switch } from '@fysioscout/ui/forms/switch'
import { TextField } from '@fysioscout/ui/forms/text-field'
import { Separator } from '@fysioscout/ui/misc/separator'
import { toast } from '@fysioscout/ui/status/toast'
import { Heading } from '@fysioscout/ui/typography/heading'

import { useSendExercisesPdfEmail } from '@/features/patients/api/email-exercises-pdf'
import { useExportExercisesContext } from '@/features/patients/user-actions/export-exercises/context'
import { useExportExercisesActions } from '@/features/patients/user-actions/export-exercises/store'

const FIELDS = {
  email: 'email',
}

interface ExportExercisesFormProps {
  treatment: Schemas['TreatmentDto']
}

export function ExportExercisesForm({ treatment }: ExportExercisesFormProps) {
  const [enterNewEmail, setEnterNewEmail] = React.useState(false)
  const [selectedEmail, setSelectedEmail] = React.useState<string | null>(null)

  const actions = useExportExercisesActions()
  const context = useExportExercisesContext()
  const mutation = useSendExercisesPdfEmail()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const emailValue = formData.get(FIELDS.email) as string | null

    const email = enterNewEmail ? (emailValue?.trim() ?? '') : selectedEmail

    invariant(email, 'Email is required')

    mutation.mutate(
      { email, treatmentId: treatment.id },
      {
        onSuccess: () => {
          toast.success(
            `PDF med øvelser for ${treatment.name ? `"${treatment.name}"` : ''} blev sendt.`,
            {
              description: `Modtager: ${email}`,
            },
          )
          actions.reset()
        },
      },
    )
  }

  const handleSwitchChange = (selected: boolean) => {
    /** Using flushSync to ensure state updates are reflected in the DOM immediately */
    flushSync(() => {
      setEnterNewEmail(selected)
    })

    /** Ensuring the input is focused only after it has been enabled */
    if (selected) {
      setSelectedEmail('')

      const emailInput = document.querySelector('input[type="email"]')
      if (emailInput instanceof HTMLElement) {
        emailInput.focus()
      }
    }
  }

  const items = [
    { text: context.patient.patient_details.email, value: context.patient.patient_details.email },
    {
      text: context.patient.clinic_employee?.email ?? '',
      value: context.patient.clinic_employee?.email ?? '',
    },
  ] satisfies { text: string; value: string }[]

  return (
    <Form className={'mt-10 items-start'} onSubmit={handleSubmit}>
      <Heading slot={'title'} medium size={'5'}>
        Eksporter øvelser
      </Heading>

      <RadioGroupList
        label={'Vælg email'}
        className={'mt-6'}
        value={selectedEmail}
        onChange={setSelectedEmail}
        isRequired
        isDisabled={enterNewEmail}
      >
        {items.map((item) => (
          <RadioListRadio key={item.value} value={item.value}>
            {item.text}
          </RadioListRadio>
        ))}
      </RadioGroupList>

      <Separator />

      <Switch isSelected={enterNewEmail} onChange={handleSwitchChange}>
        Indtast anden email
      </Switch>

      <TextField
        className={'w-full'}
        label={'Indtast email'}
        name={FIELDS.email}
        type={'email'}
        isDisabled={!enterNewEmail}
        isRequired
      />

      <Button
        type={'submit'}
        variant={'solid'}
        color={'accent'}
        size={'md'}
        isPending={mutation.isPending}
      >
        Send PDF
      </Button>
    </Form>
  )
}
