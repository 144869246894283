import React from 'react'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { ExportExercisesContext } from '../context'
import { createExportExercisesStore } from '../store'
import { ExportExercisesDrawer } from './export-exercises-drawer'

interface ExportExercisesProviderProps {
  patient: Schemas['ClinicPatientDto']
  children?: React.ReactNode
}

export function ExportExercisesProvider({ patient, children }: ExportExercisesProviderProps) {
  const [store] = React.useState(() => createExportExercisesStore())

  return (
    <ExportExercisesContext value={{ patient, store }}>
      <ExportExercisesDrawer />

      {children}
    </ExportExercisesContext>
  )
}
