import React from 'react'
import { Outlet } from '@tanstack/react-router'

import { ConsultationActionsProvider } from '@/features/video-consultation/components/consultation-actions-provider'

import { Route } from '.'

export function VideoConsultationRoute() {
  const navigate = Route.useNavigate()

  const handleOnCreate = React.useCallback(
    async (patientId: string) => {
      await navigate({
        to: '/video-consultation/patient/$patientId',
        params: { patientId },
      })
    },
    [navigate],
  )

  return (
    <ConsultationActionsProvider onCreate={handleOnCreate}>
      <Outlet />
    </ConsultationActionsProvider>
  )
}
