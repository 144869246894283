import React from 'react'

import type { DetachPatientContextValue } from '../context'

import { DetachPatientContext } from '../context'
import { createDetachPatientFromClinicStore } from '../store'
import { DetachPatientDialog } from './detach-patient-dialog'

interface DetachPatientProviderProps extends Omit<DetachPatientContextValue, 'store'> {
  children?: React.ReactNode
}

export function DetachPatientProvider({ children, onSuccess }: DetachPatientProviderProps) {
  const [store] = React.useState(() => createDetachPatientFromClinicStore())

  return (
    <DetachPatientContext value={{ store, onSuccess }}>
      <DetachPatientDialog />

      {children}
    </DetachPatientContext>
  )
}
