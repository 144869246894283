import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { StoreApi } from 'zustand'
import type { CreateVideoConsultationStore } from './store'

import { createStrictContext } from '@fysioscout/utils/react'

export interface CreateVideoConsultationContextValue {
  /** Store instance for managing video consultation state */
  store: StoreApi<CreateVideoConsultationStore>

  /** Indicates if the maximum number of consultations has been reached */
  isLimitReached?: boolean

  /** List of clinic employees available for consultations */
  employees: Schemas['ClinicEmployeeDto'][]

  /** List of clinic patients available for consultations */
  patients: Schemas['ClinicPatientDto'][]

  /** ID of the default employee to be pre-selected */
  defaultEmployeeId?: Schemas['ClinicEmployeeDto']['id']

  /** Callback when a video consultation is created */
  onCreate?: (patientId: string) => Promise<void> | void

  /** Indicates if data is being loaded */
  isLoading?: boolean
}

export const [CreateVideoConsultationContext, useCreateVideoConsultationContext] =
  createStrictContext<CreateVideoConsultationContextValue>({
    name: 'CreateVideoConsultationStore',
    errorMessage:
      'useCreateVideoConsultationContext must be used within a CreateVideoConsultationProvider',
    isDevelopment: import.meta.env.DEV,
    allowMissingProvider: false,
  })
