import React from 'react'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { Button } from '@fysioscout/ui/buttons/button'
import { Form } from '@fysioscout/ui/forms/form'
import { RadioGroupList, RadioListRadio } from '@fysioscout/ui/forms/radio-group-list'
import { Dialog, DialogCloseButton } from '@fysioscout/ui/overlays/dialog'
import { toast } from '@fysioscout/ui/status/toast'
import { Heading } from '@fysioscout/ui/typography/heading'
import { Text } from '@fysioscout/ui/typography/text'

import { useUpdateClinicPatient } from '@/features/patients/api/update-clinic-patient'
import { useChangePractitionerActions } from '@/features/patients/user-actions/change-practitioner/store'

interface ChangePractitionerDialogProps {
  /** A list of clinic employee details. */
  employees: Schemas['ClinicEmployeeDto'][]

  /** Details of the patient associated with the employee. */
  patient: Schemas['ClinicPatientDto']

  /** Details of the selected practitioner or null if none is selected. */
  practitioner: Schemas['ClinicEmployeeDto'] | null
}

export function ChangePractitionerDialog(props: ChangePractitionerDialogProps) {
  const [selectedEmployeeId, setSelectedEmployeeId] = React.useState<string | null>(null)
  const mutation = useUpdateClinicPatient()
  const actions = useChangePractitionerActions()

  const selectedEmployee = props.employees.find((employee) => employee.id === selectedEmployeeId)

  const close = () => {
    actions.onOpenChange(false)
  }

  const handleMutation = () => {
    if (!selectedEmployee || !props.patient.patient_details.id) return

    const handleSuccess = () => {
      close()
      toast.success(
        `${props.patient.patient_details.name} er nu tilknyttet ${selectedEmployee.name}.`,
      )
    }

    mutation.mutate(
      {
        patientId: props.patient.patient_details.id,
        body: { clinic_employee_id: selectedEmployee.id },
      },
      { onSuccess: handleSuccess },
    )
  }

  const buttonIsDisabled = !selectedEmployee || selectedEmployee.id === props.practitioner?.id

  return (
    <Dialog data-testid={'change-practitioner-dialog'}>
      {({ close }) => (
        <div>
          <div className={'hstack space-between'}>
            <DialogCloseButton onPress={close} />

            {props.practitioner?.name ? (
              <div className={'stack text-right'}>
                <Text muted size={'2'}>
                  Tilknyttet behandler
                </Text>

                <Text size={'4'} medium>
                  {props.practitioner.name}
                </Text>
              </div>
            ) : null}
          </div>

          <Form
            className={'mt-10 items-start'}
            onSubmit={(e) => {
              e.preventDefault()
              handleMutation()
            }}
          >
            <Heading slot={'title'} size={'5'}>
              {props.practitioner ? 'Skift' : 'Tilknyt'} behandler
            </Heading>

            {props.employees.length === 0 ? (
              <Text slot={'description'} size={'2'} muted>
                Vi kunne ikke finde nogen behandlere. <br />
                Hvis du mener, at dette er en fejl, bedes du kontakte os.
              </Text>
            ) : (
              <RadioGroupList
                label={'Vælg behandler'}
                className={'mt-6'}
                value={selectedEmployeeId ?? props.practitioner?.id}
                onChange={setSelectedEmployeeId}
                isRequired
              >
                {props.employees.map((employee) => (
                  <RadioListRadio key={employee.id} value={employee.id}>
                    {employee.name}
                  </RadioListRadio>
                ))}
              </RadioGroupList>
            )}

            <Button
              type={'submit'}
              variant={'solid'}
              color={'accent'}
              size={'md'}
              isDisabled={buttonIsDisabled}
              isPending={mutation.isPending}
            >
              {props.practitioner ? 'Skift' : 'Tilknyt'} behandler
            </Button>
          </Form>
        </div>
      )}
    </Dialog>
  )
}
