import React from 'react'

import type { Key } from 'react-aria-components'
import type { ComboBoxProps } from '../combo-box/combo-box'
import type { Country } from './countries'

import { Text } from '../../typography/text/text'
import { cn } from '../../utils'
import { ComboBox, ComboBoxItem } from '../combo-box/combo-box'
import { COUNTRIES } from './countries'

type CountryPickerType = 'country' | 'phone' | 'language' | 'currency'

/** ISO 639-1 language code used as the picker's key */
type CountryId = (typeof COUNTRIES)[number]['languageCode']

/**
 * Represents the allowed languages for a particular context.
 *
 * The type can either be:
 *
 * - 'all': Indicates that all languages are allowed.
 * - Array of `CountryId`: Specifies a list of country identifiers corresponding to allowed languages.
 */
type AllowedLanguages = 'all' | CountryId[]

interface CountryPickerOwnProps {
  /** The type of country picker to display */
  type?: CountryPickerType

  /** Callback fired when a country selection changes */
  onCountryChange?: (country: Country | null) => void

  /** Initial country selection by ID */
  defaultSelectedKey?: CountryId

  /** Controlled country selection by ID */
  selectedKey?: CountryId | null

  /** Whether to display country names in English */
  showEnglishNames?: boolean

  /** Languages to include in the picker. Defaults to 'all' */
  allowedLanguages?: AllowedLanguages

  /** Reference to the underlying ComboBox component */
  ref?: React.Ref<React.ComponentRef<typeof ComboBox>>
}

export interface CountryPickerProps
  extends Omit<ComboBoxProps<Country>, 'children' | 'defaultSelectedKey' | 'selectedKey' | 'items'>,
    CountryPickerOwnProps {}

export const CountryPicker = ({
  type = 'country',
  onCountryChange,
  onSelectionChange,
  showEnglishNames = false,
  allowedLanguages = 'all',
  ...rest
}: CountryPickerProps) => {
  const filteredCountries = React.useMemo(() => {
    if (allowedLanguages === 'all') {
      return COUNTRIES
    }

    const allowedLanguageSet = new Set(allowedLanguages)
    return COUNTRIES.filter((country) => allowedLanguageSet.has(country.languageCode))
  }, [allowedLanguages])

  const sortedCountries = React.useMemo(
    () => sortCountries(filteredCountries, type),
    [filteredCountries, type],
  )

  const handleSelectionChange = (key: Key | null) => {
    onSelectionChange?.(key)

    const selectedCountry = COUNTRIES.find((country) => country.languageCode === key)

    onCountryChange?.(selectedCountry ?? null)
  }

  return (
    <ComboBox {...rest} items={sortedCountries} onSelectionChange={handleSelectionChange}>
      {(country) => (
        <ComboBoxItem
          textValue={getTextValue(country, type, showEnglishNames)}
          id={country.languageCode}
        >
          <Text slot={'label'}>{getItemContent(country, type, showEnglishNames)}</Text>
        </ComboBoxItem>
      )}
    </ComboBox>
  )
}

interface ItemWrapperProps extends React.ComponentProps<'div'> {
  className?: string
  emoji?: string
}

function ItemWrapper({ emoji, children, className }: ItemWrapperProps) {
  return (
    <div className={cn('flex items-center gap-2', className)}>
      <span>{emoji}</span>
      <span>{children}</span>
    </div>
  )
}

function getTextValue(country: Country, type: CountryPickerType, showEnglishNames: boolean) {
  const countryName = showEnglishNames ? country.name : country.nativeName

  switch (type) {
    case 'phone': {
      return `${country.phoneCode} ${countryName}`
    }

    case 'language': {
      return `${country.languageName}`
    }

    case 'currency': {
      return `${countryName} (${country.currency.code})`
    }

    default: {
      /** For the default case (country selection), include both names to allow searching by either */
      return `${countryName}`
    }
  }
}

function getDisplayText(country: Country, type: CountryPickerType, showEnglishNames: boolean) {
  const countryName = showEnglishNames ? country.name : country.nativeName

  switch (type) {
    case 'phone': {
      return `${country.phoneCode} ${countryName}`
    }

    case 'language': {
      return `${country.languageName} (${countryName})`
    }

    case 'currency': {
      return `${countryName} (${country.currency.code})`
    }

    default: {
      /** For country selection, use the name in the interface language */
      return countryName
    }
  }
}

function getItemContent(country: Country, type: CountryPickerType, showEnglishNames: boolean) {
  return (
    <ItemWrapper emoji={country.flag}>
      {getDisplayText(country, type, showEnglishNames)}
    </ItemWrapper>
  )
}

function sortCountries(countries: readonly Country[], type: CountryPickerType): Country[] {
  const sorters = {
    phone: (a: Country, b: Country) => {
      const phoneA = Number.parseInt(a.phoneCode.replace('+', ''))
      const phoneB = Number.parseInt(b.phoneCode.replace('+', ''))
      return phoneA - phoneB
    },
    currency: (a: Country, b: Country) => a.currency.code.localeCompare(b.currency.code),
    language: (a: Country, b: Country) => a.languageName.localeCompare(b.languageName),
    country: (a: Country, b: Country) => a.name.localeCompare(b.name),
  } satisfies Record<CountryPickerType, (a: Country, b: Country) => number>

  return [...countries].sort(sorters[type])
}
