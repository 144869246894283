import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { createStrictContext } from '@fysioscout/utils/react'

interface AuthContextValue {
  user: Schemas['User']
}

export const [AuthContext, useAuthContext] = createStrictContext<AuthContextValue>({
  name: 'AuthContext',
  errorMessage: 'useAuthContext must be used within a AuthContext.',
  isDevelopment: import.meta.env.DEV,
  allowMissingProvider: false,
})
