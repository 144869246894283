import { EllipsisIcon, MailQuestionIcon, UserMinusIcon, UserRoundCogIcon } from 'lucide-react'
import { MenuTrigger } from 'react-aria-components'
import { doNothing } from 'remeda'
import { match } from 'ts-pattern'

import type { PatientActionRecord } from '@/features/patients/contexts/patient-table-context'
import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { Key } from 'react-aria-components'

import { Button } from '@fysioscout/ui/buttons/button'
import { Menu, MenuItem } from '@fysioscout/ui/collections/menu'

import { useChangePatientEmailActions } from '@/features/patients/user-actions/change-email/store'
import { useChangePractitionerActions } from '@/features/patients/user-actions/change-practitioner/store'
import { useDetachPatientFromClinicActions } from '@/features/patients/user-actions/detach-patient/store'

const actions = {
  changePractitioner: 'change-practitioner',
  detach: 'detach',
  changeEmail: 'change-email',
} as const satisfies Partial<PatientActionRecord>

interface PatientQuickMenuProps {
  patient: Schemas['ClinicPatientDto']
  practitioner: Schemas['ClinicEmployeeDto'] | null
}

export function PatientQuickMenu({ patient, practitioner }: PatientQuickMenuProps) {
  const changePatientEmailActions = useChangePatientEmailActions()
  const changePractitionerActions = useChangePractitionerActions()
  const detachPatientFromClinicActions = useDetachPatientFromClinicActions()

  const handleChangePractitioner = () => {
    setTimeout(() => {
      changePractitionerActions.load({ patient, practitioner })
      changePractitionerActions.open()
    }, 100)
  }

  const handleDetachPatientFromClinic = () => {
    setTimeout(() => {
      detachPatientFromClinicActions.setPatient(patient)
      detachPatientFromClinicActions.setPatientId(patient.patient_details.id)
    }, 100)
  }

  const handleChangePatientEmail = () => {
    setTimeout(() => {
      changePatientEmailActions.setPatient(patient)
      changePatientEmailActions.setPatientId(patient.patient_details.id)
    }, 100)
  }

  const handleAction = (key: Key) => {
    match(key)
      .with(actions.changePractitioner, handleChangePractitioner)
      .with(actions.detach, handleDetachPatientFromClinic)
      .with(actions.changeEmail, handleChangePatientEmail)
      .otherwise(() => doNothing())
  }

  return (
    <MenuTrigger>
      <Button variant={'soft'} size={'md'} color={'neutral'} aria-label={'Patienthandlinger'}>
        <EllipsisIcon />
      </Button>

      <Menu
        selectionMode={'none'}
        disallowEmptySelection
        onAction={handleAction}
        placement={'bottom end'}
      >
        <MenuItem id={actions.changePractitioner} icon={<UserRoundCogIcon />}>
          Skift behandler
        </MenuItem>

        {patient.status === 'pending' ? (
          <MenuItem id={actions.changeEmail} icon={<MailQuestionIcon />}>
            Skift email
          </MenuItem>
        ) : null}

        <MenuItem id={actions.detach} icon={<UserMinusIcon />}>
          Afslut patientforløb
        </MenuItem>
      </Menu>
    </MenuTrigger>
  )
}
