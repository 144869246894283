'use client'

import React from 'react'
import { Switch as RACSwitch } from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type { SwitchProps as RACSwitchProps } from 'react-aria-components'

import { Text } from '../../typography/text/text'
import { cn, composeTailwindRenderProps, focusRing } from '../../utils'

const track = tv({
  extend: focusRing,
  base: 'flex h-5 w-9 shrink-0 cursor-default items-center rounded-full border border-transparent px-px shadow-inner transition duration-150 ease-in-out',
  variants: {
    isSelected: {
      false: 'bg-neutral-7 group-hover:bg-neutral-8 group-pressed:bg-neutral-9',
      true: 'bg-accent-9 group-pressed:bg-accent-10 group-hover:brightness-110',
    },
    isDisabled: {
      true: 'bg-neutral-5',
    },
    isReadOnly: {
      true: 'bg-neutral-7',
    },
  },
})

const handle = tv({
  base: 'size-4 transform rounded-full bg-white shadow outline outline-1 -outline-offset-1 outline-transparent transition duration-150 ease-in-out',
  variants: {
    isSelected: {
      false: 'translate-x-0',
      true: 'translate-x-full',
    },
    isDisabled: {
      true: '',
    },
  },
})

export interface SwitchProps extends RACSwitchProps {
  hideLabel?: boolean
  ref?: React.Ref<React.ComponentRef<typeof RACSwitch>>
}

export function Switch({ children, hideLabel, className, ...rest }: SwitchProps) {
  return (
    <RACSwitch
      {...rest}
      className={composeTailwindRenderProps(className, 'group flex items-center gap-2 transition')}
    >
      {(renderProps) => (
        <>
          <div className={track(renderProps)}>
            <span className={handle(renderProps)} />
          </div>

          <Text
            size={'2'}
            isDisabled={renderProps.isDisabled}
            className={cn({ 'sr-only': hideLabel })}
          >
            {typeof children === 'function' ? children(renderProps) : children}
          </Text>
        </>
      )}
    </RACSwitch>
  )
}
