import React from 'react'
import { useCopyToClipboard } from 'usehooks-ts'

type CopyStatus = 'idle' | 'copied'

interface UseCopyToClipboardStateProps {
  timeoutDuration?: number
}

export function useCopyToClipboardState({
  timeoutDuration = 800,
}: UseCopyToClipboardStateProps = {}) {
  const [copiedText, copyToClipboard] = useCopyToClipboard()
  const [copyStatus, setCopyStatus] = React.useState<CopyStatus>('idle')
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null)

  const copy = React.useCallback(
    async (text: string, options?: { ignoreStatus?: boolean }) => {
      const success = await copyToClipboard(text)

      if (success) {
        if (!options?.ignoreStatus) setCopyStatus('copied')

        /** Clear existing timeout */
        if (timeoutId) {
          clearTimeout(timeoutId)
        }

        /** Set new timeout */
        const newTimeoutId = globalThis.setTimeout(() => {
          setCopyStatus('idle')
          setTimeoutId(null)
        }, timeoutDuration)

        setTimeoutId(newTimeoutId)
      }
    },
    [copyToClipboard, timeoutDuration, timeoutId],
  )

  React.useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [timeoutId])

  return {
    copiedText,
    copyStatus,
    copy,
  } as const
}
