import { queryOptions } from '@tanstack/react-query'
import ms from 'ms'
import { deepSnakeKeys } from 'string-ts'

import type {
  GetClinicTreatmentExerciseOptions,
  GetClinicTreatmentOptions,
  GetClinicTreatmentsOptions,
} from '@/api/fysioscout/endpoints/clinic-treatment/models'
import type { DeepCamelKeys } from 'string-ts'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicTreatmentQueries = {
  all: () => ['clinic-treatments'],
  lists: () => [...clinicTreatmentQueries.all(), 'lists'],

  /**
   * List all treatments for a patient
   *
   * @param params - The params for the query
   * @param opts - Extra options for the query
   * @param opts.enabled - Whether the query should be enabled
   * @returns The query option
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Treatment-or-Shared/paths/~1api~1clinics~1patients~1%7Bpatient_id%7D~1treatments/get}
   */
  treatments: (
    params: DeepCamelKeys<GetClinicTreatmentsOptions['params']>,
    opts: { enabled?: boolean } = {},
  ) => {
    return queryOptions({
      queryKey: [...clinicTreatmentQueries.lists(), params],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET('/api/clinics/patients/{patient_id}/treatments', {
            signal,
            params: deepSnakeKeys(params),
          }),
        )
      },
      ...opts,
    })
  },

  /**
   * Get a single treatment for a patient
   *
   * @param params - The path parameters
   * @param opts - Extra options for the query
   * @param opts.enabled - Whether the query should be enabled
   * @returns The query option
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Treatment-or-Shared/paths/~1api~1clinics~1patients~1%7Bpatient_id%7D~1treatments~1%7Btreatment_id%7D/get}
   */
  treatment: (
    params: DeepCamelKeys<GetClinicTreatmentOptions['params']>,
    opts?: { enabled?: boolean },
  ) => {
    return queryOptions({
      queryKey: [...clinicTreatmentQueries.all(), 'treatment', params],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET('/api/clinics/patients/{patient_id}/treatments/{treatment_id}', {
            signal,
            params: deepSnakeKeys(params),
          }),
        )
      },
      refetchInterval: ms('15s'),
      enabled: opts?.enabled,
    })
  },

  /**
   * Get exercise by id of a particular treatment
   *
   * @param params - The path parameters
   * @returns The query option
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Treatment-or-Shared/paths/~1api~1clinics~1patients~1%7Bpatient_id%7D~1treatments~1%7Btreatment_id%7D~1exercises~1%7Bexercise_id%7D/get}
   */
  exercise: (params: DeepCamelKeys<GetClinicTreatmentExerciseOptions['params']>) => {
    return queryOptions({
      queryKey: [...clinicTreatmentQueries.all(), 'exercise', params],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET(
            '/api/clinics/patients/{patient_id}/treatments/{treatment_id}/exercises/{exercise_id}',
            {
              signal,
              params: deepSnakeKeys(params),
            },
          ),
        )
      },
    })
  },
}
