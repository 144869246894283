import { createFileRoute } from '@tanstack/react-router'

import { clinicVideoConsultationsQueries } from '@/api/fysioscout/endpoints/clinic-video-consultations/queries'
import { dayjs } from '@/lib/dayjs'

import { TranscriptionView } from './-transcription-view'

export const Route = createFileRoute(
  '/_auth/video-consultation/patient/$patientId/consultation/$consultationId/transcription/',
)({
  component: TranscriptionView,
  loader: async ({ context: { queryClient }, params: { consultationId } }) => {
    const transcriptionQuery = queryClient.ensureQueryData(
      clinicVideoConsultationsQueries.transcription({ path: { id: consultationId } }),
    )

    const consultationQuery = queryClient.ensureQueryData(
      clinicVideoConsultationsQueries.byId({ path: { id: consultationId } }),
    )

    const [transcription, consultation] = await Promise.all([transcriptionQuery, consultationQuery])

    return {
      transcription,
      consultation,
    }
  },
  head: ({ loaderData }) => {
    const date = dayjs.format(loaderData?.consultation.scheduled_date, { dateStyle: 'long' })

    return {
      meta: [{ title: `Transskribering (${date})` }],
    }
  },
})
