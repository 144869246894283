import { createFileRoute } from '@tanstack/react-router'

import { PatientsRoute } from '@/app/routes/_auth/patients/-patients-route'

export const Route = createFileRoute('/_auth/patients')({
  head: () => ({
    meta: [{ title: 'Patienter' }],
  }),
  component: PatientsRoute,
})
