import type { CamelCase } from 'string-ts'

import { createStrictContext } from '@fysioscout/utils/react'

export type PatientAction = 'view' | 'detach' | 'change-practitioner' | 'change-email'
export type PatientActionRecord = Record<CamelCase<PatientAction>, PatientAction>

interface PatientTableContextValue {
  /** An array of actions that are disabled for the row. */
  enabledActions: PatientAction[] | 'all'
}

export const [PatientTableContext, usePatientTableContext] =
  createStrictContext<PatientTableContextValue>({
    name: 'PatientTableContext',
    errorMessage: 'usePatientTable must be used within a PatientListContext.',
    isDevelopment: import.meta.env.DEV,
  })
