import React from 'react'
import { Link } from '@tanstack/react-router'
import { match } from 'ts-pattern'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { TableProps } from '@fysioscout/ui/collections/table'
import type { SortDescriptor } from 'react-aria-components'

import { Cell, Column, Row, Table, TableBody, TableHeader } from '@fysioscout/ui/collections/table'
import { UnstyledLink } from '@fysioscout/ui/navigation/unstyled-link'
import { Text } from '@fysioscout/ui/typography/text'

import { TreatmentShareMenu } from '@/features/treatments/components/treatment/treatment-share-menu'
import { TreatmentTableActions } from '@/features/treatments/components/treatment/treatment-table-actions'
import { dayjs } from '@/lib/dayjs'
import { sortFunctions } from '@/lib/sort-functions'

const COLUMNS = {
  treatment: 'treatment',
  createdAt: 'created-at',
  updatedAt: 'updated-at',
  share: 'share',
  seeTreatment: 'see-treatment',
} as const

interface TreatmentListProps extends TableProps {
  patient: Schemas['ClinicPatientDto']
  treatments: Schemas['TreatmentDto'][]
}

export function TreatmentTable({ patient, treatments, ...rest }: TreatmentListProps) {
  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: COLUMNS.updatedAt,
    direction: 'descending',
  })

  const items = React.useMemo(() => {
    return [...treatments].sort((a, b) => {
      const d = match(sortDescriptor.column)
        .with(COLUMNS.treatment, () => sortFunctions.string(a.name ?? '', b.name ?? ''))
        .with(COLUMNS.createdAt, () => sortFunctions.date(a.created_at, b.created_at))
        .with(COLUMNS.updatedAt, () => sortFunctions.date(a.updated_at, b.updated_at))

        .otherwise(() => 0)

      return sortDescriptor.direction === 'descending' ? -d : d
    })
  }, [treatments, sortDescriptor])

  return (
    <Table
      selectionMode={'none'}
      onSelectionChange={console.log}
      className={'mt-2'}
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
      aria-label={'Behandlingsforløb'}
      {...rest}
    >
      <TableHeader>
        <Column id={COLUMNS.treatment} isRowHeader allowsSorting defaultWidth={250}>
          Forløb
        </Column>

        <Column id={COLUMNS.createdAt} allowsSorting defaultWidth={250}>
          Oprettet
        </Column>

        <Column id={COLUMNS.updatedAt} allowsSorting defaultWidth={250}>
          Senest opdateret
        </Column>

        <Column id={COLUMNS.share} width={'1fr'}>
          Del
        </Column>

        <Column id={COLUMNS.seeTreatment} width={50} />
      </TableHeader>

      <TableBody items={items} renderEmptyState={() => 'Ingen behandlinger fundet.'}>
        {(treatment) => (
          <Row>
            <Cell textValue={treatment.name ?? ''}>
              <UnstyledLink>
                <Link
                  to={'/patients/$patientId/treatments/$treatmentId'}
                  params={{ treatmentId: treatment.id, patientId: patient.patient_details.id }}
                  search={{ view: 'exercises' }}
                  className={'hover:text-accent-11'}
                >
                  <Text>{treatment.name}</Text>
                </Link>
              </UnstyledLink>
            </Cell>

            <Cell>
              <Text>{dayjs.to(treatment.created_at)}</Text>
            </Cell>

            <Cell>
              <Text>{dayjs.to(treatment.updated_at)}</Text>
            </Cell>

            <Cell>
              <TreatmentShareMenu treatment={treatment} />
            </Cell>

            <Cell>
              <TreatmentTableActions patient={patient} treatment={treatment} />
            </Cell>
          </Row>
        )}
      </TableBody>
    </Table>
  )
}
