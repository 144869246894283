import { useNavigate } from '@tanstack/react-router'
import { EllipsisVerticalIcon, ExternalLinkIcon, GlobeIcon, LogOutIcon } from 'lucide-react'
import { MenuTrigger } from 'react-aria-components'
import { match } from 'ts-pattern'

import type { Key } from 'react-aria-components'

import { Button } from '@fysioscout/ui/buttons/button'
import { Menu, MenuItem } from '@fysioscout/ui/collections/menu'
import { Tooltip, TooltipTrigger } from '@fysioscout/ui/overlays/tooltip'

const menuActionKeys = {
  logout: 'logout',
  terms: 'terms',
  language: 'language',
} as const

export function QuickSettingsMenu() {
  const navigate = useNavigate()

  const handleAction = (key: Key) => {
    match(key).with(menuActionKeys.logout, () => {
      void navigate({ to: '/logout' })
    })
  }

  return (
    <MenuTrigger>
      <TooltipTrigger>
        <Button variant={'ghost'} size={'sm'} color={'neutral'} aria-label={'Menu'}>
          <EllipsisVerticalIcon />
        </Button>

        <Tooltip>Menu</Tooltip>
      </TooltipTrigger>

      <Menu
        selectionMode={'none'}
        disallowEmptySelection
        onAction={handleAction}
        disabledKeys={[menuActionKeys.language]}
        aria-label={'Menu'}
      >
        <MenuItem id={menuActionKeys.language} icon={<GlobeIcon />}>
          Skift sprog
        </MenuItem>

        <MenuItem
          id={menuActionKeys.terms}
          icon={<ExternalLinkIcon />}
          href={'https://www.fysioscout.dk/legal/handelsbetingelser'}
          target={'_blank'}
        >
          Læs betingelser
        </MenuItem>

        <MenuItem id={menuActionKeys.logout} icon={<LogOutIcon />} isLink>
          Log ud
        </MenuItem>
      </Menu>
    </MenuTrigger>
  )
}
