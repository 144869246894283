import React from 'react'

import { CreatePatientContext } from '../context'
import { createPatientStore } from '../store'
import { CreatePatientDrawer } from './create-patient-drawer'

export function CreatePatientProvider({ children }: { children: React.ReactNode }) {
  const [store] = React.useState(() => createPatientStore())

  return (
    <CreatePatientContext value={{ store }}>
      <CreatePatientDrawer />

      {children}
    </CreatePatientContext>
  )
}
