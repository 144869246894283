import React from 'react'
import { useQuery } from '@tanstack/react-query'

import { clinicEmployeeQueries } from '@/api/fysioscout/endpoints/clinic-employee/queries'

import { ChangePractitionerContext } from '../context'
import { createChangePractitionerStore } from '../store'
import { ChangePractitionerDrawer } from './change-practitioner-drawer'

export function ChangePractitionerProvider({ children }: { children: React.ReactNode }) {
  const [store] = React.useState(() => createChangePractitionerStore())
  const query = useQuery(clinicEmployeeQueries.list())

  const employees = query.data ?? []

  return (
    <ChangePractitionerContext value={{ store, employees }}>
      <ChangePractitionerDrawer />

      {children}
    </ChangePractitionerContext>
  )
}
