'use client'

import React from 'react'
import { motion, useReducedMotion } from 'framer-motion'
import { ChevronRightIcon } from 'lucide-react'
import { Button as RACButton } from 'react-aria-components'
import { match } from 'ts-pattern'

import { useCollapsiblePanelContext } from './collapsible-panel-context'

const variants = {
  default: {
    initial: (isOpen: boolean) => ({ rotate: isOpen ? 90 : 0 }),
    animate: (isOpen: boolean) => ({
      rotate: isOpen ? 90 : 0,
      transition: { type: 'spring', duration: 0.7, bounce: 0.4 },
    }),
  },
  reducedMotion: {
    initial: (isOpen: boolean) => ({ rotate: isOpen ? 90 : 0 }),
    animate: (isOpen: boolean) => ({
      rotate: isOpen ? 90 : 0,
      transition: { duration: 0.2 },
    }),
  },
} as const

export interface CollapsiblePanelTriggerProps {
  children?: React.ReactNode
}

export function CollapsiblePanelTrigger(props: CollapsiblePanelTriggerProps) {
  const { isOpen, onOpenChange, isDisabled } = useCollapsiblePanelContext()

  const prefersReducedMotion = useReducedMotion()

  const currentVariants = match(prefersReducedMotion)
    .with(true, () => variants.reducedMotion)
    .otherwise(() => variants.default)

  return (
    <RACButton
      className={'hover:bg-surface-hover group w-full cursor-default rounded-t-lg outline-none'}
      isDisabled={isDisabled}
      onPress={() => {
        onOpenChange(!isOpen)
      }}
    >
      <div className={'hstack items-center justify-between p-4'}>
        {props.children}

        <div className={'mr-2 flex items-center'}>
          <motion.div
            initial={currentVariants.initial(isOpen)}
            animate={currentVariants.animate(isOpen)}
            aria-hidden={'true'}
          >
            <ChevronRightIcon className={'size-5'} aria-label={isOpen ? 'Skjul' : 'Vis'} />
          </motion.div>
        </div>
      </div>
    </RACButton>
  )
}
