import React from 'react'

import { ResendEmailContext } from '../context'
import { createResendEmailStore } from '../store'

interface ResendEmailProviderProps {
  children?: React.ReactNode
}

export function ResendConsultationEmailProvider({ children }: ResendEmailProviderProps) {
  const [store] = React.useState(() => createResendEmailStore())

  return <ResendEmailContext value={{ store }}>{children}</ResendEmailContext>
}
