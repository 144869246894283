import { keepPreviousData, queryOptions } from '@tanstack/react-query'
import ms from 'ms'

import type { GetClinicTemplateExercisesOptions } from '@/api/fysioscout/endpoints/clinic-template-exercise/models'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicTemplateExerciseQueries = {
  all: () => ['clinic-template-exercises'],
  lists: () => [...clinicTemplateExerciseQueries.all(), 'lists'],

  /**
   * List all template exercises for a clinic
   *
   * @param params - The params for the query
   * @returns The query option
   * @see {@link https://api.fysioscout.com/api/documentation/redoc#tag/Clinic-Template-Exercise-or-Shared/paths/~1api~1clinics~1template-exercises/get}
   */
  list: (params: GetClinicTemplateExercisesOptions['params'] = {}) => {
    return queryOptions({
      queryKey: [...clinicTemplateExerciseQueries.lists(), 'list', params],
      queryFn: async ({ signal }) => {
        return unwrap(fysioscoutClient.GET('/api/clinics/template-exercises', { signal, params }))
      },
      gcTime: ms('10s'),
      placeholderData: keepPreviousData,
    })
  },
}
