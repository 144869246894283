'use client'

import React from 'react'
import { isMac } from '@react-aria/utils'
import { useKeyPress } from 'ahooks'
import { CommandIcon, SearchIcon, XIcon } from 'lucide-react'
import { useObjectRef } from 'react-aria'
import { SearchField as RACSearchField, composeRenderProps } from 'react-aria-components'

import type {
  SearchFieldProps as RACSearchFieldProps,
  ValidationResult,
} from 'react-aria-components'

import { Button } from '../../buttons/button/button'
import { Description, FieldError, FieldGroup, Input, Label } from '../field/field'
import { fieldStyles } from '../field/field.styles'

export interface SearchFieldProps extends RACSearchFieldProps {
  label?: string
  description?: string
  errorMessage?: string | ((validation: ValidationResult) => string)
  ref?: React.Ref<React.ComponentRef<typeof Input>>
}

export function SearchField({
  label,
  description,
  errorMessage,
  className,
  ref: forwardedRef,
  ...rest
}: SearchFieldProps) {
  const ref = useObjectRef(forwardedRef)

  useKeyPress(
    ['meta.k', 'ctrl.k'],
    () => {
      ref.current?.focus()
    },
    { exactMatch: true },
  )

  return (
    <RACSearchField
      {...rest}
      className={composeRenderProps(className, (className, renderProps) =>
        fieldStyles({ ...renderProps, className }),
      )}
    >
      {label ? <Label>{label}</Label> : null}

      <FieldGroup className={'relative'}>
        <SearchIcon
          aria-hidden
          className={
            'text-muted-foreground absolute left-0 ml-2 size-4 group-disabled:text-transparent'
          }
        />

        <Input ref={ref} className={'px-8 [&::-webkit-search-cancel-button]:hidden'} />
        <FocusHotkey />
        <ClearButton />
      </FieldGroup>

      {description ? <Description>{description}</Description> : null}
      <FieldError>{errorMessage}</FieldError>
    </RACSearchField>
  )
}

function FocusHotkey() {
  return (
    <div
      className={'text-2xs text-muted-foreground invisible absolute right-2.5 group-empty:visible'}
    >
      {isMac() ? (
        <div className={'flex items-center gap-px'}>
          <CommandIcon className={'size-2'} />
          <span>K</span>
        </div>
      ) : (
        <div className={'flex items-center gap-px'}>
          <span>Ctrl</span>
          <span>+</span>
          <span>K</span>
        </div>
      )}
    </div>
  )
}

function ClearButton() {
  return (
    <Button
      className={'absolute right-1 group-empty:invisible'}
      variant={'transparent'}
      color={'neutral'}
      size={'sm'}
    >
      <XIcon />
    </Button>
  )
}
