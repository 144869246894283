import { queryOptions } from '@tanstack/react-query'

import type {
  GetAcceptedClinicPatientsOptions,
  GetPendingClinicPatientsOptions,
} from '@/api/fysioscout/endpoints/clinic-patient/models'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicPatientQueries = {
  all: () => ['clinic-patients'],
  lists: () => [...clinicPatientQueries.all(), 'lists'],

  /**
   * List accepted clinic patients
   *
   * @param params - The query parameters
   * @returns The query option
   * @see {@link https://api.fysioscout.com/api/documentation/scalar#tag/clinic-patient--web--v1/get/api/web/v1/clinics/patients/accepted}
   */
  accepted: (params?: GetAcceptedClinicPatientsOptions['params']) => {
    return queryOptions({
      queryKey: [...clinicPatientQueries.lists(), 'accepted', params],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET('/api/web/v1/clinics/patients/accepted', {
            signal,
            params,
          }),
        )
      },
    })
  },

  /**
   * List pending clinic patients
   *
   * @param params - The query parameters
   * @returns The query option
   * @see {@link https://api.fysioscout.com/api/documentation/scalar#tag/clinic-patient--web--v1/get/api/web/v1/clinics/patients/pending}
   */
  pending: (params?: GetPendingClinicPatientsOptions['params']) => {
    return queryOptions({
      queryKey: [...clinicPatientQueries.lists(), 'pending', params],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET('/api/web/v1/clinics/patients/pending', {
            signal,
            params,
          }),
        )
      },
    })
  },

  /**
   * Get clinic patient by id
   *
   * @param patientId - The id of the clinic patient
   * @returns The query option
   */
  getById: (patientId: string) => {
    return queryOptions({
      queryKey: [...clinicPatientQueries.all(), patientId],
      queryFn: ({ signal }) => {
        return unwrap(
          fysioscoutClient.GET('/api/web/v1/clinics/patients/{patient_id}', {
            signal,
            params: { path: { patient_id: patientId } },
          }),
        )
      },
    })
  },
}
