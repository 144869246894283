import { useMutation, useQueryClient } from '@tanstack/react-query'

import { clinicVideoConsultationMutations } from '@/api/fysioscout/endpoints/clinic-video-consultations/mutations'
import { clinicVideoConsultationsQueries } from '@/api/fysioscout/endpoints/clinic-video-consultations/queries'

/**
 * Edit a video consultation
 *
 * @example
 *   const { mutate, isLoading, isError } = useEditVideoConsultation()
 *   mutate(editVideoConsultationOptions)
 *
 * @returns - The result of the mutation.
 */
export function useEditConsultation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: clinicVideoConsultationMutations.update,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: clinicVideoConsultationsQueries.all() })
    },
  })
}
