import React from 'react'
import { drop, pipe, take } from 'remeda'
import { match } from 'ts-pattern'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { PaginatedTableProps } from '@fysioscout/ui/collections/table'
import type { ColumnProps, SortDescriptor, SortDirection } from 'react-aria-components'

import {
  Column,
  Pagination,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@fysioscout/ui/collections/table'

import { sortFunctions } from '@/lib/sort-functions'

import { ConsultationTableRowContext } from '../../contexts/consultation-table-row-context'
import { CreatedAtCell } from './cells/created-at-cell'
import { CreatedByCell } from './cells/created-by-cell'
import { DurationCell } from './cells/duration-cell'
import { PatientCell } from './cells/patient-cell'
import { ScheduledDateCell } from './cells/scheduled-date-cell'
import { TranscriptionCell } from './cells/transcription-cell'

const COLUMNS = {
  patient: 'patient',
  createdAt: 'created-at',
  scheduledDate: 'scheduled-date',
  createdBy: 'created-by',
  duration: 'duration',
  transcription: 'transcription',
} as const

type ColumnId = (typeof COLUMNS)[keyof typeof COLUMNS]

interface ColumnConfig extends Omit<ColumnProps, 'id'> {
  id: ColumnId
  name: string
  isHidden?: boolean
}

const columns = [
  { name: 'Patient', id: 'patient', defaultWidth: '1fr', allowsSorting: true },
  { name: 'Afholdelse start', id: 'scheduled-date', defaultWidth: '1fr', allowsSorting: true },
  { name: 'Link afsendt', id: 'created-at', defaultWidth: '1fr', allowsSorting: true },
  { name: 'Oprettet af', id: 'created-by', defaultWidth: '0.85fr', allowsSorting: true },
  { name: 'Varighed', id: 'duration', defaultWidth: '0.7fr', allowsSorting: true },
  { name: 'Transskribering', id: 'transcription', width: '1fr', allowsSorting: false },
] satisfies ColumnConfig[]

interface ClinicFinishedVcTableProps extends PaginatedTableProps {
  consultations: Schemas['ConsultationResponseDto'][]

  /** The default column to sort by. */
  defaultSortColumn?: ColumnId

  /** The default sort direction. */
  defaultSortDirection?: SortDirection
}

export function ClinicFinishedVcTable({
  consultations,
  defaultSortColumn = 'scheduled-date',
  defaultSortDirection = 'descending',
  itemsPerPage = 12,
  pageNumber = 1,
  onPageChange,
  ...rest
}: ClinicFinishedVcTableProps) {
  const [currentPage, setCurrentPage] = React.useState(pageNumber)

  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: defaultSortColumn,
    direction: defaultSortDirection,
  })

  const sortedConsultations = React.useMemo(() => {
    return [...consultations].sort((a, b) => {
      const d = match(sortDescriptor.column)
        .with(COLUMNS.patient, () => sortFunctions.string(a.patient.name, b.patient.name))
        .with(COLUMNS.createdAt, () => sortFunctions.date(a.created_at, b.created_at))
        .with(COLUMNS.scheduledDate, () => sortFunctions.date(a.scheduled_date, b.scheduled_date))
        .with(COLUMNS.duration, () =>
          sortFunctions.string(String(a.duration ?? '-'), String(b.duration ?? '-')),
        )

        .otherwise(() => 0)

      return sortDescriptor.direction === 'descending' ? -d : d
    })
  }, [consultations, sortDescriptor.column, sortDescriptor.direction])

  const paginatedConsultations = React.useMemo(() => {
    return pipe(sortedConsultations, drop((currentPage - 1) * itemsPerPage), take(itemsPerPage))
  }, [currentPage, itemsPerPage, sortedConsultations])

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
    onPageChange?.(newPage)
  }

  const showPagination = consultations.length > itemsPerPage

  return (
    <div data-testid={'video-consultation-table'} className={'stack'}>
      <Table
        aria-label={'Patienter'}
        selectionMode={'none'}
        className={'mt-2'}
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        {...rest}
      >
        <TableHeader columns={columns}>
          {(column) => (
            <Column {...column} isRowHeader={column.id === 'patient'}>
              {column.name}
            </Column>
          )}
        </TableHeader>

        <TableBody
          items={paginatedConsultations}
          renderEmptyState={() => 'Ingen patienter fundet.'}
        >
          {(consultation) => (
            <Row>
              <ConsultationTableRowContext value={{ consultation }}>
                <PatientCell />
                <ScheduledDateCell />
                <CreatedAtCell />
                <CreatedByCell />
                <DurationCell />
                <TranscriptionCell />
              </ConsultationTableRowContext>
            </Row>
          )}
        </TableBody>
      </Table>

      {showPagination ? (
        <Pagination
          totalItems={consultations.length}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          currentPage={currentPage}
        />
      ) : null}
    </div>
  )
}
