import React from 'react'
import Fuse from 'fuse.js'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { Panel, PanelHeading } from '@fysioscout/ui/layout/panel'
import { Badge } from '@fysioscout/ui/status/badge'

import { PatientTable } from '@/features/patients/components/patient-table'
import { PatientsByEmployee } from '@/features/patients/components/patients-by-employee'
import { SEARCH_MIN_CHAR_LENGTH } from '@/features/patients/config/constants'

import { PatientTableContext } from '../contexts/patient-table-context'

interface AcceptedProps {
  searchQuery?: string
  list: Schemas['ClinicEmployeeWithPatientsDto'][]
}

const keys = [
  'patient_details.name',
  'patient_details.email',
] satisfies `patient_details.${keyof Schemas['ClinicPatientDto']['patient_details']}`[]

export function AcceptedPatients({ searchQuery = '', list }: AcceptedProps) {
  const isQueryValid = searchQuery.length >= SEARCH_MIN_CHAR_LENGTH

  const fuse = React.useMemo(() => {
    const patients = list.flatMap((employee) => employee.patients)

    return new Fuse(patients, {
      keys,
      threshold: 0.2,
      minMatchCharLength: SEARCH_MIN_CHAR_LENGTH,
    })
  }, [list])

  const results = isQueryValid
    ? fuse.search(searchQuery, { limit: 50 }).map((result) => result.item)
    : []

  const renderList = () => {
    if (isQueryValid) {
      return (
        <Panel>
          <div className={'hstack items-center'}>
            <PanelHeading>Søgeresultater for &quot;{searchQuery}&quot;</PanelHeading>
            <Badge variant={'neutral'}>{results.length}</Badge>
          </div>

          <PatientTable
            aria-label={`Søgeresultater for "${searchQuery}"`}
            patients={results}
            hideColumns={['deletion']}
          />
        </Panel>
      )
    }

    return <PatientsByEmployee data={list} />
  }

  return (
    <PatientTableContext value={{ enabledActions: ['view', 'change-practitioner', 'detach'] }}>
      {renderList()}
    </PatientTableContext>
  )
}
