import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSpinDelay } from 'spin-delay'

import type { EditConsultationContextValue } from '../context'

import { clinicEmployeeQueries } from '@/api/fysioscout/endpoints/clinic-employee/queries'
import { createEditConsultationStore } from '@/features/video-consultation/user-actions/edit-consultation/store'

import { EditConsultationContext } from '../context'

interface EditConsultationProviderProps {
  children?: React.ReactNode
}

export function EditConsultationProvider({ children }: EditConsultationProviderProps) {
  const employeesQuery = useQuery(clinicEmployeeQueries.list())

  const isLoading = useSpinDelay(employeesQuery.isLoading, {
    minDuration: 400,
    delay: 0,
  })

  const value = React.useMemo(() => {
    const employees = employeesQuery.data ?? []
    const store = createEditConsultationStore()

    return { store, employees, isLoading } satisfies EditConsultationContextValue
  }, [employeesQuery.data, isLoading])

  return <EditConsultationContext value={value}>{children}</EditConsultationContext>
}
