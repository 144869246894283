import { createFileRoute, redirect } from '@tanstack/react-router'

import { VideoConsultationRoute } from './-video-consultation-route'

export const Route = createFileRoute('/_auth/video-consultation')({
  beforeLoad: ({ context: { hypertune } }) => {
    const isEnabled = hypertune.enableVideoConsultationPage({ fallback: false })

    if (!isEnabled) {
      throw redirect({ to: '/patients' })
    }
  },
  head: () => ({
    meta: [{ title: 'Videokonsultation' }],
  }),
  component: VideoConsultationRoute,
})
