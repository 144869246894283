'use client'

import React from 'react'
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion'
import { match } from 'ts-pattern'

import { useCollapsiblePanelContext } from './collapsible-panel-context'

const variants = {
  default: {
    initial: { height: 0 },
    animate: {
      height: 'auto',
      transition: { type: 'spring', duration: 0.55, bounce: 0.2 },
    },
    exit: {
      height: 0,
      transition: { type: 'spring', duration: 0.35, bounce: 0 },
    },
  },
  reducedMotion: {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: { duration: 0.2 },
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.2 },
    },
  },
} as const

export interface CollapsiblePanelContentProps {
  children?: React.ReactNode
}

export function CollapsiblePanelContent(props: CollapsiblePanelContentProps) {
  const { isOpen } = useCollapsiblePanelContext()

  const prefersReducedMotion = useReducedMotion()

  const currentVariants = match(prefersReducedMotion)
    .with(true, () => variants.reducedMotion)
    .otherwise(() => variants.default)

  return (
    <AnimatePresence initial={false}>
      {isOpen ? (
        <div className={'overflow-hidden'}>
          <motion.div
            variants={currentVariants}
            initial={'initial'}
            animate={'animate'}
            exit={'exit'}
          >
            {props.children}
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>
  )
}
