import React from 'react'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import {
  Classification,
  Consent,
  Panel,
  PanelHeader,
  PanelItem,
  PanelItemDescription,
  PanelItemDescriptionList,
  PanelItemLabel,
} from '../shared'

interface FollowUpConsultationProps {
  transcription: Schemas['FollowUpConsultationV2']
}

export function FollowUpConsultationV2(props: FollowUpConsultationProps) {
  return (
    <div data-testid={'follow-up-consultation'} className={'stack gap-8'}>
      <Classification type={'follow_up_v2'} classification={props.transcription.classification} />
      <ExaminationAndTreatment
        examinationAndTreatment={props.transcription.examination_and_treatment}
      />
      <Consent consent={props.transcription.consent} />
    </div>
  )
}

interface ExaminationAndTreatmentProps {
  examinationAndTreatment: Schemas['FollowUpExaminationAndTreatmentV2']
}

function ExaminationAndTreatment(props: ExaminationAndTreatmentProps) {
  return (
    <Panel
      left={
        <PanelHeader
          heading={'Examination og behandling'}
          description={
            'Opfølgende examination og behandling dokumenterer patientens fremskridt, nye fund og den videre behandlingsplan.'
          }
        />
      }
      right={
        <>
          <PanelItem>
            <PanelItemLabel>Reaktion på forrige behandling</PanelItemLabel>

            <PanelItemDescription>
              {props.examinationAndTreatment.previous_treatment_response}
            </PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Status</PanelItemLabel>

            <PanelItemDescription>
              {props.examinationAndTreatment.status.patient_feedback}
            </PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Monitorering</PanelItemLabel>

            <PanelItemDescriptionList>
              <PanelItemDescription>
                Funktionel score: {props.examinationAndTreatment.monitoring.functional_score}
              </PanelItemDescription>

              <PanelItemDescription>
                Smerteskala: {props.examinationAndTreatment.monitoring.pain_scale}
              </PanelItemDescription>
            </PanelItemDescriptionList>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Nye undersøgelsesfund</PanelItemLabel>

            <PanelItemDescription>
              {props.examinationAndTreatment.new_examination_findings}
            </PanelItemDescription>
          </PanelItem>

          <PanelItem>
            <PanelItemLabel>Behandling og plan</PanelItemLabel>

            <PanelItemDescriptionList>
              <PanelItemDescription>
                Nuværende behandling:{' '}
                {props.examinationAndTreatment.treatment_and_plan.current_treatment}
              </PanelItemDescription>

              <PanelItemDescription>
                Opfølgningsplan:{' '}
                {props.examinationAndTreatment.treatment_and_plan.follow_up_schedule}
              </PanelItemDescription>

              <PanelItemDescription>
                Supplerende anbefalinger:{' '}
                {props.examinationAndTreatment.treatment_and_plan.supplementary_recommendations}
              </PanelItemDescription>
            </PanelItemDescriptionList>
          </PanelItem>
        </>
      }
    />
  )
}
