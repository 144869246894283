import React from 'react'

/**
 * Creates a timer that calls a callback after a specified delay when the mouse enters an element.
 * The timer is cleared when the mouse leaves the element.
 *
 * @example
 *   const { enter, leave } = useHoverTimer(() => {
 *     // Do something after 500ms
 *   }, 500)
 *
 * @param callback - The function to call after the delay.
 * @param delay - The delay in milliseconds before the callback is called. Defaults to 50.
 * @returns An object containing `onMouseEnter` and `onMouseLeave` callbacks.
 */
export function useHoverTimer(callback: () => void, delay = 50) {
  const hoverTimerRef = React.useRef<NodeJS.Timeout>(null)

  const handleStart = React.useCallback(() => {
    hoverTimerRef.current = globalThis.setTimeout(callback, delay)
  }, [callback, delay])

  const handleEnd = React.useCallback(() => {
    if (hoverTimerRef.current) {
      globalThis.clearTimeout(hoverTimerRef.current)
      hoverTimerRef.current = null
    }
  }, [])

  React.useEffect(() => {
    return () => {
      if (hoverTimerRef.current) {
        globalThis.clearTimeout(hoverTimerRef.current)
      }
    }
  }, [])

  return {
    start: handleStart,
    end: handleEnd,
  }
}
