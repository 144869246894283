import { Outlet } from '@tanstack/react-router'

import { ChangePatientEmailProvider } from '@/features/patients/user-actions/change-email/components/change-patient-email-provider'
import { ChangePractitionerProvider } from '@/features/patients/user-actions/change-practitioner/components/change-practitioner-provider'
import { CreatePatientProvider } from '@/features/patients/user-actions/create-patient/components/create-patient-provider'

export function PatientsRoute() {
  return (
    <ChangePractitionerProvider>
      <CreatePatientProvider>
        <ChangePatientEmailProvider>
          <Outlet />
        </ChangePatientEmailProvider>
      </CreatePatientProvider>
    </ChangePractitionerProvider>
  )
}
