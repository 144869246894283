import { create, useStore } from 'zustand'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { useChangeEmailContext } from './context'

export interface ChangePatientEmailStore {
  patientId: Schemas['ClinicPatientDto']['patient_details']['id'] | null
  patient: Schemas['ClinicPatientDto'] | null
  actions: {
    setPatientId: (patientId: Schemas['ClinicPatientDto']['patient_details']['id'] | null) => void
    setPatient: (patient: Schemas['ClinicPatientDto'] | null) => void
    reset: () => void
  }
}

type InitialState = Omit<ChangePatientEmailStore, 'actions'>

const INITIAL_STATE = {
  patientId: null,
  patient: null,
} satisfies InitialState

export function createChangeEmailFromClinicStore(initialState: InitialState = INITIAL_STATE) {
  return create<ChangePatientEmailStore>((set) => ({
    ...initialState,
    actions: {
      setPatientId: (patientId) => {
        set({ patientId })
      },
      setPatient: (patient) => {
        set({ patient })
      },
      reset: () => {
        set(INITIAL_STATE)
      },
    },
  }))
}

function useChangePatientEmailStore<T>(selector: (state: ChangePatientEmailStore) => T) {
  const { store } = useChangeEmailContext()

  return useStore(store, selector)
}

export function usePatientId() {
  return useChangePatientEmailStore((state) => state.patientId)
}

export function usePatient() {
  return useChangePatientEmailStore((state) => state.patient)
}

export function useIsOpen() {
  return useChangePatientEmailStore((state) => Boolean(state.patientId))
}

export function useChangePatientEmailActions() {
  return useChangePatientEmailStore((state) => state.actions)
}
