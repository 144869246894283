import { queryOptions } from '@tanstack/react-query'

import type { ListClinicTemplateRegionsOptions } from '@/api/fysioscout/endpoints/clinic-template-region/models'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/api/fysioscout/client'

export const clinicTemplateRegionQueries = {
  all: () => ['clinic-template-regions'],
  lists: () => [...clinicTemplateRegionQueries.all(), 'lists'],

  /**
   * List Regions
   *
   * @param params - The params for the query
   * @returns The query option
   * @see {@link https://api.fysioscout.com/api/documentation/scalar#tag/clinic-template-region--shared/get/api/clinics/template-regions}
   */
  listRegions: (params?: ListClinicTemplateRegionsOptions['params']) => {
    return queryOptions({
      queryKey: [...clinicTemplateRegionQueries.lists(), params],
      queryFn: ({ signal }) => {
        return unwrap(fysioscoutClient.GET('/api/clinics/template-regions', { signal, params }))
      },
    })
  },
}
