import React from 'react'
import ms from 'ms'

import { HypertuneProvider as FSHypertuneProvider } from '@fysioscout/hypertune/hypertune.react'

import { env } from '@/config/env'
import { useAuthContext } from '@/features/auth/context'

interface HypertuneProviderProps {
  children?: React.ReactNode
}

export function HypertuneProvider({ children }: HypertuneProviderProps) {
  const { user } = useAuthContext()

  if (!env.VITE_HYPERTUNE_ENABLED || !env.VITE_HYPERTUNE_ENV || !env.VITE_HYPERTUNE_TOKEN)
    return <>{children}</>

  return (
    <FSHypertuneProvider
      createSourceOptions={{
        initDataRefreshIntervalMs: ms('5m'),
        token: env.VITE_HYPERTUNE_TOKEN,
      }}
      rootArgs={{
        context: {
          environment: env.VITE_HYPERTUNE_ENV,
          user: { id: user.id, email: user.email ?? '' },
        },
      }}
    >
      {children}
    </FSHypertuneProvider>
  )
}
