import { useHypertune } from '../gen/hypertune.react'

/**
 * Checks if the video consultation feature is enabled.
 *
 * @returns - A boolean indicating whether the video consultation feature is enabled.
 */
export function useEnableVideoConsultationPage() {
  const hypertune = useHypertune()

  return hypertune.enableVideoConsultationPage({ fallback: false })
}

export function useEnableAccountPage() {
  const hypertune = useHypertune()

  return hypertune.enableAccountPage({ fallback: false })
}

export function useEnablePractitionersPage() {
  const hypertune = useHypertune()

  return hypertune.enablePractitionersPage({ fallback: false })
}

export function useEnableTemplatesPage() {
  const hypertune = useHypertune()

  return hypertune.enableTemplatesPage({ fallback: false })
}

export function useEnableSettingsPage() {
  const hypertune = useHypertune()

  return hypertune.enableSettingsPage({ fallback: false })
}
