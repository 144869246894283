import React from 'react'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { FirstTimeConsultationV2 } from './v2/first-time-consultation-v2'
import { FollowUpConsultationV2 } from './v2/follow-up-consultation-v2'
import { FirstTimeConsultationV3 } from './v3/first-time-consultation-v3'
import { FollowUpConsultationV3 } from './v3/follow-up-consultation-v3'

type ConsultationType = Schemas['ConsultationType']

const TranscriptionComponentMap = {
  first_time_v2: FirstTimeConsultationV2,
  first_time_v3: FirstTimeConsultationV3,
  follow_up_v2: FollowUpConsultationV2,
  follow_up_v3: FollowUpConsultationV3,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Type safety is handled by the switch statement where we know the exact type of transcript.data
} as const satisfies Record<ConsultationType['type'], React.ComponentType<any>>

interface Transcription {
  transcript: ConsultationType
}

export function Transcription({ transcript }: Transcription) {
  switch (transcript.type) {
    case 'first_time_v2': {
      const Component = TranscriptionComponentMap.first_time_v2
      return <Component transcription={transcript.data} />
    }

    case 'first_time_v3': {
      const Component = TranscriptionComponentMap.first_time_v3
      return <Component transcription={transcript.data} />
    }

    case 'follow_up_v2': {
      const Component = TranscriptionComponentMap.follow_up_v2
      return <Component transcription={transcript.data} />
    }

    case 'follow_up_v3': {
      const Component = TranscriptionComponentMap.follow_up_v3
      return <Component transcription={transcript.data} />
    }
  }
}
