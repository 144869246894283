import React from 'react'
import { createFileRoute } from '@tanstack/react-router'

import { Spinner } from '@fysioscout/ui/status/spinner'

import { clinicQueries } from '@/api/fysioscout/endpoints/clinic/queries'
import { AuthLayout } from '@/components/layouts/auth-layout'

export const Route = createFileRoute('/_auth')({
  component: AuthLayout,
  beforeLoad: async ({ context: { hypertune } }) => {
    await hypertune.initIfNeeded()
  },
  loader: async ({ context: { queryClient } }) => {
    await queryClient.ensureQueryData(clinicQueries.getSubscription())
  },
  pendingComponent: () => (
    <div className={'stack center size-full -translate-y-16'}>
      <Spinner className={'size-5'} aria-label={'Indlæser...'} />
    </div>
  ),
})
