import React from 'react'
import { drop, pipe, take } from 'remeda'
import { match } from 'ts-pattern'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { PaginatedTableProps } from '@fysioscout/ui/collections/table'
import type { ColumnProps, SortDescriptor, SortDirection } from 'react-aria-components'

import {
  Column,
  Pagination,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@fysioscout/ui/collections/table'

import { EmployeeCell } from '@/features/video-consultation/components/tables/cells/employee-cell'
import { sortFunctions } from '@/lib/sort-functions'

import { ConsultationTableRowContext } from '../../contexts/consultation-table-row-context'
import { ActionsCell } from './cells/actions-cell'
import { CopyLinksCell } from './cells/copy-links-cell'
import { CreatedAtCell } from './cells/created-at-cell'
import { ScheduledDateCell } from './cells/scheduled-date-cell'
import { StartConsultationCell } from './cells/start-consultation-cell'

const COLUMNS = {
  scheduledDate: 'scheduled-date',
  createdAt: 'created-at',
  employee: 'employee',
  links: 'links',
  startConsultation: 'start-consultation',
  actions: 'actions',
} as const

type ColumnId = (typeof COLUMNS)[keyof typeof COLUMNS]

interface ColumnConfig extends Omit<ColumnProps, 'id'> {
  id: ColumnId
  name: string
  isHidden?: boolean
}

const columns = [
  { name: 'Afholdelse start', id: 'scheduled-date', defaultWidth: '1fr', allowsSorting: true },
  { name: 'Link afsendt', id: 'created-at', defaultWidth: '1fr', allowsSorting: true },
  { name: 'Behandler', id: 'employee', defaultWidth: '0.8fr', allowsSorting: true },
  { name: 'Links', id: 'links', defaultWidth: '0.5fr', allowsSorting: false },
  { name: 'Start videokonsultation', id: 'start-consultation', width: '2fr', allowsSorting: false },
  { name: '', id: 'actions', width: 50 },
] satisfies ColumnConfig[]

interface PatientScheduledVcTableProps extends PaginatedTableProps {
  consultations: Schemas['ConsultationResponseDto'][]

  /** The default column to sort by. */
  defaultSortColumn?: ColumnId

  /** The default sort direction. */
  defaultSortDirection?: SortDirection
}

export function PatientScheduledVcTable({
  consultations,
  defaultSortColumn = 'scheduled-date',
  defaultSortDirection = 'ascending',
  itemsPerPage = 12,
  pageNumber = 1,
  onPageChange,
  ...rest
}: PatientScheduledVcTableProps) {
  const [currentPage, setCurrentPage] = React.useState(pageNumber)

  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: defaultSortColumn,
    direction: defaultSortDirection,
  })

  const sortedConsultations = React.useMemo(() => {
    return [...consultations].sort((a, b) => {
      const d = match(sortDescriptor.column)
        .with(COLUMNS.createdAt, () => sortFunctions.date(a.created_at, b.created_at))
        .with(COLUMNS.scheduledDate, () => sortFunctions.date(a.scheduled_date, b.scheduled_date))
        .with(COLUMNS.employee, () => sortFunctions.string(a.employee.name, b.employee.name))
        .otherwise(() => 0)

      return sortDescriptor.direction === 'descending' ? -d : d
    })
  }, [consultations, sortDescriptor.column, sortDescriptor.direction])

  const paginatedConsultations = React.useMemo(() => {
    return pipe(sortedConsultations, drop((currentPage - 1) * itemsPerPage), take(itemsPerPage))
  }, [currentPage, itemsPerPage, sortedConsultations])

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
    onPageChange?.(newPage)
  }

  const showPagination = consultations.length > itemsPerPage

  return (
    <div data-testid={'scheduled-video-consultation-table'}>
      <Table
        aria-label={'Planlagte videokonsultationer'}
        selectionMode={'none'}
        className={'mt-2'}
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        {...rest}
      >
        <TableHeader columns={columns}>
          {(column) => (
            <Column {...column} isRowHeader={column.id === 'scheduled-date'}>
              {column.name}
            </Column>
          )}
        </TableHeader>

        <TableBody
          items={paginatedConsultations}
          renderEmptyState={() => 'Ingen planlagte videokonsultationer fundet.'}
        >
          {(consultation) => (
            <Row>
              <ConsultationTableRowContext value={{ consultation }}>
                <ScheduledDateCell />
                <CreatedAtCell />
                <EmployeeCell />
                <CopyLinksCell />
                <StartConsultationCell />
                <ActionsCell disabledActions={['view-all-consultations']} />
              </ConsultationTableRowContext>
            </Row>
          )}
        </TableBody>
      </Table>

      {showPagination ? (
        <Pagination
          totalItems={consultations.length}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          currentPage={currentPage}
        />
      ) : null}
    </div>
  )
}
