import React from 'react'

import { Dialog, DialogCloseButton } from '@fysioscout/ui/overlays/dialog'
import { Callout } from '@fysioscout/ui/status/callout'
import { Spinner } from '@fysioscout/ui/status/spinner'
import { Text } from '@fysioscout/ui/typography/text'

import { useCreateVideoConsultationContext } from '@/features/video-consultation/user-actions/create-video-consultation/context'
import {
  useEmployeeId,
  usePatientId,
} from '@/features/video-consultation/user-actions/create-video-consultation/store'

import { CreateVideoConsultationForm } from './create-video-consultation-form'

export function CreateVideoConsultationDialog() {
  const { employees, patients, isLoading, onCreate } = useCreateVideoConsultationContext()

  const employeeId = useEmployeeId() ?? undefined
  const patientId = usePatientId() ?? undefined

  const isEmptyData = employees.length === 0

  if (isLoading) {
    return (
      <Dialog
        className={'stack center text-subtle-foreground h-full gap-2'}
        aria-label={'Indlæser formular'}
      >
        <Spinner />
        <Text size={'2'}>Indlæser formular..</Text>
      </Dialog>
    )
  }

  if (isEmptyData) {
    return (
      <Dialog className={'stack center h-full'} aria-label={'Noget gik galt'}>
        <Callout type={'neutral'} size={'1'}>
          <Text>Noget gik galt. Prøv igen senere.</Text>
        </Callout>
      </Dialog>
    )
  }

  return (
    <Dialog
      data-testid={'create-video-consultation-dialog'}
      className={'stack h-full outline-none'}
    >
      {({ close }) => (
        <>
          <DialogCloseButton onPress={close} />

          <div className={'mt-8'}>
            <CreateVideoConsultationForm
              employees={employees}
              patients={patients}
              defaultPatientId={patientId}
              defaultEmployeeId={employeeId}
              onCreate={onCreate}
            />
          </div>
        </>
      )}
    </Dialog>
  )
}
