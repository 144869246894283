import { createFileRoute } from '@tanstack/react-router'
import { fallback, zodValidator } from '@tanstack/zod-adapter'
import { z } from 'zod'

import { clinicTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-treatment/queries'
import { clinicVideoConsultationsQueries } from '@/api/fysioscout/endpoints/clinic-video-consultations/queries'

import { PatientView } from './-patient-view'

const PatientRouteSearchSchema = z.object({
  tab: fallback(z.enum(['treatments', 'video-consultations']), 'treatments').default('treatments'),
})

export const Route = createFileRoute('/_auth/patients/$patientId/')({
  validateSearch: zodValidator(PatientRouteSearchSchema),
  component: PatientView,
  loader: async ({ context: { queryClient }, params: { patientId } }) => {
    const [treatments, scheduledConsultations, finishedConsultations] = await Promise.all([
      queryClient.ensureQueryData(clinicTreatmentQueries.treatments({ path: { patientId } })),
      queryClient.ensureQueryData(
        clinicVideoConsultationsQueries.byPatientId({
          path: { id: patientId },
          query: { status: 'scheduled' },
        }),
      ),
      queryClient.ensureQueryData(
        clinicVideoConsultationsQueries.byPatientId({
          path: { id: patientId },
          query: { status: 'finished' },
        }),
      ),
    ])

    return {
      treatments,
      scheduledConsultations,
      finishedConsultations,
    }
  },
})
