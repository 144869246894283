import React from 'react'

import type { PanelProps } from '@fysioscout/ui/layout/panel'

import { Panel } from '@fysioscout/ui/layout/panel'
import { cn } from '@fysioscout/ui/utils'

export function InformationPanel({ className, children, ...rest }: PanelProps) {
  return (
    <Panel
      {...rest}
      data-testid={'information-panel'}
      className={cn(
        'hstack 3xl:gap-28 w-full items-center justify-between gap-16 overflow-x-scroll whitespace-nowrap px-5 py-8 text-sm xl:overflow-x-visible',
        className,
      )}
    >
      {children}
    </Panel>
  )
}
