import { create, useStore } from 'zustand'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { useDetachPatientContext } from '@/features/patients/user-actions/detach-patient/context'

export interface DetachPatientStore {
  patientId: Schemas['ClinicPatientDto']['patient_details']['id'] | null
  patient: Schemas['ClinicPatientDto'] | null
  actions: {
    setPatientId: (patientId: Schemas['ClinicPatientDto']['patient_details']['id']) => void
    setPatient: (patient: Schemas['ClinicPatientDto']) => void
    reset: () => void
  }
}

type InitialState = Omit<DetachPatientStore, 'actions'>

const INITIAL_STATE = {
  patientId: null,
  patient: null,
} satisfies InitialState

export function createDetachPatientFromClinicStore(initialState: InitialState = INITIAL_STATE) {
  return create<DetachPatientStore>((set) => ({
    ...initialState,
    actions: {
      setPatientId: (patientId) => {
        set({ patientId })
      },
      setPatient: (patient) => {
        set({ patient })
      },
      reset: () => {
        set(INITIAL_STATE)
      },
    },
  }))
}

function useDetachPatientFromClinicStore<T>(selector: (state: DetachPatientStore) => T) {
  const { store } = useDetachPatientContext()

  return useStore(store, selector)
}

export function usePatientId() {
  return useDetachPatientFromClinicStore((state) => state.patientId)
}

export function usePatient() {
  return useDetachPatientFromClinicStore((state) => state.patient)
}

export function useDetachPatientFromClinicActions() {
  return useDetachPatientFromClinicStore((state) => state.actions)
}
