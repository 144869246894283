import React from 'react'

import { AlertDialog } from '@fysioscout/ui/overlays/alert-dialog'

import { useCancelConsultation } from '@/features/video-consultation/api/cancel-consultation'

import { useCancelConsultationActions, useConsultationId, useIsOpen } from '../store'

export function CancelConsultationDialog() {
  const actions = useCancelConsultationActions()
  const consultationId = useConsultationId()
  const isOpen = useIsOpen()
  const mutation = useCancelConsultation()

  const handleMutation = () => {
    if (!consultationId) return

    mutation.mutate(
      { path: { id: consultationId } },
      {
        onSuccess: () => {
          actions.reset()
        },
      },
    )
  }

  return (
    <AlertDialog
      title={'Aflys videokonsultation'}
      actionLabel={'Aflys videokonsultation'}
      onAction={handleMutation}
      isPending={mutation.isPending}
      isOpen={isOpen}
      onOpenChange={actions.onOpenChange}
      onCloseComplete={() => console.log('Closed')}
      destructive
    >
      Videokonsultationen vil blive aflyst og patienten vil modtage en notifikation på email.
    </AlertDialog>
  )
}
