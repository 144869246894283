import React from 'react'
import { Link } from '@tanstack/react-router'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { Label } from '@fysioscout/ui/forms/field'
import { Link as UILink } from '@fysioscout/ui/navigation/link'
import { Text } from '@fysioscout/ui/typography/text'

import { InformationPanel } from '@/components/information-panel'
import { PatientQuickMenu } from '@/features/patients/components/patient-quick-menu'
import { ToggleChat } from '@/features/patients/user-actions/toggle-chat/components/toggle-chat'
import { useIsVideoConsultationAvailable } from '@/features/video-consultation/hooks/use-is-video-consultation-available'
import { dayjs } from '@/lib/dayjs'

interface PatientTopBarProps {
  /** The patient data, including employee details. */
  patient: Schemas['ClinicPatientDto']

  /** Optional CSS class name for styling the TopBar. */
  className?: string
}

export function PatientTopBar({ patient, className }: PatientTopBarProps) {
  const createdLabelId = React.useId()
  const patientEmailLabelId = React.useId()
  const practitionerLabelId = React.useId()
  const chatLabelId = React.useId()
  const videoConsultationsLabelId = React.useId()

  const isVideoConsultationAvailable = useIsVideoConsultationAvailable()

  return (
    <InformationPanel className={className}>
      <div className={'stack gap-1'}>
        <Label id={patientEmailLabelId}>Email</Label>
        <Text aria-labelledby={patientEmailLabelId}>{patient.patient_details.email}</Text>
      </div>

      <div className={'stack gap-1'}>
        <Label id={practitionerLabelId}>Behandler</Label>

        <Text aria-labelledby={practitionerLabelId}>
          {patient.clinic_employee?.name ?? 'Ingen behandler'}
        </Text>
      </div>

      <div className={'stack gap-1'}>
        <Label id={createdLabelId}>Oprettet</Label>
        <Text aria-labelledby={createdLabelId}>{dayjs.format(patient.request_date)}</Text>
      </div>

      <div className={'stack gap-1'}>
        <Label id={chatLabelId}>
          {patient.is_pdf_patient ? 'Ikke tilgængelig' : 'Aktiver chat'}
        </Label>

        <ToggleChat
          aria-labelledby={chatLabelId}
          patient={patient}
          initialValue={patient.is_chat_enabled}
        />
      </div>

      {isVideoConsultationAvailable ? (
        <div className={'stack gap-1'}>
          <Label id={videoConsultationsLabelId}>Videokonsultationer</Label>

          <UILink aria-labelledby={videoConsultationsLabelId} intent={'forward'}>
            <Link
              to={'/video-consultation/patient/$patientId'}
              params={{ patientId: patient.patient_details.id }}
            >
              Gå til videokonsultationer
            </Link>
          </UILink>
        </div>
      ) : null}

      <PatientQuickMenu patient={patient} practitioner={patient.clinic_employee ?? null} />
    </InformationPanel>
  )
}
