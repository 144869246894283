import React from 'react'

import type { CreateVideoConsultationProviderProps } from '../user-actions/create-video-consultation/components/create-video-consultation-provider'

import { CancelConsultation } from '../user-actions/cancel-consultation/components/cancel-consultation'
import { CreateVideoConsultation } from '../user-actions/create-video-consultation/components/create-video-consultation'
import { EditVideoConsultation } from '../user-actions/edit-consultation/components/edit-video-consultation'
import { ResendConsultationEmail } from '../user-actions/resend-consultation-email/components/resend-consultation-email'

interface ConsultationActionsProviderProps
  extends Pick<CreateVideoConsultationProviderProps, 'onCreate'> {
  children?: React.ReactNode
}

export function ConsultationActionsProvider({
  children,
  onCreate,
}: ConsultationActionsProviderProps) {
  return (
    <EditVideoConsultation>
      <ResendConsultationEmail>
        <CancelConsultation>
          <CreateVideoConsultation onCreate={onCreate}>{children}</CreateVideoConsultation>
        </CancelConsultation>
      </ResendConsultationEmail>
    </EditVideoConsultation>
  )
}
