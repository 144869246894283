import { useClinicSubscription } from '@/hooks/use-clinic-subscription'

/**
 * Retrieves the consultation count statistics for the current user's subscription. This includes
 * the number of video consultations used and the maximum allowable video consultations.
 *
 * @returns An object containing the used and total consultation count.
 */
export function useConsultationCount() {
  const subscription = useClinicSubscription()

  const { current_video_consultation_usage: used, max_video_consultation_limit: total } =
    subscription.video_consultations

  return {
    used,
    total,
    isLimitReached: used >= total,
  }
}
