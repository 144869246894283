import { AlertDialog } from '@fysioscout/ui/overlays/alert-dialog'
import { toast } from '@fysioscout/ui/status/toast'
import { Text } from '@fysioscout/ui/typography/text'

import { useDeleteClinicPatient } from '@/features/patients/api/delete-clinic-patient'
import { useDetachPatientContext } from '@/features/patients/user-actions/detach-patient/context'
import {
  useDetachPatientFromClinicActions,
  usePatient,
  usePatientId,
} from '@/features/patients/user-actions/detach-patient/store'

export function DetachPatientDialog() {
  const actions = useDetachPatientFromClinicActions()
  const context = useDetachPatientContext()
  const mutation = useDeleteClinicPatient()
  const patient = usePatient()
  const patientId = usePatientId()

  const close = () => {
    actions.setPatientId('')
  }

  const handleMutation = () => {
    if (!patientId) return

    mutation.mutate(patientId, {
      onSuccess: () => {
        close()
        toast.success(
          `${patient?.patient_details.name ?? 'Patienten'}'s patientforløb er nu afsluttet.`,
        )

        setTimeout(() => {
          context.onSuccess?.()
        }, 300)
      },
    })
  }

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      close()
    }
  }

  return (
    <AlertDialog
      title={`Er du sikker på at du vil afslutte ${patient?.patient_details.name ?? 'patienten'}'s forløb?`}
      actionLabel={'Afslut'}
      onAction={handleMutation}
      isPending={mutation.isPending}
      isOpen={Boolean(patientId)}
      onOpenChange={handleOpenChange}
      destructive
    >
      <Text size={'2'} elementType={'p'}>
        Patienten kan stadigvæk selv tilgå sit forløb. <br />
        Du kan også altid invitere patienten til klinikken igen.
      </Text>
    </AlertDialog>
  )
}
