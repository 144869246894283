import { useSuspenseQuery } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'
import { useTitle } from 'ahooks'
import { EditIcon } from 'lucide-react'

import { Button } from '@fysioscout/ui/buttons/button'
import { LinkButton } from '@fysioscout/ui/buttons/link-button'
import { cn } from '@fysioscout/ui/utils'

import { clinicTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-treatment/queries'
import { Route } from '@/app/routes/_auth/patients/$patientId/treatments/$treatmentId/index'
import { PageHeading, PageLayout, PageSeparator } from '@/components/layouts/page-layout'
import { NotebookOverview } from '@/features/treatments/components/notebook/notebook-overview'
import { TreatmentOverview } from '@/features/treatments/components/treatment/treatment-overview'
import { TreatmentContext } from '@/features/treatments/contexts/treatment-context'
import { EditExercises } from '@/features/treatments/user-actions/edit-exercise/components/edit-exercises'
import { EditTreatmentNameProvider } from '@/features/treatments/user-actions/edit-treatment-name/components/edit-treatment-name-provider'
import { useEditTreatmentNameActions } from '@/features/treatments/user-actions/edit-treatment-name/store'

export function TreatmentView() {
  const navigate = Route.useNavigate()
  const search = Route.useSearch()
  const { patient } = Route.useRouteContext()
  const { patientId, treatmentId } = Route.useParams()

  const {
    data: { treatment },
  } = useSuspenseQuery(clinicTreatmentQueries.treatment({ path: { treatmentId, patientId } }))

  useTitle(`${treatment.name ?? ''} - ${patient.patient_details.name}`)

  return (
    <EditTreatmentNameProvider patientId={patientId} treatment={treatment}>
      <PageLayout>
        <div className={'hstack items-end justify-between'}>
          <div className={'hstack group -m-2 items-baseline gap-3 p-2'}>
            <PageHeading>{treatment.name ?? '–'}</PageHeading>
            <EditTreatmentNameButton />
          </div>

          <div className={'hstack gap-2'}>
            <LinkButton
              variant={'soft'}
              color={search.view === 'exercises' ? 'accent' : 'neutral'}
              size={'sm'}
            >
              <Link from={Route.fullPath} search={{ view: 'exercises' }}>
                Øvelser
              </Link>
            </LinkButton>

            <LinkButton
              variant={'soft'}
              color={search.view === 'notebook' ? 'accent' : 'neutral'}
              size={'sm'}
            >
              <Link from={Route.fullPath} search={{ view: 'notebook' }}>
                Notesbog
              </Link>
            </LinkButton>
          </div>
        </div>

        <PageSeparator />

        <TreatmentContext.Provider value={{ patientId, treatmentId }}>
          <EditExercises>
            {search.view === 'exercises' ? (
              <TreatmentOverview
                activeTab={search.tab}
                setActiveTab={(tab) => {
                  void navigate({ search: (prev) => ({ ...prev, tab }) })
                }}
              />
            ) : (
              <NotebookOverview />
            )}
          </EditExercises>
        </TreatmentContext.Provider>
      </PageLayout>
    </EditTreatmentNameProvider>
  )
}

function EditTreatmentNameButton() {
  const actions = useEditTreatmentNameActions()

  return (
    <Button
      color={'neutral'}
      variant={'ghost'}
      size={'md'}
      className={({ isFocused }) =>
        cn('opacity-0 group-hover:opacity-100', {
          'opacity-100 transition-none': isFocused,
        })
      }
      onPress={() => {
        actions.open()
      }}
    >
      <EditIcon />
    </Button>
  )
}
