import React from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'
import { useTitle } from 'ahooks'
import { PlusIcon } from 'lucide-react'

import { Button } from '@fysioscout/ui/buttons/button'
import { LinkButton } from '@fysioscout/ui/buttons/link-button'
import { SearchField } from '@fysioscout/ui/forms/search-field'

import { clinicPatientQueries } from '@/api/fysioscout/endpoints/clinic-patient/queries'
import { PageHeading, PageLayout, PageSeparator } from '@/components/layouts/page-layout'
import { AcceptedPatients } from '@/features/patients/components/accepted-patients'
import { PendingPatients } from '@/features/patients/components/pending-patients'
import { UnattachedPatients } from '@/features/patients/components/unattached-patients'
import { useCreatePatientActions } from '@/features/patients/user-actions/create-patient/store'
import { DetachPatientProvider } from '@/features/patients/user-actions/detach-patient/components/detach-patient-provider'

import { Route } from './'

export function PatientsView() {
  useTitle('Alle patienter')

  const [_, startTransition] = React.useTransition()
  const navigate = Route.useNavigate()
  const search = Route.useSearch()

  const { data: accepted } = useSuspenseQuery(clinicPatientQueries.accepted())
  const { data: pending } = useSuspenseQuery(clinicPatientQueries.pending())

  const createPatientActions = useCreatePatientActions()

  const handleSearch = (query: string) => {
    startTransition(() => {
      void navigate({
        to: '.',
        search: (prev) => ({ ...prev, query: query.trim() || undefined }),
      })
    })
  }

  const clearSearch = () => void navigate({ to: '.', search: (prev) => ({ ...prev, query: '' }) })

  const pendingPatientsCount = pending.patients.length

  return (
    <PageLayout>
      <div className={'hstack items-end justify-between'}>
        <PageHeading>Alle patienter</PageHeading>

        <div className={'hstack gap-2'}>
          <LinkButton
            variant={'soft'}
            color={search.status === 'accepted' ? 'accent' : 'neutral'}
            size={'sm'}
          >
            <Link
              from={Route.fullPath}
              to={'.'}
              search={(prev) => ({ ...prev, status: 'accepted' })}
            >
              Accepteret
            </Link>
          </LinkButton>

          <LinkButton
            variant={'soft'}
            color={search.status === 'pending' ? 'accent' : 'neutral'}
            size={'sm'}
          >
            <Link
              from={Route.fullPath}
              to={'.'}
              search={(prev) => ({ ...prev, status: 'pending' })}
            >
              Afventer {pendingPatientsCount ? `(${pendingPatientsCount})` : null}
            </Link>
          </LinkButton>
        </div>
      </div>

      <PageSeparator />

      <div className={'hstack items-end justify-between gap-4'}>
        <SearchField
          label={'Søg efter patient'}
          className={'min-w-72'}
          onChange={handleSearch}
          defaultValue={search.query}
          onClear={clearSearch}
          isDisabled={accepted.clinic_employees.length === 0}
        />

        <Button
          variant={'solid'}
          color={'accent'}
          iconEnd={<PlusIcon />}
          onPress={createPatientActions.open}
        >
          Opret patient
        </Button>
      </div>

      <DetachPatientProvider>
        <div className={'mt-8 space-y-3'}>
          {search.status === 'accepted' ? (
            <>
              {accepted.unattached_patients.length > 0 ? (
                <UnattachedPatients
                  clinic={accepted.clinic}
                  patients={accepted.unattached_patients}
                />
              ) : null}

              <AcceptedPatients list={accepted.clinic_employees} searchQuery={search.query} />
            </>
          ) : null}

          {search.status === 'pending' ? (
            <PendingPatients list={pending.patients} searchQuery={search.query} />
          ) : null}
        </div>
      </DetachPatientProvider>
    </PageLayout>
  )
}
