import React from 'react'

import type { ChangeEmailContextValue } from '../context'

import { ChangeEmailContext } from '../context'
import { createChangeEmailFromClinicStore } from '../store'
import { ChangePatientEmailDialog } from './change-patient-email-dialog'

interface ChangeEmailProviderProps extends Omit<ChangeEmailContextValue, 'store'> {
  children?: React.ReactNode
}

export function ChangePatientEmailProvider({ children, onSuccess }: ChangeEmailProviderProps) {
  const [store] = React.useState(() => createChangeEmailFromClinicStore())

  return (
    <ChangeEmailContext value={{ store, onSuccess }}>
      <ChangePatientEmailDialog />

      {children}
    </ChangeEmailContext>
  )
}
