import React from 'react'
import { useEventEmitter } from 'ahooks'

import { AddExercisesContext } from '../context'
import { createAddExercisesStore } from '../store'

export function AddExercisesProvider({ children }: { children?: React.ReactNode }) {
  const [store] = React.useState(() => createAddExercisesStore())
  const resetFilters$ = useEventEmitter()

  return <AddExercisesContext value={{ store, resetFilters$ }}>{children}</AddExercisesContext>
}
