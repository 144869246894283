import { useSuspenseQuery } from '@tanstack/react-query'

import { clinicQueries } from '@/api/fysioscout/endpoints/clinic/queries'

/**
 * Retrieves the clinic subscription.
 *
 * @returns The clinic subscription.
 */
export function useClinicSubscription() {
  const { data: subscription } = useSuspenseQuery(clinicQueries.getSubscription())

  return subscription
}
