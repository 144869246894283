import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { StoreApi } from 'zustand'
import type { EditConsultationStore } from './store'

import { createStrictContext } from '@fysioscout/utils/react'

export interface EditConsultationContextValue {
  store: StoreApi<EditConsultationStore>
  employees: Schemas['ClinicEmployeeDto'][]
  isLoading: boolean
}

export const [EditConsultationContext, useEditConsultationContext] =
  createStrictContext<EditConsultationContextValue>({
    name: 'EditConsultationContext',
    errorMessage: 'useEditConsultationContext must be used within a EditConsultationContext.',
    isDevelopment: import.meta.env.DEV,
    allowMissingProvider: false,
  })
