import React from 'react'
import { parseDate } from '@internationalized/date'
import { useQuery } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'
import { useTitle } from 'ahooks'
import Fuse from 'fuse.js'
import { HelpCircle, PlusIcon } from 'lucide-react'
import { DialogTrigger } from 'react-aria-components'
import { flatMap, pipe } from 'remeda'
import { P, match } from 'ts-pattern'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { DateValue } from '@internationalized/date'

import { INFO_MAIL, SERVICE_MAIL, SERVICE_PHONE } from '@fysioscout/env/information'
import { Button } from '@fysioscout/ui/buttons/button'
import { LinkButton } from '@fysioscout/ui/buttons/link-button'
import { DateRangePicker } from '@fysioscout/ui/date-and-time/date-range-picker'
import { SearchField } from '@fysioscout/ui/forms/search-field'
import { Container } from '@fysioscout/ui/layout/container'
import { Panel } from '@fysioscout/ui/layout/panel'
import { Link as UILink } from '@fysioscout/ui/navigation/link'
import { Dialog } from '@fysioscout/ui/overlays/dialog'
import { Popover } from '@fysioscout/ui/overlays/popover'
import { Heading } from '@fysioscout/ui/typography/heading'
import { Text } from '@fysioscout/ui/typography/text'

import { clinicVideoConsultationsQueries } from '@/api/fysioscout/endpoints/clinic-video-consultations/queries'
import { PageHeading, PageLayout, PageSeparator } from '@/components/layouts/page-layout'
import { ClinicFinishedVcTable } from '@/features/video-consultation/components/tables/clinic-finished-vc-table'
import { ClinicScheduledVcTable } from '@/features/video-consultation/components/tables/clinic-scheduled-vc-table'
import { VideoConsultationsByEmployee } from '@/features/video-consultation/components/video-consultations-by-employee'
import { useConsultationCount } from '@/features/video-consultation/hooks/use-consultation-count'
import { useIsSubscriptionEnabled } from '@/features/video-consultation/hooks/use-is-subscription-enabled'
import { useCreateVideoConsultationActions } from '@/features/video-consultation/user-actions/create-video-consultation/store'
import { dayjs } from '@/lib/dayjs'

import { Route } from './'

/** The minimum number of characters a search query must contain to be considered valid. */
const SEARCH_MIN_CHAR_LENGTH = 2

const keys = ['patient.name', 'patient.email'] satisfies `patient.${keyof Schemas['PatientDto']}`[]

export function VideoConsultationView() {
  useTitle('Videokonsultation')

  const isSubscribed = useIsSubscriptionEnabled()

  const search = Route.useSearch()

  const startDate = search.start ? dayjs.toUTCStartOfDay(search.start) : undefined
  const endDate = search.end ? dayjs.toUTCStartOfDay(search.end) : undefined

  const isQueryValid = search.query ? search.query.length >= SEARCH_MIN_CHAR_LENGTH : false
  const query = useQuery(
    clinicVideoConsultationsQueries.list({ query: { status: search.status, startDate, endDate } }),
  )

  const fuse = React.useMemo(() => {
    const list = pipe(
      query.data ?? [],
      flatMap(({ consultations }) => consultations),
    )

    return new Fuse(list, {
      keys,
      threshold: 0.3,
      minMatchCharLength: SEARCH_MIN_CHAR_LENGTH,
    })
  }, [query.data])

  if (!isSubscribed) {
    return <VideoConsultationDisabledNotice />
  }

  const results = isQueryValid
    ? fuse.search(search.query ?? '', { limit: 50 }).map((result) => result.item)
    : []

  const renderList = () => {
    if (isQueryValid && search.status === 'scheduled') {
      return (
        <Panel>
          <ClinicScheduledVcTable consultations={results} />
        </Panel>
      )
    }

    if (isQueryValid && search.status === 'finished') {
      return (
        <Panel>
          <ClinicFinishedVcTable consultations={results} />
        </Panel>
      )
    }

    return <VideoConsultationsByEmployee status={search.status} data={query.data ?? []} />
  }

  return (
    <PageLayout data-testid={'video-consultation-page'}>
      <div className={'stack gap-8'}>
        <Header />
        <PageSeparator className={'m-0'} />
        <Controls />
      </div>

      <div className={'mt-8'}>{renderList()}</div>
    </PageLayout>
  )
}

function VideoConsultationDisabledNotice() {
  return (
    <div data-testid={'video-consultation-disabled-notice'} className={'relative flex-1'}>
      <div className={'stack center absolute inset-0 z-20 flex-1 backdrop-blur-lg'}>
        <Container size={'sm'}>
          <div className={'mb-40 flex flex-col items-center gap-2 text-center'}>
            <Heading size={'6'}>Videokonsultationer er ikke aktiveret</Heading>
            <Text size={'2'} subtle>
              Skriv til os på{' '}
              <UILink>
                <a href={`mailto:${INFO_MAIL}`}>{INFO_MAIL}</a>
              </UILink>{' '}
              eller ring direkte på {SERVICE_PHONE} for at høre, hvordan du aktiverer
              videokonsultationer.
            </Text>
          </div>
        </Container>
      </div>

      {/*  Skeleton UI of VideoConsultationPage */}
      <PageLayout className={'opacity-40'}>
        <div className={'hstack items-end justify-between'}>
          <PageHeading>Videokonsultationer</PageHeading>

          <div className={'hstack gap-2'}>
            <div className={'bg-accent-6 h-9 w-20 rounded-md'} />
            <div className={'bg-neutral-6 h-9 w-20 rounded-md'} />
          </div>
        </div>

        <PageSeparator />

        <div className={'hstack items-end justify-between gap-4'}>
          <div className={'hstack gap-2'}>
            <div className={'bg-neutral-3 h-9 w-60 rounded-md'} />
            <div className={'bg-neutral-3 h-9 w-60 rounded-md'} />
          </div>

          <div className={'bg-accent h-9 w-60 rounded-md'} />
        </div>

        <PageSeparator />

        <div className={'mt-8 flex flex-col gap-6'}>
          <div className={'bg-neutral-3 h-20 w-full rounded-lg'} />
          <div className={'bg-neutral-3 h-20 w-full rounded-lg'} />
          <div className={'bg-neutral-3 h-20 w-full rounded-lg'} />
          <div className={'bg-neutral-3 h-20 w-full rounded-lg'} />
        </div>
      </PageLayout>
    </div>
  )
}

function Header() {
  const search = Route.useSearch()
  const subscription = useConsultationCount()

  const subscriptionNoticeId = React.useId()

  return (
    <div className={'hstack items-end justify-between'}>
      <div className={'stack'}>
        <PageHeading>Videokonsultationer</PageHeading>

        <div className={'hstack items-center gap-0.5'}>
          <Text id={subscriptionNoticeId} size={'1'} muted>
            Der er oprettet {subscription.used} ud af {subscription.total} videokonsultationer for
            måneden.
          </Text>

          <DialogTrigger>
            <Button
              aria-labelledby={subscriptionNoticeId}
              size={'sm'}
              color={'neutral'}
              variant={'ghost'}
            >
              <HelpCircle />
            </Button>

            <Popover className={'max-w-xs'}>
              <Dialog>
                <Heading slot={'title'} size={'2'}>
                  Klinikkens abonnement
                </Heading>

                <Text elementType={'p'} size={'1'} subtle className={'mt-1'}>
                  Klinikkens abonnement er begrænset til {subscription.total} videokonsultationer om
                  måneden.
                  <br />
                  Kontakt{' '}
                  <UILink>
                    <a href={`mailto:${SERVICE_MAIL}`}>{SERVICE_MAIL}</a>
                  </UILink>{' '}
                  eller ring på{' '}
                  <UILink>
                    <a href={`tel:${SERVICE_PHONE}`}>{SERVICE_PHONE}</a>
                  </UILink>{' '}
                  for at ændre abonnementet.
                </Text>
              </Dialog>
            </Popover>
          </DialogTrigger>
        </div>
      </div>

      <div className={'hstack gap-2'}>
        <LinkButton
          variant={'soft'}
          color={search.status === 'scheduled' ? 'accent' : 'neutral'}
          size={'sm'}
        >
          <Link from={Route.fullPath} to={'.'} search={{ status: 'scheduled' }}>
            Planlagte
          </Link>
        </LinkButton>

        <LinkButton
          variant={'soft'}
          color={search.status === 'finished' ? 'accent' : 'neutral'}
          size={'sm'}
        >
          <Link from={Route.fullPath} to={'.'} search={{ status: 'finished' }}>
            Afholdte
          </Link>
        </LinkButton>
      </div>
    </div>
  )
}

function Controls() {
  const actions = useCreateVideoConsultationActions()

  return (
    <div className={'hstack items-end justify-between gap-4'}>
      <div className={'hstack gap-2'}>
        <PatientSearchField />
        <DateRangeSelector />
      </div>

      <Button variant={'solid'} color={'accent'} iconEnd={<PlusIcon />} onPress={actions.open}>
        Opret videokonsultation
      </Button>
    </div>
  )
}

function PatientSearchField() {
  const [_, startTransition] = React.useTransition()
  const navigate = Route.useNavigate()
  const search = Route.useSearch()

  const handleSearch = (query: string) => {
    startTransition(() => {
      void navigate({
        to: '.',
        search: (prev) => ({ ...prev, query: query.trim() || undefined }),
      })
    })
  }

  const clearSearch = () => void navigate({ to: '.', search: (prev) => ({ ...prev, query: '' }) })

  return (
    <SearchField
      label={'Søg efter patient'}
      className={'min-w-72'}
      onChange={handleSearch}
      defaultValue={search.query}
      onClear={clearSearch}
    />
  )
}

function DateRangeSelector() {
  const navigate = Route.useNavigate()
  const search = Route.useSearch()

  const handlePeriodChange = (params: { start: DateValue; end: DateValue } | null): void => {
    void navigate({
      to: '.',
      search: (prev) => ({
        ...prev,
        start: params?.start ? params.start.toString() : undefined,
        end: params?.end ? params.end.toString() : undefined,
      }),
    })
  }

  const defaultValue = React.useMemo(() => {
    return match(search)
      .with({ start: P.string, end: P.string }, ({ start, end }) => ({
        start: parseDate(start),
        end: parseDate(end),
      }))
      .otherwise(() => null)
  }, [search])

  const direction = search.status === 'scheduled' ? 'future' : 'past'

  return (
    <DateRangePicker
      key={search.status}
      label={'Periode'}
      onChange={handlePeriodChange}
      defaultValue={defaultValue}
      presetRanges={['24h', '7d', '30d', '90d', '6m']}
      direction={direction}
    />
  )
}
