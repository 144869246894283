import React from 'react'
import { UserRoundIcon, VideoOffIcon } from 'lucide-react'
import { useSessionStorage } from 'usehooks-ts'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import {
  CollapsiblePanel,
  CollapsiblePanelContent,
  CollapsiblePanelTrigger,
} from '@fysioscout/ui/layout/collapsible-panel'
import { Panel } from '@fysioscout/ui/layout/panel'
import { Badge } from '@fysioscout/ui/status/badge'
import { Text } from '@fysioscout/ui/typography/text'

import { ClinicFinishedVcTable } from '@/features/video-consultation/components/tables/clinic-finished-vc-table'

import { ClinicScheduledVcTable } from './tables/clinic-scheduled-vc-table'

interface VideoConsultationsByEmployeeProps {
  data: Schemas['EmployeeConsultationsResponseDto'][]

  /** The status of consultations */
  status: 'scheduled' | 'finished'

  /** Optional CSS class name for the VideoConsultationsByEmployee. */
  className?: string
}

export function VideoConsultationsByEmployee(props: VideoConsultationsByEmployeeProps) {
  const [openPanels, setOpenPanels] = useSessionStorage<string[]>(
    'fs:video-consultations:panels:open',
    [],
  )

  return (
    <div data-testid={'video-consultations-by-employee'} className={props.className}>
      <div aria-label={'Videokonsultationer'} className={'stack gap-3'}>
        {props.data.length === 0 ? (
          <Panel isEmpty>
            <VideoOffIcon />
            <Text>Ingen videokonsultationer fundet.</Text>
          </Panel>
        ) : (
          props.data.map(({ employee, consultations }) => (
            <CollapsiblePanel
              key={employee.id}
              isDisabled={consultations.length === 0}
              isOpen={consultations.length > 0 ? openPanels.includes(employee.id) : undefined}
              onOpenChange={(isOpen) => {
                setOpenPanels((openPanels) => {
                  if (isOpen) {
                    return [...openPanels, employee.id]
                  }

                  return openPanels.filter((id) => id !== employee.id)
                })
              }}
            >
              {({ isOpen }) => (
                <VideoConsultationPanel
                  status={props.status}
                  employee={employee}
                  consultations={consultations}
                  isOpen={isOpen}
                />
              )}
            </CollapsiblePanel>
          ))
        )}
      </div>
    </div>
  )
}

interface VideoConsultationPanelProps {
  employee: Schemas['EmployeeConsultationsResponseDto']['employee']
  consultations: Schemas['EmployeeConsultationsResponseDto']['consultations']
  status: 'scheduled' | 'finished'
  isOpen: boolean
}

function VideoConsultationPanel({
  employee,
  consultations,
  status,
  isOpen,
}: VideoConsultationPanelProps) {
  const [scheduledPageNumber, setScheduledPageNumber] = useSessionStorage(
    `fs:video-consultations:${employee.id}:scheduled:page`,
    1,
  )
  const [finishedPageNumber, setFinishedPageNumber] = useSessionStorage(
    `fs:video-consultations:${employee.id}:finished:page`,
    1,
  )

  const renderList = () => {
    if (status === 'scheduled') {
      return (
        <ClinicScheduledVcTable
          key={`scheduled-${employee.id}`}
          consultations={consultations}
          aria-label={`${employee.name}'s planlagte videokonsultationer`}
          itemsPerPage={8}
          onPageChange={setScheduledPageNumber}
          pageNumber={scheduledPageNumber}
        />
      )
    }

    return (
      <ClinicFinishedVcTable
        key={`finished-${employee.id}`}
        consultations={consultations}
        aria-label={`${employee.name}'s afholdte videokonsultationer`}
        itemsPerPage={8}
        onPageChange={setFinishedPageNumber}
        pageNumber={finishedPageNumber}
      />
    )
  }

  return (
    <>
      <CollapsiblePanelTrigger>
        <div className={'hstack center gap-3'}>
          <UserRoundIcon className={'size-5'} />
          <Text size={'2'} slot={'description'} medium>
            {employee.name}
          </Text>

          <Badge variant={isOpen ? 'accent' : 'neutral'}>
            {consultations.length}{' '}
            {consultations.length === 1 ? 'videokonsultation' : 'videokonsultationer'}
          </Badge>
        </div>
      </CollapsiblePanelTrigger>

      <CollapsiblePanelContent>{renderList()}</CollapsiblePanelContent>
    </>
  )
}
