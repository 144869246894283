import React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { Link, Outlet, createLink, useMatches } from '@tanstack/react-router'
import { useKeyPress } from 'ahooks'
import {
  BookIcon,
  HomeIcon,
  LayoutListIcon,
  MessageSquareMoreIcon,
  PanelLeft,
  UsersIcon,
  VideoIcon,
} from 'lucide-react'
import { mergeProps, useFocusRing, useHover, useLink, useObjectRef } from 'react-aria'
import { Button as RACButton } from 'react-aria-components'
import { uniqueBy } from 'remeda'
import { endsWith, slice } from 'string-ts'
import { tv } from 'tailwind-variants'

import type { LinkComponent } from '@tanstack/react-router'
import type { AriaLinkOptions } from 'react-aria'

import { useEnableVideoConsultationPage } from '@fysioscout/hypertune/flags'
import { Button } from '@fysioscout/ui/buttons/button'
import { FocusRing } from '@fysioscout/ui/misc/focus-ring'
import { LogoMark } from '@fysioscout/ui/misc/logo-mark'
import { Separator } from '@fysioscout/ui/misc/separator'
import { Breadcrumb, Breadcrumbs } from '@fysioscout/ui/navigation/breadcrumbs'
import { Tooltip, TooltipTrigger } from '@fysioscout/ui/overlays/tooltip'
import { Text } from '@fysioscout/ui/typography/text'
import { cn, focusRing } from '@fysioscout/ui/utils'
import { ThemeSelector } from '@fysioscout/widgets/theme'

import { env } from '@/config/env'
import { QuickSettingsMenu } from '@/features/general/components/quick-settings-menu'
import { useGlobalUiActions, useIsSidebarOpen } from '@/stores/global-ui-store'

const SIDEBAR_WIDTH = '16rem'

export function AuthLayout() {
  const globalUiActions = useGlobalUiActions()
  const isOpen = useIsSidebarOpen()

  useKeyPress(
    (event) => event.code === 'BracketLeft',
    () => {
      globalUiActions.toggleSidebar()
    },
  )

  return (
    <div
      data-testid={'auth-layout'}
      className={'group/sidebar flex size-full min-h-full flex-row items-stretch text-clip'}
      style={{ '--sidebar-width': SIDEBAR_WIDTH } as React.CSSProperties}
      data-state={isOpen ? 'expanded' : 'collapsed'}
    >
      <Sidebar />
      <MainContent />
    </div>
  )
}

function SidebarLineToggle() {
  const actions = useGlobalUiActions()

  return (
    <RACButton
      onPress={actions.toggleSidebar}
      className={cn(
        'absolute inset-y-0 left-0 z-30 w-2 cursor-w-resize outline-none',
        'hover:after:bg-neutral-8 after:absolute after:inset-y-0 after:left-0 after:w-0.5 after:transition after:duration-300',
        'group-data-[state="collapsed"]/sidebar:cursor-e-resize',
      )}
    />
  )
}

function MainContent() {
  return (
    <main
      className={
        'border-border-subtle bg-main-background relative m-2 flex flex-1 flex-col overflow-hidden rounded-lg border shadow-sm 2xl:m-3'
      }
    >
      <SidebarLineToggle />

      <div className={'flex h-full flex-col place-items-stretch overflow-auto'}>
        <MockingIndicator />
        <TopNavigation />
        <Outlet />
      </div>
    </main>
  )
}

function Sidebar() {
  const isVideoConsultationPageEnabled = useEnableVideoConsultationPage()

  return (
    <div
      className={cn(
        'h-full w-[--sidebar-width] will-change-[width] contain-strict',
        'group-data-[state="collapsed"]/sidebar:w-0',
        'motion-safe:transition-[width] motion-safe:duration-200',
      )}
    >
      <div
        className={cn(
          'fixed inset-y-0 left-0 z-10 h-svh w-[--sidebar-width] will-change-[left]',
          'group-data-[state="collapsed"]/sidebar:left-[calc(var(--sidebar-width)*-1)]',
          'motion-safe:transition-[left] motion-safe:duration-200',
        )}
      >
        <nav className={'flex h-full min-h-0 flex-col'}>
          <div className={'flex p-6'}>
            <FocusRing>
              <Link
                to={'/'}
                resetScroll
                className={
                  '-m-1 flex items-center gap-3 self-start p-1 text-sm font-medium outline-none'
                }
              >
                <LogoMark className={'fill-accent-10 flex size-8 items-center'} />
                <Text size={'2'} className={'tracking-wide'}>
                  FysioScout
                </Text>
              </Link>
            </FocusRing>
          </div>

          <div className={'flex flex-1 flex-col overflow-y-auto px-4 py-6 pr-2'}>
            <div className={'flex flex-col gap-0.5'}>
              <RouterNavLink to={'/'} icon={<HomeIcon />}>
                Hjem
              </RouterNavLink>

              <RouterNavLink to={'/patients'} search={{ status: 'accepted' }} icon={<UsersIcon />}>
                Patienter
              </RouterNavLink>

              {isVideoConsultationPageEnabled ? (
                <RouterNavLink
                  to={'/video-consultation'}
                  search={{ status: 'scheduled' }}
                  icon={<VideoIcon />}
                >
                  Videokonsultation
                </RouterNavLink>
              ) : null}
            </div>

            <div className={'mt-8 flex flex-col gap-0.5'}>
              <Text size={'1'} medium className={'text-neutral-10 mb-2 px-3'}>
                Kommer snart
              </Text>

              {isVideoConsultationPageEnabled ? null : (
                <RouterNavLink
                  icon={<VideoIcon />}
                  to={'/video-consultation'}
                  search={{ status: 'scheduled' }}
                  isDisabled
                >
                  Videokonsultation
                </RouterNavLink>
              )}

              <RouterNavLink to={'/practitioners'} isDisabled icon={<LayoutListIcon />}>
                Behandlere
              </RouterNavLink>

              <RouterNavLink to={'/templates'} isDisabled icon={<BookIcon />}>
                Skabeloner
              </RouterNavLink>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

function MockingIndicator() {
  if (!env.VITE_API_MOCKING) return null

  return (
    <div className={'stack center bg-yellow-5 h-6 shrink-0 rounded-t-lg px-2'}>
      <Text size={'0'} className={'leading-none'} medium>
        Mocking active
      </Text>
    </div>
  )
}

function TopNavigation() {
  const actions = useGlobalUiActions()
  const matches = useMatches()

  const isSidebarOpen = useIsSidebarOpen()

  const breadcrumbs = React.useMemo(() => {
    const crumbs = matches.map((match) => {
      const pathname = match.pathname
      const title = match.meta?.at(0)?.title

      return { title, pathname }
    })

    const uniquePathname = uniqueBy(crumbs, (crumb) => {
      if (endsWith(crumb.pathname, '/')) {
        return slice(crumb.pathname, 0, -1)
      }

      return crumb.pathname
    })

    const filtered = uniquePathname.filter((crumb) => crumb.title)

    return [{ title: 'Hjem', pathname: '/' }, ...filtered] as const
  }, [matches])

  return (
    <div
      className={
        'border-border-subtle bg-main-background sticky top-0 z-20 flex max-h-12 items-center justify-between border-b px-5 py-3'
      }
    >
      <div className={'hstack w-full items-center gap-4'}>
        <TooltipTrigger>
          <Button
            size={'sm'}
            variant={'ghost'}
            color={'neutral'}
            onPress={actions.toggleSidebar}
            aria-label={`${isSidebarOpen ? 'Luk' : 'Åbn'} sidepanel`}
          >
            <PanelLeft />
          </Button>

          <Tooltip>
            <div className={'stack space-y-1'}>
              <Text size={'1'}>
                {isSidebarOpen ? 'Klik for at skjule' : 'Klik for at vise'}
                <span
                  className={
                    'bg-neutral-6 text-2xs ml-2 inline-flex size-4 items-center justify-center rounded'
                  }
                >
                  [
                </span>
              </Text>
            </div>
          </Tooltip>
        </TooltipTrigger>

        <Separator orientation={'vertical'} className={'h-4'} />

        <div className={'hstack items-center'}>
          <Breadcrumbs items={breadcrumbs}>
            {(item) => (
              <Breadcrumb id={item.pathname} className={'last-of-type:pointer-events-none'}>
                <Link
                  to={item.pathname}
                  className={'hover:text-foreground leading-none transition-colors duration-75'}
                >
                  <Text size={'1'}>{item.title}</Text>
                </Link>
              </Breadcrumb>
            )}
          </Breadcrumbs>
        </div>

        <div className={'hstack ml-auto items-center gap-8'}>
          <div className={'hstack items-center gap-1.5'}>
            <TooltipTrigger>
              <Button
                size={'xs'}
                variant={'transparent'}
                iconStart={<MessageSquareMoreIcon />}
                isVisuallyDisabled
              >
                Support
              </Button>

              <Tooltip>
                Support er midlertidigt kun <br /> tilgængelig i appen.
              </Tooltip>
            </TooltipTrigger>

            <TooltipTrigger>
              <Button
                size={'xs'}
                variant={'transparent'}
                iconStart={<MessageSquareMoreIcon />}
                isVisuallyDisabled
              >
                Chat
              </Button>

              <Tooltip>
                Chat er midlertidigt kun <br /> tilgængelig i appen.
              </Tooltip>
            </TooltipTrigger>

            {/*<LinkButton size={'xs'} variant={'ghost'} color={'neutral'} iconStart={<CogIcon />}>*/}
            {/*  <Link to={'/settings'}>Indstillinger</Link>*/}
            {/*</LinkButton>*/}
          </div>

          <div className={'hstack items-center gap-1'}>
            <ThemeSelector />
            <QuickSettingsMenu />
          </div>
        </div>
      </div>
    </div>
  )
}

const navLinkStyles = tv({
  extend: focusRing,
  base: 'text-foreground data-[status="active"]:bg-neutral-4 group/item relative flex items-center gap-3 rounded-lg px-3 py-2.5 text-sm font-medium transition-colors duration-75 focus-visible:z-10',
  variants: {
    isDisabled: {
      true: 'cursor-default py-2 text-[0.8125rem] opacity-40',
    },
    isHovered: {
      true: 'bg-neutral-3',
    },
    isPressed: {
      true: 'bg-neutral-4 data-[status="active"]:bg-neutral-5',
    },
  },
})

interface NavLinkProps extends Omit<AriaLinkOptions, 'href'> {
  children?: React.ReactNode
  icon: React.ReactNode
}

const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ icon, isDisabled, children, ...rest }, forwardedRef) => {
    const ref = useObjectRef(forwardedRef)

    const { isHovered, hoverProps } = useHover({ isDisabled, ...rest })
    const { isPressed, linkProps } = useLink({ isDisabled, ...rest }, ref)
    const { isFocusVisible, isFocused, focusProps } = useFocusRing(rest)

    return (
      <a
        {...mergeProps(hoverProps, linkProps, focusProps, rest)}
        ref={ref}
        data-hovered={isHovered || undefined}
        data-pressed={isPressed || undefined}
        data-focus-visible={isFocusVisible || undefined}
        data-focused={isFocused || undefined}
        className={navLinkStyles({ isDisabled, isHovered, isPressed, isFocusVisible })}
      >
        <Slot
          slot={'icon'}
          className={cn(
            'text-subtle-foreground size-5 transition-colors duration-75',
            'group-data-[status=active]/item:text-foreground',
            {
              'group-hover/item:text-foreground': !isDisabled,
            },
          )}
        >
          {icon}
        </Slot>

        {children}
      </a>
    )
  },
)

NavLink.displayName = 'NavLink'

const CreatedNavLink = createLink(NavLink)

export const RouterNavLink: LinkComponent<typeof NavLink> = (props) => (
  <CreatedNavLink activeOptions={{ includeSearch: false }} resetScroll {...props} />
)
