import React, { Suspense } from 'react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {
  Outlet,
  ScrollRestoration,
  createRootRouteWithContext,
  useRouter,
} from '@tanstack/react-router'
import { MotionConfig } from 'framer-motion'
import { RouterProvider, useLocale } from 'react-aria-components'

import type { useHypertune } from '@fysioscout/hypertune/hypertune.react'
import type { QueryClient } from '@tanstack/react-query'
import type { RegisteredRouter, ToOptions } from '@tanstack/react-router'

import { Toaster } from '@fysioscout/ui/status/toast'
import { useThemeContext } from '@fysioscout/widgets/theme'

import { env } from '@/config/env'
import { useTransitionBlockerStyle } from '@/hooks/use-transition-blocker-style'

export interface AppRouterContext {
  /** Query client used for the application. */
  queryClient: QueryClient

  /** Feature flags used for conditional rendering. */
  hypertune: ReturnType<typeof useHypertune>
}

export const Route = createRootRouteWithContext<AppRouterContext>()({
  component: RootLayout,
})

function RootLayout() {
  const router = useRouter()
  const { locale, direction } = useLocale()
  const { theme } = useThemeContext()

  React.useEffect(() => {
    document.documentElement.lang = locale
    document.documentElement.dir = direction
  }, [direction, locale])

  return (
    <div
      data-testid={'root-view'}
      lang={locale}
      dir={direction}
      className={'flex size-full min-h-full flex-row items-stretch'}
    >
      <ScrollRestoration />

      {env.VITE_DISABLE_ANIMATIONS ? <TransitionBlocker /> : null}

      <Toaster
        theme={theme}
        toastOptions={{ classNames: { toast: 'gap-3' } }}
        position={'bottom-center'}
      />

      <RouterProvider
        navigate={(to, options) => router.navigate({ to, ...options })}
        useHref={(to) => buildHref(to, router)}
      >
        <MotionConfig reducedMotion={'user'}>
          <Outlet />
        </MotionConfig>
      </RouterProvider>

      <Suspense fallback={null}>
        <TanStackRouterDevtools />
      </Suspense>

      <ReactQueryDevtools initialIsOpen={false} />
    </div>
  )
}

function TransitionBlocker() {
  useTransitionBlockerStyle()

  return null
}

const LazyTanStackRouterDevtools = React.lazy(() =>
  import('@tanstack/router-devtools').then((res) => ({
    default: res.TanStackRouterDevtools,
  })),
)

function TanStackRouterDevtools() {
  if (import.meta.env.PROD) {
    return null
  }

  return <LazyTanStackRouterDevtools />
}

const ABSOLUTE_URL_PREFIXES = ['https://', 'http://', 'mailto:'] as const

function buildHref(to: ToOptions['to'] | (string & {}), router: RegisteredRouter): string {
  if (!to) return ''

  if (ABSOLUTE_URL_PREFIXES.some((prefix) => to.startsWith(prefix))) {
    return to
  }

  return router.buildLocation({ to }).href
}
