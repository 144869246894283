// countries.ts
export interface Country {
  /** English name of the country */
  name: string

  /** Name of the country in its native language */
  nativeName: string

  /** Name of the primary language in its native form */
  languageName: string

  /** Two-letter country code (ISO 3166-1 alpha-2) */
  code: 'DK' | 'SE' | 'NO' | 'GB'

  /** Two-letter language code (ISO 639-1) */
  languageCode: 'da' | 'en' | 'nb' | 'sv'

  /** International calling code with + prefix */
  phoneCode: string

  /** Unicode flag emoji representation */
  flag: string

  /** Country's currency information */
  currency: {
    /** Three-letter currency code (ISO 4217) */
    code: string

    /** Currency symbol */
    symbol: string
  }
}

export const COUNTRIES = [
  {
    name: 'Denmark',
    nativeName: 'Danmark',
    languageName: 'Dansk',
    code: 'DK',
    languageCode: 'da',
    phoneCode: '+45',
    flag: '🇩🇰',
    currency: {
      code: 'DKK',
      symbol: 'kr.',
    },
  },
  {
    name: 'Sweden',
    nativeName: 'Sverige',
    languageName: 'Svenska',
    code: 'SE',
    languageCode: 'sv',
    phoneCode: '+46',
    flag: '🇸🇪',
    currency: {
      code: 'SEK',
      symbol: 'kr',
    },
  },
  {
    name: 'Norway',
    nativeName: 'Norge',
    languageName: 'Norsk',
    code: 'NO',
    languageCode: 'nb',
    phoneCode: '+47',
    flag: '🇳🇴',
    currency: {
      code: 'NOK',
      symbol: 'kr',
    },
  },
  {
    name: 'United Kingdom',
    nativeName: 'United Kingdom',
    languageName: 'English',
    code: 'GB',
    languageCode: 'en',
    phoneCode: '+44',
    flag: '🇬🇧',
    currency: {
      code: 'GBP',
      symbol: '£',
    },
  },
] as const satisfies readonly Country[]
