import React from 'react'
import { useNavigate, useRouter } from '@tanstack/react-router'
import { BookUserIcon, MailQuestionIcon, UserMinusIcon, UserRoundCogIcon } from 'lucide-react'
import { MenuTrigger } from 'react-aria-components'
import { doNothing } from 'remeda'
import { match } from 'ts-pattern'

import type { PatientActionRecord } from '@/features/patients/contexts/patient-table-context'
import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { Key } from 'react-aria-components'

import { Menu, MenuItem } from '@fysioscout/ui/collections/menu'
import { TableActionsTrigger } from '@fysioscout/ui/collections/table'
import { useHoverTimer } from '@fysioscout/utils/react'

import { usePatientTableContext } from '@/features/patients/contexts/patient-table-context'
import { useChangePatientEmailActions } from '@/features/patients/user-actions/change-email/store'
import { useChangePractitionerActions } from '@/features/patients/user-actions/change-practitioner/store'
import { useDetachPatientFromClinicActions } from '@/features/patients/user-actions/detach-patient/store'

const actions = {
  view: 'view',
  changePractitioner: 'change-practitioner',
  changeEmail: 'change-email',
  detach: 'detach',
} as const satisfies PatientActionRecord

interface PatientTableActionsProps {
  patient: Schemas['ClinicPatientDto']
  practitioner: Schemas['ClinicEmployeeDto'] | null
}

export function PatientTableActions({ patient, practitioner }: PatientTableActionsProps) {
  const { enabledActions } = usePatientTableContext()

  const changePatientEmailActions = useChangePatientEmailActions()
  const changePractitionerActions = useChangePractitionerActions()
  const detachPatientFromClinicActions = useDetachPatientFromClinicActions()

  const navigate = useNavigate()
  const router = useRouter()

  const handleChangePractitioner = () => {
    setTimeout(() => {
      changePractitionerActions.load({ patient, practitioner })
      changePractitionerActions.open()
    }, 100)
  }

  const handleDetachPatientFromClinic = () => {
    setTimeout(() => {
      detachPatientFromClinicActions.setPatientId(patient.patient_details.id)
      detachPatientFromClinicActions.setPatient(patient)
    }, 100)
  }

  const handleChangePatientEmail = () => {
    setTimeout(() => {
      changePatientEmailActions.setPatientId(patient.patient_details.id)
      changePatientEmailActions.setPatient(patient)
    }, 100)
  }

  const handleAction = (key: Key) => {
    match(key)
      .with(actions.changePractitioner, handleChangePractitioner)
      .with(actions.detach, handleDetachPatientFromClinic)
      .with(actions.changeEmail, handleChangePatientEmail)
      .with(actions.view, () => {
        void navigate({
          to: '/patients/$patientId',
          params: { patientId: patient.patient_details.id },
        })
      })
      .otherwise(() => doNothing())
  }

  const preloadPatientDetailsHover = useHoverTimer(() => {
    void router.preloadRoute({
      to: '/patients/$patientId',
      params: { patientId: patient.patient_details.id },
    })
  })

  return (
    <MenuTrigger>
      <TableActionsTrigger />

      <Menu
        selectionMode={'none'}
        disallowEmptySelection
        onAction={handleAction}
        placement={'bottom end'}
      >
        {enabledActions.includes('view') || enabledActions === 'all' ? (
          <MenuItem
            id={actions.view}
            icon={<BookUserIcon />}
            isLink
            onHoverStart={preloadPatientDetailsHover.start}
            onHoverEnd={preloadPatientDetailsHover.end}
          >
            Patientdetaljer
          </MenuItem>
        ) : null}

        {enabledActions.includes('change-practitioner') || enabledActions === 'all' ? (
          <MenuItem id={actions.changePractitioner} icon={<UserRoundCogIcon />}>
            {practitioner ? 'Skift' : 'Tilknyt'} behandler
          </MenuItem>
        ) : null}

        {enabledActions.includes('change-email') || enabledActions === 'all' ? (
          <MenuItem id={actions.changeEmail} icon={<MailQuestionIcon />}>
            Skift email
          </MenuItem>
        ) : null}

        {enabledActions.includes('detach') || enabledActions === 'all' ? (
          <MenuItem id={actions.detach} icon={<UserMinusIcon />}>
            Afslut patientforløb
          </MenuItem>
        ) : null}
      </Menu>
    </MenuTrigger>
  )
}
