'use client'

import React from 'react'
import { ArrowLeftIcon, ArrowRightIcon, ChevronUp, EllipsisIcon } from 'lucide-react'
import {
  Button as RACButton,
  Cell as RACCell,
  Collection as RACCollection,
  Column as RACColumn,
  ColumnResizer as RACColumnResizer,
  Group as RACGroup,
  ResizableTableContainer as RACResizableTableContainer,
  Row as RACRow,
  Table as RACTable,
  TableBody as RACTableBody,
  TableHeader as RACTableHeader,
  composeRenderProps,
  useTableOptions,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type {
  CellProps as RACCellProps,
  ColumnProps as RACColumnProps,
  ResizableTableContainerProps as RACResizableTableContainerProps,
  RowProps as RACRowProps,
  TableBodyProps as RACTableBodyProps,
  TableHeaderProps as RACTableHeaderProps,
  TableProps as RACTableProps,
} from 'react-aria-components'
import type { ButtonProps } from '../../buttons/button/button'

import { usePagination } from '@fysioscout/utils/react'

import { Button } from '../../buttons/button/button'
import { Checkbox } from '../../forms/checkbox/checkbox'
import { Text } from '../../typography/text/text'
import { cn, composeTailwindRenderProps, focusRing } from '../../utils'

export interface TableProps
  extends RACTableProps,
    Omit<RACResizableTableContainerProps, 'className' | 'onScroll' | 'style'> {}

export interface PaginatedTableProps extends TableProps {
  /** The page number to display. */
  pageNumber?: number

  /** The function to call when the page changes. */
  onPageChange?: (page: number) => void

  /** The number of items to display per page. */
  itemsPerPage?: number
}

export function Table({ className, onResize, onResizeEnd, onResizeStart, ...rest }: TableProps) {
  return (
    <RACResizableTableContainer
      className={cn('relative scroll-pt-[2.281rem] overflow-auto', className)}
      onResize={onResize}
      onResizeEnd={onResizeEnd}
      onResizeStart={onResizeStart}
    >
      <RACTable {...rest} className={'border-collapse border-spacing-0'} />
    </RACResizableTableContainer>
  )
}

const columnStyles = tv({
  extend: focusRing,
  base: 'data-[allows-sorting]:hover:text-foreground flex flex-1 items-center gap-1 overflow-hidden px-4 py-1 transition-colors duration-100',
})

const resizerStyles = tv({
  extend: focusRing,
  base: 'resizing:bg-accent-8 resizing:w-[2px] resizing:pl-[7px] bg-surface-border hover:bg-surface-border-hover box-content h-5 w-px translate-x-[8px] cursor-col-resize rounded bg-clip-content px-[8px] py-1 -outline-offset-2',
})

export function Column(props: RACColumnProps) {
  return (
    <RACColumn
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        'text-subtle-foreground cursor-default py-1 text-start text-sm font-medium transition-colors duration-100',
      )}
    >
      {composeRenderProps(props.children, (children, { allowsSorting, sortDirection }) => (
        <div className={'flex items-center'}>
          <RACGroup
            role={'presentation'}
            data-allows-sorting={allowsSorting || undefined}
            tabIndex={-1}
            className={columnStyles}
          >
            <span className={'truncate'}>{children}</span>

            {allowsSorting ? (
              <span
                className={cn('flex items-center justify-center transition-transform', {
                  'rotate-180': sortDirection === 'descending',
                })}
              >
                {sortDirection ? <ChevronUp aria-hidden className={'size-4 text-current'} /> : null}
              </span>
            ) : null}
          </RACGroup>

          {props.width ? null : <RACColumnResizer className={resizerStyles} />}
        </div>
      ))}
    </RACColumn>
  )
}

export function TableBody<T extends object>(props: RACTableBodyProps<T>) {
  return (
    <RACTableBody
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        'empty:text-subtle-foreground empty:h-48 empty:text-center empty:text-sm empty:italic',
      )}
    />
  )
}

export function TableHeader<T extends object>(props: RACTableHeaderProps<T>) {
  const { selectionBehavior, selectionMode, allowsDragging } = useTableOptions()

  return (
    <RACTableHeader
      {...props}
      className={cn('border-surface-border sticky top-0 z-10 border-b', props.className)}
    >
      {/* Add extra columns for drag and drop and selection. */}
      {allowsDragging ? <Column /> : null}

      {selectionBehavior === 'toggle' ? (
        <RACColumn
          width={50}
          minWidth={50}
          className={'cursor-default px-4 text-start text-sm font-semibold'}
        >
          {selectionMode === 'multiple' ? <Checkbox slot={'selection'} /> : null}
        </RACColumn>
      ) : null}

      <RACCollection items={props.columns}>{props.children}</RACCollection>
    </RACTableHeader>
  )
}

const rowStyles = tv({
  extend: focusRing,
  base: 'group/row selected:bg-neutral-3 selected:hover:bg-neutral-4 text-foreground hover:bg-neutral-3 disabled:text-disabled-foreground relative cursor-default text-sm -outline-offset-2',
})

export function Row<T extends object>({ id, columns, children, ...otherProps }: RACRowProps<T>) {
  const { selectionBehavior, allowsDragging } = useTableOptions()

  return (
    <RACRow id={id} {...otherProps} className={rowStyles}>
      {allowsDragging ? (
        <Cell>
          <RACButton slot={'drag'}>≡</RACButton>
        </Cell>
      ) : null}

      {selectionBehavior === 'toggle' ? (
        <Cell>
          <Checkbox slot={'selection'} />
        </Cell>
      ) : null}

      <RACCollection items={columns}>{children}</RACCollection>
    </RACRow>
  )
}

const cellStyles = tv({
  extend: focusRing,
  base: 'group-selected/row:border-surface-border border-surface-border truncate border-t px-4 py-3 -outline-offset-2 group-last/row:border-b-0',
})

export function Cell({ className, ...rest }: RACCellProps) {
  return (
    <RACCell
      {...rest}
      className={composeRenderProps(className, (className, renderProps) =>
        cellStyles({ ...renderProps, className }),
      )}
    />
  )
}

type TableActionsProps = ButtonProps

export function TableActionsTrigger({ children = <EllipsisIcon />, ...rest }: TableActionsProps) {
  return (
    <Button {...rest} variant={'soft'} size={'md'} color={'neutral'}>
      {children}
    </Button>
  )
}

export interface PaginationProps {
  totalItems: number
  itemsPerPage: number
  onPageChange: (page: number) => void
  currentPage: number
}

export function Pagination({
  totalItems,
  itemsPerPage,
  onPageChange,
  currentPage,
}: PaginationProps) {
  const { totalPages, handlePageChange } = usePagination({
    totalItems,
    itemsPerPage,
    initialPage: currentPage,
    onPageChange,
  })

  return (
    <div
      className={
        'border-surface-border bg-surface text-subtle-foreground hstack items-center gap-6 border-t px-4 py-2.5 text-xs'
      }
    >
      <div className={'hstack items-center gap-4'}>
        <Button
          size={'sm'}
          variant={'soft'}
          color={'neutral'}
          onPress={() => handlePageChange(currentPage - 1)}
          isDisabled={currentPage === 1}
        >
          <ArrowLeftIcon />
        </Button>

        <Text>
          Side {currentPage} af {totalPages}
        </Text>

        <Button
          size={'sm'}
          variant={'soft'}
          color={'neutral'}
          onPress={() => handlePageChange(currentPage + 1)}
          isDisabled={currentPage === totalPages}
        >
          <ArrowRightIcon />
        </Button>
      </div>

      <Text>
        {Math.min(currentPage * itemsPerPage, totalItems)} af {totalItems} items
      </Text>
    </div>
  )
}
