import { createFileRoute } from '@tanstack/react-router'
import { fallback, zodValidator } from '@tanstack/zod-adapter'
import { z } from 'zod'

import { clinicPatientQueries } from '@/api/fysioscout/endpoints/clinic-patient/queries'

import { PatientsView } from './-patients-view'

const PatientsRouteSearchSchema = z.object({
  status: fallback(z.enum(['accepted', 'pending', 'rejected']), 'accepted').default('accepted'),
  query: fallback(z.string().optional(), undefined),
})

export const Route = createFileRoute('/_auth/patients/')({
  validateSearch: zodValidator(PatientsRouteSearchSchema),
  loader: async ({ context: { queryClient } }) => {
    const [accepted, pending] = await Promise.all([
      queryClient.ensureQueryData(clinicPatientQueries.accepted()),
      queryClient.ensureQueryData(clinicPatientQueries.pending()),
    ])

    return {
      accepted,
      pending,
    }
  },
  component: PatientsView,
})
