import { useEnableVideoConsultationPage } from '@fysioscout/hypertune/flags'

import { useIsSubscriptionEnabled } from './use-is-subscription-enabled'

/**
 * Checks if video consultations are available. When disabled, the routes should still be accessible
 * but with limited functionality.
 *
 * @example
 *   const isAvailable = useVideoConsultationAvailable()
 *
 * @returns Whether video consultations are available
 */
export function useIsVideoConsultationAvailable() {
  const isFeatureEnabled = useEnableVideoConsultationPage()
  const isSubscribed = useIsSubscriptionEnabled()

  return isSubscribed && isFeatureEnabled
}
