import { useSuspenseQuery } from '@tanstack/react-query'

import { clinicQueries } from '@/api/fysioscout/endpoints/clinic/queries'

/**
 * Checks if video consultations are enabled in the clinic's subscription. When disabled, the routes
 * should still be accessible but with limited functionality.
 *
 * @example
 *   const isSubscribed = useVideoConsultationSubscription()
 *
 * @returns Whether video consultations are enabled in the clinic's subscription
 */
export function useIsSubscriptionEnabled() {
  const { data: subscription } = useSuspenseQuery(clinicQueries.getSubscription())

  return subscription.video_consultations.is_enabled
}
