import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  CalendarDate,
  Time,
  now,
  parseAbsoluteToLocal,
  toCalendarDate,
  toTime,
  today,
} from '@internationalized/date'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'
import type { SubmitHandler } from 'react-hook-form'

import { DEFAULT_TIME_ZONE } from '@fysioscout/env/date-time'
import { LOCALES } from '@fysioscout/env/locale'
import { Button } from '@fysioscout/ui/buttons/button'
import { DatePicker } from '@fysioscout/ui/date-and-time/date-picker'
import { TimeField } from '@fysioscout/ui/date-and-time/time-field'
import { Form } from '@fysioscout/ui/forms/form'
import { ComboBox, ComboBoxItem } from '@fysioscout/ui/pickers/combo-box'
import { CountryPicker } from '@fysioscout/ui/pickers/country-picker'
import { toast } from '@fysioscout/ui/status/toast'
import { Heading } from '@fysioscout/ui/typography/heading'
import { Text } from '@fysioscout/ui/typography/text'

import { useAuthContext } from '@/features/auth/context'
import { useEditConsultation } from '@/features/video-consultation/api/edit-consultation'
import { ConfirmPlannedConsultationDialog } from '@/features/video-consultation/components/consultation-form/confirmation-dialogs'
import { MINIMUM_SCHEDULING_NOTICE_MINUTES } from '@/features/video-consultation/config/constants'
import { useEditConsultationActions } from '@/features/video-consultation/user-actions/edit-consultation/store'
import { createScheduleDate, roundToNearest5Minutes } from '@/features/video-consultation/utils'
import { useClinicSubscription } from '@/hooks/use-clinic-subscription'
import { dayjs } from '@/lib/dayjs'

const EditVideoConsultationFormSchema = z
  .object({
    clinicEmployeeId: z.string().min(1, { message: 'Du mangler at vælge en behandler.' }),
    treatmentId: z.string().nullable(),
    locale: z.enum(LOCALES).nullish(),
    date: z
      .instanceof(CalendarDate, { message: 'Datoen er ikke gyldig.' })
      .nullable()
      .refine((date) => (date ? date.compare(today(DEFAULT_TIME_ZONE)) >= 0 : true), {
        message: 'Datoen kan ikke være før dagens dato.',
      }),
    time: z.instanceof(Time, { message: 'Tidspunktet er ikke gyldig.' }).nullable(),
  })
  .refine((data) => data.date !== null, {
    message: 'Du skal angive en gyldig dato.',
    path: ['date'],
  })
  .refine((data) => data.time !== null, {
    message: 'Du skal angive et gyldigt tidspunkt.',
    path: ['time'],
  })
  .refine(
    (data) => {
      if (data.date && data.time) {
        const currentDate = today(DEFAULT_TIME_ZONE)
        const currentTime = now(DEFAULT_TIME_ZONE)
        const minTime = currentTime.add({ minutes: MINIMUM_SCHEDULING_NOTICE_MINUTES })

        if (data.date.compare(currentDate) === 0) {
          return data.time.compare(minTime) >= 0
        }
      }
      return true
    },
    {
      message: `Tidspunktet skal være mindst ${MINIMUM_SCHEDULING_NOTICE_MINUTES} minutter frem i tiden.`,
      path: ['time'],
    },
  )

type FormSchema = z.infer<typeof EditVideoConsultationFormSchema>

interface EditConsultationFormProps {
  consultation: Schemas['ConsultationResponseDto']
  treatments?: Schemas['TreatmentDto'][]
  employees: Schemas['ClinicEmployeeDto'][]
}

export function EditConsultationForm({
  employees,
  consultation,
  treatments,
}: EditConsultationFormProps) {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false)

  const actions = useEditConsultationActions()
  const authContext = useAuthContext()
  const mutation = useEditConsultation()
  const parsedDate = parseAbsoluteToLocal(consultation.scheduled_date)
  const subscription = useClinicSubscription()

  const form = useForm<FormSchema>({
    resolver: zodResolver(EditVideoConsultationFormSchema),
    defaultValues: {
      clinicEmployeeId: consultation.employee.id,
      treatmentId: consultation.treatment_id,
      locale: consultation.locale,
      date: toCalendarDate(parsedDate),
      time: toTime(parsedDate),
    },
  })

  const [selectedEmployeeId, selectedTreatmentId, selectedDate, selectedTime] = form.watch([
    'clinicEmployeeId',
    'treatmentId',
    'date',
    'time',
  ])

  const handleSubmit: SubmitHandler<FormSchema> = () => setConfirmationDialogOpen(true)

  const handleMutation = () => {
    const { clinicEmployeeId, treatmentId, locale, date, time } = form.getValues()
    const { dirtyFields } = form.formState

    if (date && time) {
      const scheduleDate = createScheduleDate(date, time)

      mutation.mutate(
        {
          params: { path: { id: consultation.id } },
          body: {
            clinicEmployeeId: dirtyFields.clinicEmployeeId ? clinicEmployeeId : undefined,
            scheduleDate: dirtyFields.date || dirtyFields.time ? scheduleDate : undefined,
            treatmentId: dirtyFields.treatmentId ? treatmentId : undefined,
            locale: dirtyFields.locale ? locale : undefined,
          },
        },
        {
          onSuccess: () => {
            toast.success('Videokonsultation ændret', {
              description: `Planlagt til d. ${dayjs.format(scheduleDate, { dateStyle: 'full' })}.`,
            })

            setConfirmationDialogOpen(false)
            actions.setIsOpen(false)
          },
        },
      )
    }
  }

  const selectedEmployee = employees.find(({ id }) => id === selectedEmployeeId)
  const selectedTreatment = treatments?.find(({ id }) => id === selectedTreatmentId)

  return (
    <>
      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <div className={'mb-4'}>
          <Heading level={1} slot={'title'} size={'5'} medium className={'mb-1'}>
            Rediger videokonsultation
          </Heading>

          <Text size={'2'} subtle>
            Skift behandler, dato og tid for den planlagte konsultation.
          </Text>
        </div>

        <ComboBox
          label={'Patient'}
          isReadOnly
          items={[consultation.patient]}
          selectedKey={consultation.patient.id}
        >
          <ComboBoxItem id={consultation.patient.id}>{consultation.patient.name}</ComboBoxItem>
        </ComboBox>

        <Controller
          control={form.control}
          name={'clinicEmployeeId'}
          render={({ field, fieldState }) => (
            <ComboBox
              {...field}
              label={'Behandler'}
              items={employees}
              selectedKey={field.value || undefined}
              onSelectionChange={(practitionerId) => {
                field.onChange(practitionerId ?? '')
              }}
              validationBehavior={'aria'}
              errorMessage={fieldState.error?.message}
              isInvalid={fieldState.invalid}
            >
              {(item) => <ComboBoxItem>{item.name}</ComboBoxItem>}
            </ComboBox>
          )}
        />

        <Controller
          control={form.control}
          name={'treatmentId'}
          render={({ field, fieldState }) => (
            <ComboBox
              {...field}
              label={'Behandlingsforløb (valgfri)'}
              items={treatments ?? []}
              selectedKey={field.value}
              onSelectionChange={(treatmentId) => {
                field.onChange(treatmentId)
              }}
              validationBehavior={'aria'}
              errorMessage={fieldState.error?.message}
              isInvalid={fieldState.invalid}
              isReadOnly={!treatments || treatments.length === 0}
              emptyCollectionLabel={'Ingen behandlingsforløb fundet.'}
            >
              {(item) => <ComboBoxItem>{item.name}</ComboBoxItem>}
            </ComboBox>
          )}
        />

        {subscription.video_consultations.transcription_enabled ? (
          <Controller
            control={form.control}
            name={'locale'}
            render={({ field, fieldState }) => (
              <CountryPicker
                {...field}
                label={'Sprog (valgfri)'}
                type={'language'}
                allowedLanguages={['da', 'en']}
                defaultSelectedKey={field.value ?? undefined}
                onCountryChange={(country) => {
                  field.onChange(country?.languageCode)
                }}
                isInvalid={fieldState.invalid}
                validationBehavior={'aria'}
                description={
                  'Vælg det sprog, der tales under videokonsultationen. Dette hjælper os med at optimere den automatiske transskribering, så vi kan sikre den bedst mulige kvalitet.'
                }
              />
            )}
          />
        ) : null}

        <Controller
          control={form.control}
          name={'date'}
          render={({ field, fieldState }) => (
            <DatePicker
              {...field}
              label={'Dato'}
              onChange={(date) => field.onChange(date)}
              minValue={today(DEFAULT_TIME_ZONE)}
              errorMessage={fieldState.error?.message}
              isInvalid={fieldState.invalid}
              validationBehavior={'aria'}
            />
          )}
        />

        <Controller
          control={form.control}
          name={'time'}
          render={({ field, fieldState }) => (
            <TimeField
              {...field}
              label={'Tid'}
              hideTimeZone
              shouldForceLeadingZeros
              hourCycle={24}
              errorMessage={fieldState.error?.message}
              isInvalid={fieldState.invalid}
              validationBehavior={'aria'}
              onBlur={() => {
                const time = field.value

                if (time) {
                  field.onChange(roundToNearest5Minutes(time))
                }
              }}
            />
          )}
        />

        <Button
          type={'submit'}
          variant={'solid'}
          color={'accent'}
          size={'md'}
          className={'self-start'}
          isDisabled={!form.formState.isDirty}
        >
          Rediger videokonsultation
        </Button>
      </Form>

      <ConfirmPlannedConsultationDialog
        title={'Er du sikker på, at du vil redigere konsultationen?'}
        actionLabel={'Rediger videokonsultation'}
        onAction={handleMutation}
        isOpen={confirmationDialogOpen}
        isPending={mutation.isPending}
        onOpenChange={setConfirmationDialogOpen}
        patientName={consultation.patient.name}
        practitionerName={selectedEmployee?.name ?? ''}
        selectedDate={selectedDate}
        selectedSelf={selectedEmployee?.user_id === authContext.user.id}
        selectedTime={selectedTime}
        treatmentName={selectedTreatment?.name ?? ''}
      />
    </>
  )
}
