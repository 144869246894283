import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useDebounceFn } from 'ahooks'
import { useSpinDelay } from 'spin-delay'
import { capitalize } from 'string-ts'
import { match } from 'ts-pattern'

import type { EventEmitter } from 'ahooks/lib/useEventEmitter'
import type { Key } from 'react-aria-components'

import { Button } from '@fysioscout/ui/buttons/button'
import { Tag, TagGroup } from '@fysioscout/ui/collections/tag-group'
import { Checkbox } from '@fysioscout/ui/forms/checkbox'
import { SearchField } from '@fysioscout/ui/forms/search-field'
import { AlertDialog } from '@fysioscout/ui/overlays/alert-dialog'
import { Dialog } from '@fysioscout/ui/overlays/dialog'
import { Select, SelectItem } from '@fysioscout/ui/pickers/select'
import { Callout } from '@fysioscout/ui/status/callout'
import { Spinner } from '@fysioscout/ui/status/spinner'
import { toast } from '@fysioscout/ui/status/toast'
import { Heading } from '@fysioscout/ui/typography/heading'
import { Text } from '@fysioscout/ui/typography/text'
import { cn } from '@fysioscout/ui/utils'

import { clinicTemplateRegionQueries } from '@/api/fysioscout/endpoints/clinic-template-region/queries'
import { clinicTemplateTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-template-treatment/queries'
import { useTreatmentContext } from '@/features/treatments/contexts/treatment-context'
import { useTransferTemplateExercises } from '@/features/treatments/user-actions/add-exercise/api/transfer-template-exercises'
import { useExercisesList } from '@/features/treatments/user-actions/add-exercise/api/use-exercises-list'
import { useAddExercisesContext } from '@/features/treatments/user-actions/add-exercise/context'
import {
  INITIAL_STATE,
  useAddExercisesActions,
  useBasket,
  useFavorite,
  useLibrary,
  useRegionId,
  useSearchQuery,
  useTreatmentId,
} from '@/features/treatments/user-actions/add-exercise/store'

import { AddExercisesTable } from './add-exercises-table'

export function AddExercisesDialog() {
  const query = useExercisesList()

  const isLoading = useSpinDelay(query.isLoading, { minDuration: 600, delay: 0 })

  if (isLoading) {
    return (
      <Dialog
        data-testid={'add-exercises-dialog-loading'}
        className={'stack center text-subtle-foreground h-full gap-2'}
        aria-label={'Indlæser øvelser'}
      >
        <Spinner />
        <Text size={'2'}>Indlæser øvelser..</Text>
      </Dialog>
    )
  }

  if (query.data) {
    return (
      <Dialog
        data-testid={'add-exercises-dialog'}
        className={'stack h-full p-0'}
        aria-label={'Øvelser'}
      >
        <Header>
          <Filters />
        </Header>

        <AddExercisesTable />
        <Basket />
      </Dialog>
    )
  }

  return (
    <Dialog
      className={'stack center h-full'}
      aria-label={'Noget gik galt ved indlæsning af øvelser'}
    >
      <Callout type={'error'} size={'1'}>
        <Text>Der skete en fejl ved indlæsning af øvelser.</Text>
      </Callout>
    </Dialog>
  )
}

function Header({ children }: { children?: React.ReactNode }) {
  const actions = useAddExercisesActions()
  const { resetFilters$ } = useAddExercisesContext()

  const handleReset = () => {
    actions.resetFilters()
    resetFilters$.emit()
  }

  return (
    <div
      className={
        'divide-neutral-6 border-border-subtle bg-neutral-2 sticky top-0 z-20 divide-y border-b'
      }
    >
      <div className={'hstack justify-between gap-4 p-4'}>
        <Heading slot={'title'} level={1} size={'3'} medium>
          Tilføj øvelse fra skabeloner
        </Heading>

        <Button size={'xs'} variant={'ghost'} color={'neutral'} onPress={handleReset}>
          Nulstil filtre
        </Button>
      </div>

      {children}
    </div>
  )
}

function Filters() {
  return (
    <div data-testid={'filters'}>
      <div className={'hstack gap-10 px-4 py-3'}>
        <div className={'hstack w-96 items-center gap-3'}>
          <SearchFilter />
          <FavouriteFilter />
        </div>

        <div className={'grid flex-1 auto-cols-fr grid-flow-col items-center gap-3'}>
          <LibraryFilter />
          <RegionsFilter />
          <TreatmentFilter />
        </div>
      </div>
    </div>
  )
}

function Basket() {
  const actions = useAddExercisesActions()
  const basket = useBasket()

  const handleRemove = (keys: Set<Key>) => {
    actions.removeFromBasket(keys)
  }

  return (
    <div data-testid={'basket'} className={'sticky bottom-0 mt-auto'}>
      <div
        className={
          'border-border-subtle bg-neutral-2 relative mt-auto w-full border-t px-8 py-4 shadow-xl'
        }
      >
        <div
          className={cn('hstack items-center gap-14', {
            'items-end': basket.length >= 5,
          })}
        >
          <div className={'hstack relative w-full flex-wrap items-start gap-2'}>
            {basket.length > 0 ? (
              <TagGroup items={basket} onRemove={handleRemove}>
                {(item) => <Tag id={item.id}>{item.name}</Tag>}
              </TagGroup>
            ) : (
              <Text size={'2'} medium>
                Ingen øvelser valgt
              </Text>
            )}
          </div>

          <div className={'hstack shrink-0 gap-2'}>
            <AddExercisesAlertDialog />

            <Button
              variant={'ghost'}
              color={'neutral'}
              size={'sm'}
              onPress={actions.resetSelectionAndBasket}
            >
              Nulstil valg
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

function AddExercisesAlertDialog() {
  const [isOpen, setIsOpen] = React.useState(false)

  const actions = useAddExercisesActions()
  const basket = useBasket()
  const mutation = useTransferTemplateExercises()
  const navigate = useNavigate()
  const { patientId, treatmentId } = useTreatmentContext()

  const exerciseIds = basket.map((item) => item.id)

  const handleAddExercises = () => {
    mutation.mutate(
      { treatmentId, exerciseIds },
      {
        onSuccess: () => {
          void navigate({
            to: '/patients/$patientId/treatments/$treatmentId',
            params: { patientId, treatmentId },
            search: (prev) => ({ ...prev, tab: 'all' }),
          })

          toast.success(`${exerciseIds.length.toString()} øvelser overført til forløbet.`)
          setIsOpen(false)

          setTimeout(() => {
            actions.onOpenChange(false)
          }, 400)

          setTimeout(() => {
            actions.reset()
          }, 800)
        },
      },
    )
  }

  return (
    <>
      <Button
        size={'sm'}
        isDisabled={exerciseIds.length === 0}
        className={'shrink-0'}
        onPress={() => {
          setIsOpen(true)
        }}
      >
        Overfør øvelser ({exerciseIds.length})
      </Button>

      <AlertDialog
        title={`Vil du tilføje ${exerciseIds.length.toString()} ${exerciseIds.length === 1 ? 'øvelse' : 'øvelser'}?`}
        actionLabel={'Tilføj øvelser'}
        onAction={handleAddExercises}
        isPending={mutation.isPending}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
      >
        <Text elementType={'p'}>
          Du er ved at tilføje {exerciseIds.length}{' '}
          {exerciseIds.length === 1 ? 'øvelse' : 'øvelser'} til forløbet. Er du sikker på at du vil
          gøre dette?
        </Text>
      </AlertDialog>
    </>
  )
}

function SearchFilter() {
  const actions = useAddExercisesActions()
  const searchRef = React.useRef<HTMLInputElement>(null)
  const storeQuery = useSearchQuery()
  const { resetFilters$ } = useAddExercisesContext()

  const [localQuery, setLocalQuery] = React.useState(storeQuery)

  const debouncedSetSearchQuery = useDebounceFn(actions.setSearchQuery, { wait: 250 })

  useResetFilters(resetFilters$, () => {
    setLocalQuery('')
    searchRef.current?.focus()
  })

  const handleSearch = (query: string) => {
    setLocalQuery(query)
    debouncedSetSearchQuery.run(query)
  }

  return (
    <SearchField
      label={'Søg efter øvelse'}
      value={localQuery}
      onChange={handleSearch}
      autoFocus
      className={'w-full'}
    />
  )
}

/**
 * Extracted to a static hook call to satisfy the React compiler rules.
 *
 * @param resetFilters$ - The reset filters event emitter.
 * @param callback - The callback to call when the event is emitted.
 */
function useResetFilters(resetFilters$: EventEmitter<void>, callback: () => void) {
  resetFilters$.useSubscription(callback)
}

function FavouriteFilter() {
  const actions = useAddExercisesActions()
  const isFavorite = useFavorite()

  return (
    <Checkbox className={'mt-5'} isSelected={isFavorite} onChange={actions.setFavourite}>
      Favoritter
    </Checkbox>
  )
}

function RegionsFilter() {
  const actions = useAddExercisesActions()
  const region = useRegionId()
  const { data: regions } = useQuery(clinicTemplateRegionQueries.listRegions())

  const items = React.useMemo(() => {
    if (!regions) return []

    const mapped = regions
      .map((region) => ({ id: region.id, name: capitalize(region.name ?? '') }))
      .filter((item) => Boolean(item.name))
      .sort((a, b) => a.name.localeCompare(b.name))

    return [{ id: 'all', name: 'Alle' }, ...mapped]
  }, [regions])

  return (
    <Select
      label={'Region'}
      size={'1'}
      placeholder={'Vælg region'}
      selectedKey={region}
      onKeyUp={(e) => {
        if (e.key === 'Backspace') {
          actions.setRegion(INITIAL_STATE.region)
        }
      }}
      onSelectionChange={(value) => {
        actions.setRegion(value)
      }}
      items={items}
    >
      {(item) => <SelectItem key={item.id}>{item.name}</SelectItem>}
    </Select>
  )
}

function TreatmentFilter() {
  const actions = useAddExercisesActions()
  const treatmentId = useTreatmentId()
  const regionId = useRegionId()

  const { data: treatments } = useQuery(
    clinicTemplateTreatmentQueries.list(
      { regionId: regionId.toString() },
      { enabled: Boolean(regionId) && regionId !== 'all' },
    ),
  )

  const items = React.useMemo(() => {
    if (!treatments) return []

    const mapped = treatments
      .map(({ treatment }) => ({ id: treatment.id, name: treatment.name ?? '' }))
      .filter((item) => Boolean(item.name))
      .sort((a, b) => a.name.localeCompare(b.name))

    return [{ id: 'all', name: 'Alle' }, ...mapped]
  }, [treatments])

  return (
    <Select
      label={'Behandlingsforløb'}
      size={'1'}
      placeholder={'Vælg forløb'}
      selectedKey={treatmentId}
      isDisabled={items.length === 0}
      onKeyUp={(e) => {
        if (e.key === 'Backspace') {
          actions.setTreatment(INITIAL_STATE.treatment)
        }
      }}
      onSelectionChange={(value) => {
        actions.setTreatment(value)
      }}
      items={items}
    >
      {(item) => <SelectItem key={item.id}>{item.name}</SelectItem>}
    </Select>
  )
}

function LibraryFilter() {
  const actions = useAddExercisesActions()
  const library = useLibrary()

  return (
    <Select
      label={'Øvelseskartotek'}
      size={'1'}
      placeholder={'Vælg øvelseskartotek'}
      selectedKey={library}
      onKeyUp={(e) => {
        if (e.key === 'Backspace') {
          actions.setLibrary(INITIAL_STATE.library)
        }
      }}
      onSelectionChange={(value) => {
        match(value)
          .with('fysio_scout', () => actions.setLibrary('fysio_scout'))
          .with('own', () => actions.setLibrary('own'))
          .otherwise(() => actions.setLibrary(INITIAL_STATE.library))
      }}
      items={[
        { id: 'all', name: 'Alle' },
        { id: 'fysio_scout', name: 'FysioScout øvelser' },
        { id: 'own', name: 'Klinikkens egne øvelser' },
      ]}
    >
      {(item) => <SelectItem key={item.id}>{item.name}</SelectItem>}
    </Select>
  )
}
