'use client'

import React from 'react'
import { ChevronDown } from 'lucide-react'
import {
  ComboBox as RACComboBox,
  ListBox as RACListBox,
  composeRenderProps,
} from 'react-aria-components'

import type {
  ComboBoxProps as RACComboBoxProps,
  ListBoxItemProps as RACListBoxItemProps,
  ValidationResult,
} from 'react-aria-components'
import type { DropdownSectionProps } from '../../collections/list-box/list-box'

import { DropdownItem, DropdownSection } from '../../collections/list-box/list-box'
import {
  Description,
  FieldError,
  FieldGroup,
  FieldTrigger,
  Input,
  Label,
} from '../../forms/field/field'
import { fieldStyles } from '../../forms/field/field.styles'
import { Popover } from '../../overlays/popover/popover'
import { Text } from '../../typography/text/text'

export interface ComboBoxProps<T extends object> extends Omit<RACComboBoxProps<T>, 'children'> {
  label?: string
  description?: string | null
  errorMessage?: string | ((validation: ValidationResult) => string)
  children: React.ReactNode | ((item: T) => React.ReactNode)
  emptyCollectionLabel?: string
  ref?: React.Ref<React.ComponentRef<typeof Input>>
}

export function ComboBox<T extends object>({
  label,
  description,
  errorMessage,
  children,
  items,
  className,
  ref,
  emptyCollectionLabel,
  ...rest
}: ComboBoxProps<T>) {
  return (
    <RACComboBox
      {...rest}
      allowsEmptyCollection={Boolean(emptyCollectionLabel)}
      className={composeRenderProps(className, (className, renderProps) =>
        fieldStyles({ ...renderProps, className }),
      )}
    >
      <Label>{label}</Label>

      <FieldGroup>
        <Input ref={ref} />

        <FieldTrigger>
          <ChevronDown aria-hidden />
        </FieldTrigger>
      </FieldGroup>

      {description ? <Description>{description}</Description> : null}

      {rest.isDisabled ? null : <FieldError>{errorMessage}</FieldError>}

      <Popover className={'w-[--trigger-width]'}>
        <RACListBox
          items={items}
          renderEmptyState={() => (
            <div className={'stack center min-h-20 items-center'}>
              <Text size={'1'} subtle slot={'description'}>
                {emptyCollectionLabel ?? 'Ingen resultater fundet.'}
              </Text>
            </div>
          )}
          className={
            'max-h-[400px] overflow-auto p-1 outline-0 [clip-path:inset(0_0_0_0_round_.75rem)]'
          }
        >
          {children}
        </RACListBox>
      </Popover>
    </RACComboBox>
  )
}

export type ComboBoxItemProps = RACListBoxItemProps

export function ComboBoxItem(props: ComboBoxItemProps) {
  return <DropdownItem {...props} />
}

export type ComboBoxSectionProps<T> = DropdownSectionProps<T>

export function ComboBoxSection<T extends object>(props: ComboBoxSectionProps<T>) {
  return <DropdownSection {...props} />
}
