'use client'

import React from 'react'
import { CalendarDaysIcon } from 'lucide-react'
import { DatePicker as RACDatePicker } from 'react-aria-components'

import type {
  DateValue,
  DatePickerProps as RACDatePickerProps,
  ValidationResult,
} from 'react-aria-components'

import { Description, FieldError, FieldGroup, FieldTrigger, Label } from '../../forms/field/field'
import { Dialog } from '../../overlays/dialog/dialog'
import { Popover } from '../../overlays/popover/popover'
import { composeTailwindRenderProps } from '../../utils'
import { Calendar } from '../calendar/calendar'
import { DateInput } from '../date-field/date-field'

export interface DatePickerProps<T extends DateValue> extends RACDatePickerProps<T> {
  label?: string
  description?: string
  errorMessage?: string | ((validation: ValidationResult) => string)
  ref?: React.Ref<React.ComponentRef<typeof RACDatePicker>>
}

export function DatePicker<T extends DateValue>({
  label,
  description,
  errorMessage,
  ref,
  className,
  ...rest
}: DatePickerProps<T>) {
  return (
    <RACDatePicker
      {...rest}
      className={composeTailwindRenderProps(className, 'group flex flex-col gap-1')}
    >
      {label ? <Label>{label}</Label> : null}

      <FieldGroup className={'min-w-52'}>
        <DateInput ref={ref} />

        <FieldTrigger>
          <CalendarDaysIcon aria-hidden />
        </FieldTrigger>
      </FieldGroup>

      {description ? <Description>{description}</Description> : null}
      <FieldError>{errorMessage}</FieldError>

      <Popover>
        <Dialog>
          <Calendar />
        </Dialog>
      </Popover>
    </RACDatePicker>
  )
}
