import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { match } from 'ts-pattern'

import type { Locale } from '@fysioscout/env/locale'
import type { ConfigType, Dayjs } from 'dayjs'

import 'dayjs/locale/da'
import 'dayjs/locale/en'

type DateStyle = 'full' | 'long' | 'short'

export interface DateService {
  format: (date: ConfigType, options?: { dateStyle?: DateStyle; includeTime?: boolean }) => string
  instance: (date?: ConfigType) => Dayjs
  to: (date: ConfigType) => string
  toUTC: (date: ConfigType) => string
  toUTCStartOfDay: (date: ConfigType) => string
}

export function createDateService(locale: Locale = 'da'): DateService {
  dayjs.extend(relativeTime)
  dayjs.extend(timezone)
  dayjs.extend(utc)

  const localDayjs = (date?: ConfigType): Dayjs => {
    return dayjs(date).locale(locale)
  }

  const to = (date: ConfigType): string => {
    return localDayjs().to(date)
  }

  const toUTC = (date: ConfigType): string => {
    return dayjs(date).utc().toISOString()
  }

  const toUTCStartOfDay = (date: ConfigType): string => {
    return dayjs.utc(date).startOf('day').toISOString()
  }

  function format(
    date: ConfigType,
    {
      dateStyle = 'long',
      includeTime = false,
    }: { dateStyle?: DateStyle; includeTime?: boolean } = {},
  ): string {
    const dateTemplate = match({ dateStyle, includeTime })
      .with({ dateStyle: 'full', includeTime: true }, () => 'DD. MMMM YYYY kl. HH:mm')
      .with({ dateStyle: 'full', includeTime: false }, () => 'DD. MMMM YYYY')
      .with({ dateStyle: 'long', includeTime: true }, () => 'DD. MMM YYYY kl. HH:mm')
      .with({ dateStyle: 'long', includeTime: false }, () => 'DD. MMM YYYY')
      .with({ dateStyle: 'short', includeTime: true }, () => 'DD.MM.YYYY kl. HH:mm')
      .with({ dateStyle: 'short', includeTime: false }, () => 'DD.MM.YYYY')
      .exhaustive()

    return localDayjs(date).format(dateTemplate)
  }

  return {
    format,
    instance: localDayjs,
    to,
    toUTC,
    toUTCStartOfDay,
  }
}
