'use client'

import React from 'react'
import { ChevronDown, ChevronUp } from 'lucide-react'
import {
  Button as RACButton,
  NumberField as RACNumberField,
  composeRenderProps,
} from 'react-aria-components'

import type {
  ButtonProps as RACButtonProps,
  NumberFieldProps as RACNumberFieldProps,
  ValidationResult,
} from 'react-aria-components'

import { Description, FieldError, FieldGroup, Input, Label } from '../field/field'
import { fieldBorderStyles, fieldStyles } from '../field/field.styles'

export interface NumberFieldProps extends RACNumberFieldProps {
  label?: string
  description?: string
  errorMessage?: string | ((validation: ValidationResult) => string)
  ref?: React.Ref<React.ComponentRef<typeof Input>>
}

export function NumberField({
  label,
  description,
  errorMessage,
  ref,
  className,
  ...rest
}: NumberFieldProps) {
  return (
    <RACNumberField
      {...rest}
      isWheelDisabled
      className={composeRenderProps(className, (className, renderProps) =>
        fieldStyles({ ...renderProps, className }),
      )}
    >
      <Label>{label}</Label>

      <FieldGroup>
        {(renderProps) => (
          <>
            <Input ref={ref} />

            <div
              className={fieldBorderStyles({
                ...renderProps,
                className: 'flex h-full flex-col rounded-none border-0 border-l',
              })}
            >
              <StepperButton slot={'increment'}>
                <ChevronUp aria-hidden className={'size-4'} />
              </StepperButton>

              <div
                className={fieldBorderStyles({ ...renderProps, className: 'border-0 border-b' })}
              />

              <StepperButton slot={'decrement'}>
                <ChevronDown aria-hidden className={'size-4'} />
              </StepperButton>
            </div>
          </>
        )}
      </FieldGroup>

      {description ? <Description>{description}</Description> : null}
      <FieldError>{errorMessage}</FieldError>
    </RACNumberField>
  )
}

NumberField.displayName = 'NumberField'

function StepperButton(props: RACButtonProps) {
  return (
    <RACButton
      {...props}
      className={
        'pressed:bg-neutral-3 hover:bg-neutral-2 text-muted-foreground hover:text-subtle-foreground group-disabled:text-neutral-3 cursor-default px-1 transition-colors duration-150'
      }
    />
  )
}
