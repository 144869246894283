import React from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useKeyPress } from 'ahooks'
import { InfoIcon, OptionIcon } from 'lucide-react'
import {
  Tab as RACTab,
  TabList as RACTabList,
  TabPanel as RACTabPanel,
  Tabs as RACTabs,
  composeRenderProps,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'
import { match } from 'ts-pattern'

import type {
  Key,
  TabPanelProps as RACTabPanelProps,
  TabProps as RACTabProps,
} from 'react-aria-components'

import { Text } from '@fysioscout/ui/typography/text'
import { focusRing } from '@fysioscout/ui/utils'

import { clinicTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-treatment/queries'
import { ExerciseTable } from '@/features/treatments/components/exercise/exercise-table'
import { ExerciseTableContext } from '@/features/treatments/components/exercise/exercise-table.context'
import { useTreatmentContext } from '@/features/treatments/contexts/treatment-context'
import { AddExercises } from '@/features/treatments/user-actions/add-exercise/components/add-exercises'

const TABS = {
  CURRENT_EXERCISES: 'current',
  ALL_EXERCISES: 'all',
} as const

type Tab = (typeof TABS)[keyof typeof TABS]

interface TreatmentOverviewProps {
  activeTab: Tab
  setActiveTab: (tab: Tab) => void
}

export function TreatmentOverview({ activeTab, setActiveTab }: TreatmentOverviewProps) {
  const { patientId, treatmentId } = useTreatmentContext()

  const { data: treatment } = useSuspenseQuery(
    clinicTreatmentQueries.treatment({ path: { treatmentId, patientId } }),
  )

  const currentExercises = React.useMemo(
    () => treatment.exercises.filter(({ exercise }) => exercise.is_relevant),
    [treatment.exercises],
  )

  const handleTabChange = (key: Key) => {
    const tab = match(key)
      .with('current', () => 'current' as const)
      .with('all', () => 'all' as const)
      .otherwise(() => 'all' as const)

    setActiveTab(tab)
  }

  useKeyPress(
    ['alt.e'],
    () => {
      setActiveTab('current')
    },
    { exactMatch: true },
  )

  useKeyPress(
    ['alt.d'],
    () => {
      setActiveTab('all')
    },
    { exactMatch: true },
  )

  return (
    <div data-testid={'treatment-overview'}>
      <div className={'hstack items-end justify-end gap-4'}>
        <AddExercises />
      </div>

      <RACTabs
        orientation={'horizontal'}
        selectedKey={activeTab}
        onSelectionChange={handleTabChange}
      >
        <div className={'hstack justify-between gap-4'}>
          <RACTabList className={'hstack relative -mb-px'}>
            <Tab id={TABS.CURRENT_EXERCISES}>
              <span>Aktuelle øvelser</span>
              <TabHotkey>E</TabHotkey>
            </Tab>

            <Tab id={TABS.ALL_EXERCISES}>
              <span>Alle øvelser</span>
              <TabHotkey>D</TabHotkey>
            </Tab>
          </RACTabList>
        </div>

        <ExerciseTableContext value={{}}>
          <TabPanel id={TABS.CURRENT_EXERCISES}>
            <ExerciseTable exercises={currentExercises} />

            <div
              className={
                'hstack center border-surface-border bg-surface text-muted-foreground gap-3 rounded-lg border p-6'
              }
            >
              <InfoIcon className={'size-5'} />
              <Text size={'2'}>Du kan maksimalt have 20 aktuelle øvelser</Text>
            </div>
          </TabPanel>

          <TabPanel id={TABS.ALL_EXERCISES}>
            <ExerciseTable exercises={treatment.exercises} roundTopLeft />
          </TabPanel>
        </ExerciseTableContext>
      </RACTabs>
    </div>
  )
}

const tabStyles = tv({
  extend: focusRing,
  base: 'hstack cursor-default items-center gap-5 rounded-t-lg border-x border-t px-6 py-4 text-sm transition-colors duration-100',
  variants: {
    isSelected: {
      false: 'text-muted-foreground hover:text-subtle-foreground border-transparent',
      true: 'border-surface-border bg-surface text-foreground',
    },
    isDisabled: {
      true: '',
    },
    isFocusVisible: {
      true: 'rounded-lg',
    },
  },
})

function Tab({ className, ...rest }: RACTabProps) {
  return (
    <RACTab
      className={composeRenderProps(className, (className, renderProps) =>
        tabStyles({ ...renderProps, className }),
      )}
      {...rest}
    />
  )
}

const tabPanelStyles = tv({
  base: 'stack gap-6',
})

function TabPanel({ className, ...rest }: RACTabPanelProps) {
  return (
    <RACTabPanel
      className={composeRenderProps(className, (className, renderProps) =>
        tabPanelStyles({ ...renderProps, className }),
      )}
      {...rest}
    />
  )
}

function TabHotkey({ children }: { children: string }) {
  return (
    <div className={'hstack text-muted-foreground items-center gap-1'}>
      <OptionIcon className={'size-3'} /> {children}
    </div>
  )
}
