import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRouter } from '@tanstack/react-router'
import { Analytics } from '@vercel/analytics/react'
import isChromatic from 'chromatic'
import { MotionGlobalConfig } from 'framer-motion'
import { I18nProvider } from 'react-aria-components'

import type { NavigateOptions, ToOptions } from '@tanstack/react-router'

import { Button } from '@fysioscout/ui/buttons/button'
import { Container } from '@fysioscout/ui/layout/container'
import { Callout } from '@fysioscout/ui/status/callout'
import { toast } from '@fysioscout/ui/status/toast'
import { Text } from '@fysioscout/ui/typography/text'
import { getErrorMessage } from '@fysioscout/utils/error'
import { ThemeProvider } from '@fysioscout/widgets/theme'

import { routeTree } from '@/app/gen/route-tree.gen'
import { HypertuneProvider } from '@/components/providers/hypertune-provider'
import { RouterProvider } from '@/components/providers/router-provider'
import { DEFAULT_STALE_TIME } from '@/config/constants'
import { env } from '@/config/env'
import { AuthProvider } from '@/features/auth/components/auth-provider'

/**
 * When running in Chromatic, we want to skip animations to make the visual regression tests more
 * stable.
 *
 * This is a global setting that will affect all Framer Motion animations in the application.
 *
 * @see {@link https://www.chromatic.com/docs/animations/#javascript-animations}
 */
MotionGlobalConfig.skipAnimations = isChromatic() || env.VITE_DISABLE_ANIMATIONS

/** Project-wide React Query client */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: DEFAULT_STALE_TIME,
    },
    mutations: {
      retry: 1,
      onError: (error) => {
        console.error(error)
        return toast.error('Der skete en fejl.', {
          description: getErrorMessage(error),
        })
      },
    },
  },
})

/** Project-wide router instance */
const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  defaultStructuralSharing: true,
  search: {
    strict: true,
  },
  context: {
    queryClient,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    hypertune: undefined!,
  },
  defaultErrorComponent: ({ error }) => <ErrorComponent error={error} />,
  /**
   * Since we're using React Query, we don't want loader calls to ever be stale. This will ensure
   * that the loader is always called when the route is preloaded or visited
   */
  defaultPreloadStaleTime: 0,
})

/** Register the router instance for type safety */
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

declare module 'react-aria-components' {
  interface RouterConfig {
    href: ToOptions['to'] | (string & {})
    routerOptions: Omit<NavigateOptions, keyof ToOptions>
  }
}

export function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <I18nProvider locale={'da'}>
          <AuthProvider>
            <HypertuneProvider>
              <ThemeProvider>
                <RouterProvider router={router} />
              </ThemeProvider>
            </HypertuneProvider>
          </AuthProvider>
        </I18nProvider>
      </QueryClientProvider>

      <Analytics />
    </>
  )
}

function ErrorComponent({ error }: { error: Error }) {
  const errorMessage = getErrorMessage(error)

  return (
    <Container size={'prose'} className={'mt-32'}>
      <Callout type={'neutral'} size={'2'} className={'py-6'}>
        <Text size={'3'} semiBold className={'leading-tight'}>
          Beklager, der opstod en uventet fejl.
        </Text>

        <div className={'stack mt-1.5 items-start gap-4'}>
          <Text size={'2'}>
            Vi kunne desværre ikke indlæse siden på grund af en teknisk fejl. <br />
            Hvis problemet fortsætter, <a href={'mailto:service@fysioscout.dk'}>kontakt os</a>.
          </Text>

          <Text size={'1'} muted className={'italic'}>
            {errorMessage}
          </Text>

          <Button
            size={'xs'}
            variant={'soft'}
            color={'neutral'}
            onPress={() => globalThis.location.reload()}
          >
            Genindlæs side
          </Button>
        </div>
      </Callout>
    </Container>
  )
}
