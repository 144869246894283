import { create, useStore } from 'zustand'
import { persist } from 'zustand/middleware'

interface GlobalUiStore {
  isSidebarOpen: boolean
  actions: {
    toggleSidebar: () => void
    reset: () => void
  }
}

const INITIAL_STATE = {
  isSidebarOpen: true,
} satisfies Omit<GlobalUiStore, 'actions'>

export const globalUiStore = create<GlobalUiStore>()(
  persist(
    (set) => ({
      ...INITIAL_STATE,
      actions: {
        toggleSidebar: () => {
          set((state) => ({ isSidebarOpen: !state.isSidebarOpen }))
        },
        reset: () => {
          set(INITIAL_STATE)
        },
      },
    }),
    {
      name: 'fs:global:sidebar:open',
      partialize: (state) => ({ ...state, actions: undefined }),
    },
  ),
)

export function useGlobalUiStore<T>(selector: (state: GlobalUiStore) => T): T {
  return useStore(globalUiStore, selector)
}

export function useIsSidebarOpen() {
  return useGlobalUiStore((state) => state.isSidebarOpen)
}

export function useGlobalUiActions() {
  return useGlobalUiStore((state) => state.actions)
}
