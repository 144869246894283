import React from 'react'

import { EditExercisesContext } from '../context'
import { createEditExercisesStore } from '../store'

export interface EditExerciseProviderProps {
  children?: React.ReactNode
}

export function EditExerciseProvider({ children }: EditExerciseProviderProps) {
  const [store] = React.useState(() => createEditExercisesStore())

  return <EditExercisesContext value={{ store }}>{children}</EditExercisesContext>
}
