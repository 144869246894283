import { createFileRoute } from '@tanstack/react-router'
import { fallback, zodValidator } from '@tanstack/zod-adapter'
import { z } from 'zod'

import { clinicNotebookQueries } from '@/api/fysioscout/endpoints/clinic-notebook/queries'
import { clinicTreatmentQueries } from '@/api/fysioscout/endpoints/clinic-treatment/queries'

import { TreatmentView } from './-treatment-view'

const TreatmentRouteSearchSchema = z.object({
  view: fallback(z.enum(['exercises', 'notebook']), 'exercises').default('exercises'),
  tab: fallback(z.enum(['current', 'all']), 'all').default('all'),
})

export const Route = createFileRoute('/_auth/patients/$patientId/treatments/$treatmentId/')({
  validateSearch: zodValidator(TreatmentRouteSearchSchema),
  loaderDeps: ({ search: { view } }) => ({ view }),
  loader: async ({
    context: { queryClient },
    params: { patientId, treatmentId },
    deps: { view },
  }) => {
    const [treatment] = await Promise.all([
      queryClient.ensureQueryData(
        clinicTreatmentQueries.treatment({ path: { treatmentId, patientId } }),
      ),
      queryClient.ensureQueryData(
        clinicNotebookQueries.listNotebooks({ treatmentId }, { enabled: view === 'notebook' }),
      ),
    ])

    return {
      treatment,
    }
  },
  head: ({ loaderData }) => ({
    meta: [{ title: loaderData?.treatment.treatment.name ?? 'Behandlingsforløb' }],
  }),
  component: TreatmentView,
})
