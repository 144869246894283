import React from 'react'

import type { CreateVideoConsultationContextValue } from '../context'

import { CreateVideoConsultationDrawer } from './create-video-consultation-drawer'
import { CreateVideoConsultationProvider } from './create-video-consultation-provider'

interface CreateVideoConsultationProps
  extends Pick<CreateVideoConsultationContextValue, 'onCreate'> {
  children?: React.ReactNode
}

export function CreateVideoConsultation({ children, onCreate }: CreateVideoConsultationProps) {
  return (
    <CreateVideoConsultationProvider onCreate={onCreate}>
      <CreateVideoConsultationDrawer />

      {children}
    </CreateVideoConsultationProvider>
  )
}
