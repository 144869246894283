import React from 'react'
import Fuse from 'fuse.js'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { Panel, PanelHeading } from '@fysioscout/ui/layout/panel'

import { PatientTable } from '@/features/patients/components/patient-table'
import { SEARCH_MIN_CHAR_LENGTH } from '@/features/patients/config/constants'
import { PatientTableContext } from '@/features/patients/contexts/patient-table-context'

interface PendingProps {
  searchQuery?: string
  list: Schemas['ClinicPatientDto'][]
}

const keys = [
  'patient_details.name',
  'patient_details.email',
] satisfies `patient_details.${keyof Schemas['ClinicPatientDto']['patient_details']}`[]

export function PendingPatients({ searchQuery = '', list }: PendingProps) {
  const headingId = React.useId()
  const isQueryValid = searchQuery.length >= SEARCH_MIN_CHAR_LENGTH

  const fuse = React.useMemo(() => {
    return new Fuse(list, {
      keys,
      threshold: 0.2,
      minMatchCharLength: SEARCH_MIN_CHAR_LENGTH,
    })
  }, [list])

  const results = isQueryValid ? fuse.search(searchQuery).map((result) => result.item) : []

  const renderList = () => {
    if (isQueryValid) {
      return <PatientTable patients={results} />
    }

    return (
      <PatientTable patients={list} aria-labelledby={headingId} defaultSortColumn={'deletion'} />
    )
  }

  return (
    <Panel>
      <PanelHeading id={headingId}>Afventende patienter</PanelHeading>

      <PatientTableContext value={{ enabledActions: 'all' }}>{renderList()}</PatientTableContext>
    </Panel>
  )
}
