'use client'

import {
  Tab as RACTab,
  TabList as RACTabList,
  TabPanel as RACTabPanel,
  Tabs as RACTabs,
  composeRenderProps,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type {
  TabListProps as RACTabListProps,
  TabPanelProps as RACTabPanelProps,
  TabProps as RACTabProps,
  TabsProps as RACTabsProps,
} from 'react-aria-components'

import { focusRing } from '../../utils'

const tabsStyles = tv({
  base: 'flex gap-8',
  variants: {
    orientation: {
      horizontal: 'flex-col',
      vertical: 'flex-row',
    },
  },
})

export type TabsProps = RACTabsProps

export function Tabs(props: TabsProps) {
  return (
    <RACTabs
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabsStyles({ ...renderProps, className }),
      )}
    />
  )
}

const tabListStyles = tv({
  base: 'border-border-subtle flex border-b',
  variants: {
    orientation: {
      horizontal: 'flex-row',
      vertical: 'flex-col items-start',
    },
  },
})

export type TabListProps<T> = RACTabListProps<T>

export function TabList<T extends object>(props: TabListProps<T>) {
  return (
    <RACTabList
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabListStyles({ ...renderProps, className }),
      )}
    />
  )
}

const tabStyles = tv({
  extend: focusRing,
  base: '-mb-px flex cursor-default items-center border-b-2 px-5 py-3 text-sm transition duration-200',
  variants: {
    isSelected: {
      false: 'text-subtle-foreground hover:text-foreground border-transparent',
      true: 'text-accent-11 border-accent-10',
    },
    isDisabled: {
      true: 'selected:bg-neutral-5 selected:text-neutral-9 text-disabled-foreground',
    },
  },
})

export type TabProps = RACTabProps

export function Tab(props: TabProps) {
  return (
    <RACTab
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabStyles({ ...renderProps, className }),
      )}
    />
  )
}

const tabPanelStyles = tv({
  extend: focusRing,
  base: 'text-foreground flex-1 text-sm',
})

export type TabPanelProps = RACTabPanelProps

export function TabPanel(props: TabPanelProps) {
  return (
    <RACTabPanel
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabPanelStyles({ ...renderProps, className }),
      )}
    />
  )
}
